import { Box, IconButton, InputAdornment, TextField } from "@mui/material";
import * as React from "react";
import { useRef } from "react";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import EditIcon from "@mui/icons-material/Edit";
import {
    DonationBooleanTypeList,
    DonationTypeList,
    PictureBooleanTypeList,
    StoreOpeningConditionsBooleanTypeList,
    StoreOpeningConditionsTypeList,
    ConflictBooleanTypeList,
    LocationImpactBooleanTypeList,
} from "../../data/type";
import { setPermissionFunc } from "../components/PermissionFunc";
import { useAppSelector } from "../../app/hooks";
import {
    BGDefaultColor,
    BGChangeColor,
    BGDisabledColor,
    TextDefaultColor,
    TextChangeColor,
    TextDisabledColor,
} from "../../data/table";
import { textColor } from "../../data/tableFunc";
import {
    chatBubbleCircleSx,
    chatBubbleIconSx,
    editIconCircleSx,
    iconHover,
} from "./ChatBubbleAndEditIconStyle";

export interface InputProps {
    //全ページ対応できるように各ページ型を追加していく
    props: {
        userType: string;
        disabled: boolean;
        pageName: string;
        labelName: string;
        startAdornment: string;
        endAdornment: string;
        InputValueObj:
            | StoreOpeningConditionsTypeList
            | DonationTypeList
            | PictureBooleanTypeList
            | any;
        ReadOnlyObj:
            | StoreOpeningConditionsBooleanTypeList
            | DonationBooleanTypeList
            | PictureBooleanTypeList
            | ConflictBooleanTypeList
            | any;
        setReadOnlyObj:
            | React.Dispatch<
                  React.SetStateAction<StoreOpeningConditionsBooleanTypeList>
              >
            | React.Dispatch<React.SetStateAction<DonationBooleanTypeList>>
            | React.Dispatch<React.SetStateAction<PictureBooleanTypeList>>
            | React.Dispatch<React.SetStateAction<ConflictBooleanTypeList>>
            | React.Dispatch<
                  React.SetStateAction<LocationImpactBooleanTypeList>
              >;
        ShowEditIconObj:
            | StoreOpeningConditionsBooleanTypeList
            | DonationBooleanTypeList
            | PictureBooleanTypeList
            | ConflictBooleanTypeList
            | LocationImpactBooleanTypeList;
        ShowIconsObj:
            | StoreOpeningConditionsBooleanTypeList
            | DonationBooleanTypeList
            | PictureBooleanTypeList
            | ConflictBooleanTypeList
            | LocationImpactBooleanTypeList
            | any;
        handleChatBubbleClick: (labelName: string) => void;
        handleInputBlur: (
            e: React.FocusEvent<
                HTMLInputElement | HTMLTextAreaElement,
                Element
            >,
            labelName: string
        ) => void;
        handleInputChange: (
            e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
            labelName: string
        ) => void;
        handleInputClick: (labelName: string) => void;
    };
}

export const InputWithIcons: React.FC<InputProps> = ({ props }) => {
    const objLocationBase = useAppSelector(
        (state) => state.longPage.locationBase
    );
    const objRrBranchLog = useAppSelector(
        (state) => state.longPage.rrBranchLog
    );
    // 鉛筆をクリックしたときのフォーカス制御
    const inputRef = useRef<HTMLInputElement | null>(null);

    // 鉛筆をクリックしたとき、Input編集可
    const handleEditIconClick = (labelName: string) => {
        if (
            !props.disabled && 
            setPermissionFunc(
                props.userType,
                objLocationBase.task_id,
                props.pageName,
                labelName
            ) === false
        ) {
            //falseにすると、Input編集可
            props.setReadOnlyObj({ ...props.ReadOnlyObj, [labelName]: false });
        }
        //インプットにフォーカスする
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };

    const changeTextColor = () => {
        let changeColor = false;
        let color = TextDefaultColor;
        changeColor = textColor(
            objRrBranchLog,
            props.pageName,
            props.labelName
        );
        if (changeColor) {
            color = TextChangeColor;
        } else {
            if (props.userType !== "z003" && props.userType !== "z001") {
                /* ユーザー種別がRFCとリサーチ以外の場合*/
                color = TextDisabledColor;
            } else if (props.userType === "z003") {
                color = props.disabled ? TextDisabledColor : TextDefaultColor;
            } else if (props.userType === "z001") {
                color = props.ReadOnlyObj[props.labelName]
                    ? TextDisabledColor
                    : TextDefaultColor;
            }
        }
        return color;
    };

    const changeBGColor = () => {
        if (props.disabled) {
            return BGDisabledColor;
        } else if (props.InputValueObj[props.labelName].length > 0) {
            return BGChangeColor;
        }
        return BGDefaultColor;
    };

    //ユーザー種別（'z002'、'z004'、'z005'）が選択されている場合のTextFieldのスタイル調整
    const TextFieldSx = {
        "& .MuiOutlinedInput-input": {
            color: changeTextColor(),
            textAlign: "center", //入力した値を中央寄せ
            paddingTop: "17px",
            paddingBottom: "17px",
        },
        "& .MuiInputAdornment-root.MuiInputAdornment-positionStart.MuiInputAdornment-filled.MuiInputAdornment-sizeMedium":
            {
                marginTop: "0px",
            },
        //disabledがtrueのとき背景がグレー
        "& .Mui-disabled.MuiInputBase-root": {
            backgroundColor: "rgba(0, 0, 0, 0.06)",
            "&: hover": {
                backgroundColor: "rgba(0, 0, 0, 0.06)",
            },
        },
        "& .MuiInputBase-root.MuiOutlinedInput-root.Mui-disabled:before": {
            borderBottomStyle: "solid",
        },
    };

    //ユーザー種別（'z003'）が選択されている場合のTextFieldのスタイル調整
    const SelectZ003TextFieldSx = {
        "& .MuiOutlinedInput-input": {
            color: changeTextColor(),
            textAlign: "center", //入力した値を中央寄せ
            paddingTop: "17px",
            paddingBottom: "17px",
        },
        "& .MuiInputAdornment-root.MuiInputAdornment-positionStart.MuiInputAdornment-filled.MuiInputAdornment-sizeMedium":
            {
                marginTop: "0px",
            },
        cursor: props.ReadOnlyObj[props.labelName] ? "not-allowed" : "text", //鉛筆アイコンをクリック時にTextFieldにカーソルを表示
        "& .MuiInputBase-root.MuiOutlinedInput-root": {
            backgroundColor: changeBGColor(),
        },
        "& .MuiInputBase-root.MuiOutlinedInput-root.Mui-disabled:before": {
            borderBottomStyle: "solid",
        },
    };

    //ユーザー種別（'z001'）が選択されている場合のTextFieldのスタイル調整
    const SelectZ001TextFieldSx = {
        "& .MuiOutlinedInput-input": {
            color: changeTextColor(),
            textAlign: "center", //入力した値を中央寄せ
            paddingTop: "17px",
            paddingBottom: "17px",
        },
        "& .MuiInputAdornment-root.MuiInputAdornment-positionStart.MuiInputAdornment-filled.MuiInputAdornment-sizeMedium":
            {
                marginTop: "0px",
            },
        cursor: props.ReadOnlyObj[props.labelName] ? "not-allowed" : "text", //鉛筆アイコンをクリック時にTextFieldにカーソルを表示
        "& .MuiInputBase-root.MuiOutlinedInput-root": {
            backgroundColor: changeBGColor(),
        },
        "& .MuiInputBase-root.MuiOutlinedInput-root.Mui-disabled:before": {
            borderBottomStyle: "solid",
        },
    };

    return (
        <Box>
            {/* ユーザー種別がRFCとリサーチ以外の場合*/}
            {props.userType !== "z003" && props.userType !== "z001" && (
                <TextField
                    fullWidth
                    disabled={true}
                    value={props.InputValueObj[props.labelName]}
                    sx={TextFieldSx}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                {props.startAdornment}
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                {props.endAdornment}
                            </InputAdornment>
                        ),
                    }}
                    variant="outlined"
                />
            )}
            {/* ユーザー種別がRFCの場合 userTypeがz003*/}
            {props.userType === "z003" && (
                <TextField
                    fullWidth
                    value={props.InputValueObj[props.labelName]}
                    onChange={(e) =>
                        props.handleInputChange(e, props.labelName)
                    }
                    onBlur={(e) => props.handleInputBlur(e, props.labelName)}
                    sx={SelectZ003TextFieldSx}
                    InputProps={{
                        readOnly: props.disabled,
                        startAdornment: (
                            <InputAdornment position="start">
                                {props.startAdornment}
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                {props.endAdornment}
                            </InputAdornment>
                        ),
                    }}
                    variant="outlined"
                />
            )}
            {/* ユーザー種別がリサーチの場合 userTypeがz001 */}
            {props.userType === "z001" && (
                <Box display="flex" alignItems="center">
                    <TextField
                        fullWidth
                        value={props.InputValueObj[props.labelName]}
                        onClick={() => props.handleInputClick(props.labelName)}
                        onChange={(e) =>
                            props.handleInputChange(e, props.labelName)
                        }
                        onBlur={(e) =>
                            props.handleInputBlur(e, props.labelName)
                        }
                        inputRef={inputRef}
                        sx={SelectZ001TextFieldSx}
                        InputProps={{
                            readOnly: props.ReadOnlyObj[props.labelName],
                            startAdornment: (
                                <InputAdornment position="start">
                                    {props.startAdornment}
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    {props.endAdornment}
                                </InputAdornment>
                            ),
                        }}
                        variant="outlined"
                    />
                    {/* アイコン全体の表示非表示 */}
                    {props.ShowIconsObj[props.labelName] && (
                        <Box sx={{ minWidth: "85px" }}>
                            {/* 吹き出しアイコン */}
                            <IconButton
                                disabled={props.disabled}
                                onClick={() =>
                                    props.handleChatBubbleClick(
                                        props.labelName
                                    )
                                }
                                sx={iconHover}
                            >
                                <Box sx={chatBubbleCircleSx(props.disabled)}>
                                    <ChatBubbleIcon sx={chatBubbleIconSx} />
                                </Box>
                            </IconButton>
                            {/* 鉛筆アイコンの表示非表示 */}
                            {props.ShowEditIconObj[props.labelName] && (
                                <IconButton
                                    onClick={() =>
                                        handleEditIconClick(props.labelName)
                                    }
                                    sx={iconHover}
                                >
                                    <Box sx={editIconCircleSx}>
                                        <EditIcon />
                                    </Box>
                                </IconButton>
                            )}
                        </Box>
                    )}
                </Box>
            )}
        </Box>
    );
};

export default InputWithIcons;
