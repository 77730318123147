import * as React from "react";

import { useUserContext } from "../../../user-provider";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { setSaveStatus } from "../../../features/longPageSlice";
import {
    setObjLocationTraffic5mcut,
    setInitSelectList,
    setSelectList,
    setSelectList2,
    setTraffic5cutImages,
} from "../../../features/locationTraffic5mcutSlice";
import { LOCAL_CONSTANT } from "../../Const";
import {
    Typography,
    Box,
    Button,
    Checkbox,
} from "@mui/material";
import { useUtilityContext } from "../../../utility-provider";
import UploadIcon from "@mui/icons-material/Upload";
import {
    uploadFile,
    deleteUploadFile,
    getFileMetadata,
} from "../../../utility/storage";
import { postPython } from "../../../api/ApiCall";
import MessageDialog from "../../components/MessageDialog";
import { getAccessToken } from "../../Common";
import { setPermissionFunc } from "../../components/PermissionFunc";
import { DeleteConfirmationModal } from "../../ResearchReport/DeleteConfirmationModal";
import DeleteIcon from "@mui/icons-material/Delete";
import Cancellation5mcutModal from './Cancellation5mcutModal';
import { commonFileDownloadFileName } from "../../Common";


const TEMPLATE_FILE_NAME = "/template/5m外算入根拠_テンプレート.xlsx";
const UPLOAD_FILE_NAME = "upload_5m外算入根拠.xlsx";
const DEFAULT_IMAGE_01 = "/image/TrafficVolume/image001.png";
const DEFAULT_IMAGE_02 = "/image/TrafficVolume/image002.png";

export interface resultDialogProps {
    props: {};
}

const LocationTraffic5mcut: React.FC<resultDialogProps> = ({ props }) => {
    const utilityCtx = useUtilityContext();

    const objLongPageParam = useAppSelector((state) => state.longPage.param);
    const objLocationBase = useAppSelector((state) => state.longPage.locationBase);
    const objLocationTraffic5mcut = useAppSelector((state) => state.locationTraffic5mcut.obj);
    const objLongPageSaveStatus = useAppSelector((state) => state.longPage.saveStatus);
    const objTraffic5cutImages = useAppSelector((state) => state.locationTraffic5mcut.traffic5cutImages);

    const dispatch = useAppDispatch();

    const user = useUserContext();
    const [userType, setUserType] = React.useState("");
    const [userData, setUserData] = React.useState<any>({});

    const [file, setFile] = React.useState<File | null>(null)
    const [checkList, setCheckList] = React.useState([
        { 'name': '0', 'key': 'check_planting', 'checked': false, label: '店舗入口ドア予定位置前に植栽等障害物がない' },
        { 'name': '1', 'key': 'check_visibility', 'checked': false, label: '5ｍ外算入範囲から新店頭看板・入口ドアが視認可能​' },
        { 'name': '2', 'key': 'check_stairs', 'checked': false, label: '店舗入口ドア予定位置まで階段等段差がない' },
        { 'name': '3', 'key': 'check_count', 'checked': false, label: '道路の反対側をカウントする場合、車交通量が少なく店舗への往来に支障がない事' },
    ])
    const [messageDialogOpen, setMessageDialogOpen] = React.useState<boolean>(false);
    const [message, setMessage] = React.useState<string>("")
    const [hasButton, setHasButton] = React.useState<boolean>(false)
    const [deleteConfModalOpen, setDeleteConfModalOpen] = React.useState(false);

    const [approval5MMgr, setApproval5MMgr] = React.useState<boolean>(false)
    const [show5CutModal, setShow5CutModal] = React.useState<boolean>(false)

    React.useEffect(() => {
        const res: any = localStorage.getItem("codeMaster")
        if (res) {
            const d = JSON.parse(res)
            dispatch(setInitSelectList(d['others_facility'])) //その他施設 施設プルダウンリスト初期値
            dispatch(setSelectList(d['others_facility']))     //その他施設 3行目施設プルダウンリスト
            dispatch(setSelectList2(d['others_facility']))    //その他施設 4行目施設プルダウンリスト
        }
    }, [])


    //ユーザー取得タイミングによる画面制御
    React.useEffect(() => {
        if (JSON.parse(user.userData || 'null') !== null) {
            setUserType(JSON.parse(user.userData || 'null').user_type);
            setUserData(JSON.parse(user.userData));
        }
    }, [user]);

    React.useEffect(() => {
        setImagePath()
    }, [objLocationTraffic5mcut])

    const setChecked = (target: number) => {
        return checkList[Number(target)]['checked']
    }


    const handleDeleteConfModalOpen = () => {
        setDeleteConfModalOpen(true);
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const targetId = event.target.id;
        const targetList = targetId.split("_");
        dispatchLTraffic5Data(checkList[Number(targetList[2])]['key'], event.target.checked ? '1' : '0')
    };

    const dispatchLTraffic5Data = (labelName: string, value: string) => {
        if (objLocationTraffic5mcut) {
            dispatch(setObjLocationTraffic5mcut({ ...objLocationTraffic5mcut, [labelName]: value }));
            // 更新フラグ
            dispatch(setSaveStatus({ ...objLongPageSaveStatus, TrafficVolume: true }));
        }
    }

    const locationTraffic5mcutDisabled = () => {
        let disabled = true
        if (objLocationTraffic5mcut && objLocationTraffic5mcut.excel_path) {
            disabled = 
            (
                objLocationTraffic5mcut.approval_confirm_flag === "1" ||
                objLocationTraffic5mcut.approval_research_mgr_flag === "1" ||
                objLocationTraffic5mcut.approval_research_gm_flag === "1" ||
                objLocationTraffic5mcut.approval_mgr_flag === "1" ||
                objLocationTraffic5mcut.approval_gm_flag === "1"
            ) ? true : 
            setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.TRAFFIC_VOLUME, 'excel_dl_button');
        }

        return disabled
    }

    const handleClickFile = () => {
        const fileElem = document.getElementById("fileElem");
        if (fileElem) {
            fileElem.click()
        }
    }

    const handleChangeFile = (newFile: any) => {
        setFile(newFile.target.files[0]);
        newFile.target.value = '';
    };

    const fileDownload = () => {
        commonFileDownloadFileName(TEMPLATE_FILE_NAME)
    }

    const excelDownload = () => {
        if (objLocationTraffic5mcut && objLocationTraffic5mcut.excel_path) {
            commonFileDownloadFileName(objLongPageParam.planAreaId + "/" + objLongPageParam.branch + '/' + objLocationTraffic5mcut.excel_path)
        }
    }


    const closeMessageDialog = () => {
        setMessage("")
        setHasButton(false)
        setMessageDialogOpen(false)
    }

    const disapproval5Cut = () => {
        setShow5CutModal(true)
    }


    React.useEffect(() => {
        if (file) {
            fileUpload(file)
        }
    }, [file])

    const fileUpload = (file: any) => {
        const params = apiParams("create")
        if (utilityCtx.showSpinner) {
            utilityCtx.showSpinner();
        }
        (async () => {
            try {
                setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.CONVERT_EXCEL)
                setMessageDialogOpen(true)
                const uploadResult = await uploadFile(file, objLongPageParam.planAreaId + "/" + objLongPageParam.branch + '/excel/' + UPLOAD_FILE_NAME)
                const result = await postPython(params)
                dispatch(setObjLocationTraffic5mcut({
                    ...objLocationTraffic5mcut,
                    ["excel_path"]: '/excel/' + UPLOAD_FILE_NAME,
                    ["layout_img_path"]: result.data.data[0],
                    ["photo_img_path"]: result.data.data[1],
                }));
                setFile(null)
                setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.COMPLETE_CONVERT_EXCEL)
                setHasButton(true)
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
            } catch (e) {
                console.log(e);
                setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.UNCOMPLETE_CONVERT_EXCEL)
                setHasButton(true)
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
            }
        })();
    }


    const apiParams = (mode: string) => {
        const userData = JSON.parse(user.userData)

        return {
            mode: "regist",
            endPoint: "/excel2image",
            query: {
                mode: mode,
                prop_site_no: objLongPageParam.planAreaId,
                file_name: UPLOAD_FILE_NAME,
                branch: objLongPageParam.branch,
                layout_img_path: objLocationTraffic5mcut.layout_img_path,
                photo_img_path: objLocationTraffic5mcut.photo_img_path,
                check_planting: checkList[0]['checked'] === true ? '1' : '0',
                check_visibility: checkList[1]['checked'] === true ? '1' : '0',
                check_stairs: checkList[2]['checked'] === true ? '1' : '0',
                check_count: checkList[3]['checked'] === true ? '1' : '0',
                car_another_survey: objLocationTraffic5mcut.car_another_survey,
                people_another_survey: objLocationTraffic5mcut.people_another_survey,
                cut_survey: objLocationTraffic5mcut.cut_survey,
                others_facility_1: objLocationTraffic5mcut.others_facility_1,
                others_name_1: objLocationTraffic5mcut.others_name_1,
                others_distance_1: objLocationTraffic5mcut.others_distance_1,
                others_value_1: objLocationTraffic5mcut.others_value_1,
                others_facility_2: objLocationTraffic5mcut.others_facility_2,
                others_name_2: objLocationTraffic5mcut.others_name_2,
                others_distance_2: objLocationTraffic5mcut.others_distance_2,
                others_value_2: objLocationTraffic5mcut.others_value_2,
                others_facility_3: objLocationTraffic5mcut.others_facility_3,
                others_name_3: objLocationTraffic5mcut.others_name_3,
                others_distance_3: objLocationTraffic5mcut.others_distance_3,
                others_value_3: objLocationTraffic5mcut.others_value_3,
                others_facility_4: objLocationTraffic5mcut.others_facility_4,
                others_name_4: objLocationTraffic5mcut.others_name_4,
                others_distance_4: objLocationTraffic5mcut.others_distance_4,
                others_value_4: objLocationTraffic5mcut.others_value_4,
                user_data: {
                    update_top_department: userData.top_department_code_OA,
                    update_department: userData.department_code_OA,
                    update_job: userData.job_category_code_OA,
                    update_mail: userData.email.toString(),
                    update_stuff_number: userData.staff_number,
                    update_name: userData.user_name
                }
            }
        }
    }

    //ファイル削除
    const handleDeleteChange = () => {
        deleteFile()
    }


    const deleteFile = () => {
        const params = apiParams("delete")
        if (utilityCtx.showSpinner) {
            utilityCtx.showSpinner();
        }
        (async () => {
            try {
                setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.DELETE_FILE)
                setMessageDialogOpen(true)
                // ファイル削除
                const path = '/' + objLongPageParam.planAreaId + '/' + objLongPageParam.branch + '/'
                const deleteFile = await getFileMetadata(path + objLocationTraffic5mcut.excel_path);
                await deleteUploadFile(deleteFile, path + objLocationTraffic5mcut.excel_path)
                const layoutImgFile = await getFileMetadata(path + objLocationTraffic5mcut.layout_img_path);
                await deleteUploadFile(layoutImgFile, path + objLocationTraffic5mcut.layout_img_path)
                const photoImgFile = await getFileMetadata(path + objLocationTraffic5mcut.photo_img_path);
                await deleteUploadFile(photoImgFile, path + objLocationTraffic5mcut.photo_img_path)
                // python処理
                await postPython(params)
                setFile(null)
                setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.SUCCESS_DELETE_FILE)
                setHasButton(true)
                dispatch(setObjLocationTraffic5mcut({
                    ...objLocationTraffic5mcut,
                    ["excel_path"]: "",
                    ["layout_img_path"]: "",
                    ["photo_img_path"]: "",
                }));
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
            } catch (e) {
                console.log(e);
                setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.ERROR_DELETE_FILE)
                setHasButton(true)
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
            }
        })();
    }

    const setImagePath = () => {
        const bucket_path = process.env.REACT_APP_GOOGLE_STORAGE
            + '/' + process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
            + '/' + objLongPageParam.planAreaId
            + '/' + objLongPageParam.branch
        const access_token = getAccessToken()

        let layout_img_path = DEFAULT_IMAGE_01
        let photo_img_path = DEFAULT_IMAGE_02
        let approvalResearchMgrStamp = ""
        let approvalResearchGmStamp = ""
        let approvalMgrStamp = ""
        let approvalGmStamp = ""
        if (objLocationTraffic5mcut.layout_img_path) {
            layout_img_path = bucket_path + objLocationTraffic5mcut.layout_img_path + access_token
        }
        if (objLocationTraffic5mcut.photo_img_path) {
            photo_img_path = bucket_path + objLocationTraffic5mcut.photo_img_path + access_token
        }

        if (objLocationTraffic5mcut.approval_research_mgr_flag === '1') {
            setApproval5MMgr(true)
        }
        // 5m外印影画像
        if (objLocationTraffic5mcut.approval_research_mgr_stamp !== '') {
            approvalResearchMgrStamp = bucket_path + objLocationTraffic5mcut.approval_research_mgr_stamp + access_token
        }
        if (objLocationTraffic5mcut.approval_research_gm_stamp !== '') {
            approvalResearchGmStamp = bucket_path + objLocationTraffic5mcut.approval_research_gm_stamp + access_token
        }
        if (objLocationTraffic5mcut.approval_mgr_stamp !== '') {
            approvalMgrStamp = bucket_path + objLocationTraffic5mcut.approval_mgr_stamp + access_token
        }
        if (objLocationTraffic5mcut.approval_gm_stamp !== '') {
            approvalGmStamp = bucket_path + objLocationTraffic5mcut.approval_gm_stamp + access_token
        }
        checkList[0]['checked'] = objLocationTraffic5mcut.check_planting === "0" ? false : true
        checkList[1]['checked'] = objLocationTraffic5mcut.check_visibility === "0" ? false : true
        checkList[2]['checked'] = objLocationTraffic5mcut.check_stairs === "0" ? false : true
        checkList[3]['checked'] = objLocationTraffic5mcut.check_count === "0" ? false : true
        dispatch(setTraffic5cutImages({
            ...objTraffic5cutImages,
            layout_img_path: layout_img_path,
            photo_img_path: photo_img_path,
            approvalResearchMgrStamp: approvalResearchMgrStamp,
            approvalResearchGmStamp: approvalResearchGmStamp,
            approvalMgrStamp: approvalMgrStamp,
            approvalGmStamp: approvalGmStamp,
        }))
    }

    const handle_approval = () => {
        let disabled = true
        if (
            setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.TRAFFIC_5MCUT, 'disapproval_5mcut_button')
        ) {
            if (
                objLocationTraffic5mcut.approval_research_mgr_flag ||
                objLocationTraffic5mcut.approval_research_gm_flag ||
                objLocationTraffic5mcut.approval_mgr_flag ||
                objLocationTraffic5mcut.approval_gm_flag
            )
                disabled = false
        }
        return disabled
    }


    return (
        <Box>
            <Box><h3>5m外算入根拠</h3></Box>
            <Box>
                <Box sx={{ display: 'flex' }}>
                    <Box sx={{ width: '120px', border: '1px solid #000', textAlign: 'center' }}>リサーチ Mgr</Box>
                    <Box sx={{ width: '120px', border: '1px solid #000', textAlign: 'center', display: approval5MMgr ? 'block' : 'none' }}>リサーチ GM</Box>
                    <Box sx={{ width: '120px', border: '1px solid #000', textAlign: 'center' }}>リクルート Mgr</Box>
                    <Box sx={{ width: '120px', border: '1px solid #000', textAlign: 'center' }}>リクルート GM</Box>
                </Box>
                <Box sx={{ display: 'flex' }}>
                    <Box sx={{ width: '120px', height: '92px', border: '1px solid #000', textAlign: 'center' }}><img src={objTraffic5cutImages.approvalResearchMgrStamp}></img></Box>
                    <Box sx={{ width: '120px', height: '92px', border: '1px solid #000', textAlign: 'center', display: approval5MMgr ? 'block' : 'none' }}><img src={objTraffic5cutImages.approvalResearchGmStamp}></img></Box>
                    <Box sx={{ width: '120px', height: '92px', border: '1px solid #000', textAlign: 'center' }}><img src={objTraffic5cutImages.approvalMgrStamp}></img></Box>
                    <Box sx={{ width: '120px', height: '92px', border: '1px solid #000', textAlign: 'center' }}><img src={objTraffic5cutImages.approvalGmStamp}></img></Box>
                </Box>
                <Box sx={{ margin: '12px 0' }}>
                    <Button
                        variant="contained" color="error" size="medium"
                        onClick={disapproval5Cut}
                        disabled={handle_approval()}
                    >5m外承認を外す
                    </Button>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '560px', maxWidth: '560px' }}>
                <Box sx={{ '> button': { margin: '10px' } }}>
                    <Button
                        variant="contained" color="success" size="small"
                        onClick={fileDownload}
                        disabled={
                            (
                                objLocationTraffic5mcut.approval_confirm_flag === "1" ||
                                objLocationTraffic5mcut.approval_research_mgr_flag === "1" ||
                                objLocationTraffic5mcut.approval_research_gm_flag === "1" ||
                                objLocationTraffic5mcut.approval_mgr_flag === "1" ||
                                objLocationTraffic5mcut.approval_gm_flag === "1"
                            ) ? true : 
                            setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.TRAFFIC_VOLUME, 'template_dl_button')
                        }
                    >{LOCAL_CONSTANT.LABEL.TEMPLATE_DL}
                    </Button>
                    <Button
                        variant="contained" color="success" size="small"
                        onClick={excelDownload}
                        disabled={locationTraffic5mcutDisabled()}
                    >{LOCAL_CONSTANT.LABEL.EXCEL_DL}
                    </Button>
                </Box>
                <Box>
                    <input
                        id="fileElem"
                        type="file"
                        style={{ display: 'none' }}
                        onChange={handleChangeFile}
                        accept=".xls,.xlsx"
                    />
                    <Button sx={{ color: 'black' }}
                        disabled={
                            (
                                objLocationTraffic5mcut.approval_confirm_flag === "1" ||
                                objLocationTraffic5mcut.approval_research_mgr_flag === "1" ||
                                objLocationTraffic5mcut.approval_research_gm_flag === "1" ||
                                objLocationTraffic5mcut.approval_mgr_flag === "1" ||
                                objLocationTraffic5mcut.approval_gm_flag === "1"
                            ) ? true : 
                            setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.TRAFFIC_VOLUME, 'uploadButton')
                        }
                    ><UploadIcon fontSize="large" onClick={handleClickFile} />
                    </Button>
                    <Button sx={{ color: 'black' }}
                        disabled={locationTraffic5mcutDisabled()}
                    ><DeleteIcon fontSize="large" onClick={handleDeleteConfModalOpen} />
                    </Button>
                </Box>
            </Box>
            <Box sx={{ display: 'flex' }}>
                <Box sx={{ margin: '10px' }}>
                    <Typography>配置図または航空写真<br />（5m内外の計測範囲を記載）</Typography>
                    <Box sx={{ border: '1px solid black' }}>
                        <img src={objTraffic5cutImages.layout_img_path} style={{ width: '560px', maxWidth: '560px' }} />
                    </Box>
                </Box>
                <Box sx={{ margin: '10px' }}>
                    <Typography>写真（障害物有無がわかる）<br />（5m内外の計測範囲を記載）</Typography>
                    <Box sx={{ border: '1px solid black' }}>
                        <img src={objTraffic5cutImages.photo_img_path} style={{ width: '560px', maxWidth: '560px' }} />
                    </Box>
                </Box>
            </Box>
            <Box><Typography>5m外歩行者TR算入確認</Typography></Box>
            {checkList.map((row: any) => (
                <Typography key={row.key}>
                    <Checkbox
                        id={'check_num_' + row['name']}
                        checked={setChecked(row['name'])}
                        onChange={handleChange}
                        disabled={
                            (
                                objLocationTraffic5mcut.approval_confirm_flag === "1" ||
                                objLocationTraffic5mcut.approval_research_mgr_flag === "1" ||
                                objLocationTraffic5mcut.approval_research_gm_flag === "1" ||
                                objLocationTraffic5mcut.approval_mgr_flag === "1" ||
                                objLocationTraffic5mcut.approval_gm_flag === "1"
                            ) ? true : 
                            setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.TRAFFIC_VOLUME, 'traffic5mcut')
                        }
                    ></Checkbox>{row.label}</Typography>
            ))}
            <MessageDialog props={{
                open: messageDialogOpen,
                handleModal: closeMessageDialog,
                body: message,
                hasButton: hasButton,
                buttonBody: LOCAL_CONSTANT.LABEL.CLOSE,
            }}
            />
            {/* 削除確認モーダル */}
            <DeleteConfirmationModal modalOpen={deleteConfModalOpen} setModalOpen={setDeleteConfModalOpen} deleteMethod={handleDeleteChange} />
            <Cancellation5mcutModal props={{
                open: show5CutModal,
                handleModal: setShow5CutModal,
                planAreaId: objLongPageParam.planAreaId,
                branch: objLongPageParam.branch,
            }} />
        </Box>
    )
}

export default LocationTraffic5mcut;