import * as React from 'react';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { Status, Wrapper } from "@googlemaps/react-wrapper";
import {
    InputLabel,
    Box,
    Modal,
    Button,
    Divider,
    Grid,
    Tabs,
    Tab,
    Select,
    MenuItem,
    SelectChangeEvent,
    TextField,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    IconButton,
    FormControl,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Comment from '../../components/Comment';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import { LOCAL_CONSTANT } from '../../Const';

import {
    PlanAreaType,
    existingSejStoreType,
    impactCounterType,
    listInterFace,
    impactCounterInterFace,
    storeForimpactCounterInterFace,
} from "../Interface";


import {
    calcLineLengthGeoJson,
    googleMapsLine2GeoJsonLine,
} from "../../../utility/turf"
import {
    startDrawingManager,
    endDrawingManager,
} from "../../../utility/drawingManager";
import { drawPlanArea } from "../viewData/PlanArea";
import { drawExistingSejPlanArea } from "../viewData/ExistingSejPlanArea";
import { drawImpactCounterSummary } from "../viewData/ImpactCounter";
import { putMarker } from "../viewData/viewDataFunction";
import { useUtilityContext } from '../../../utility-provider';
import { useUserContext } from '../../../user-provider';
import { apiRequest } from "../../../api/ApiCall";
import { userLogging } from "../../../utility/firestore";
import {
    setImpactLabelMarker,
    impactMarker,
    registImpactCounter,
    exsitesStoreCode,
    callApi,
} from "./AddImpactFunction";
import { setPermissionFunc } from '../../components/PermissionFunc';
import { setObjEditParams } from '../../../features/impactSlice';
import {
    DISTANCE,
    GRAY_COLOR,
    GREEN_COLOR,
    YELLOW_COLOR,
    CAR_COLOR,
    PEOPLE_COLOR,
} from "./LocationImpactFunction"

const render = (status: Status) => {
    return <h1>{status}</h1>;
};
const MAX_ADD_IMPACT = 7
const WARNING_MESSAGE = LOCAL_CONSTANT.CONFIRM_MESSAGE.LIMIT_OF_IMPACT_ADDED_LIST.replace('__MAX_ADD_IMPACT__', MAX_ADD_IMPACT.toString())

const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "96%",
    height: "90vh",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
};

const tabStyle = {
    maxWidth: '40px',
    padding: '0',
    margin: '0',
    minWidth: '30px',
    fontSize: '0.8em',
}

export interface addImpactModalProps {
    props: {
        open: boolean,
        handleModal: React.Dispatch<React.SetStateAction<boolean>>,
        mode: string,
        latlng: any,
        locationImpactList: any,
        targetImpact: any,
        handleSetReadApi: any,
    }
}

const AddImpactModal: React.FC<addImpactModalProps> = ({ props }) => {
    const utilityCtx = useUtilityContext();
    const user = useUserContext();
    // 初期
    const [open, setOpen] = React.useState(false);
    const [map, setMap] = React.useState<google.maps.Map>();

    // マップ中央
    const [center, setCenter] = React.useState<google.maps.LatLngLiteral>(props.latlng);
    // マップ描画
    const [load, setLoad] = React.useState(false)
    // 各処理完了フラグ
    const [hasPlanArea, setHasPlanArea] = React.useState<boolean>(false);
    // 
    const [polyLineButton, setPolyLineButton] = React.useState<string>("")
    const [removeDisabled, setRemoveDisabled] = React.useState<boolean>(true);
    // モード
    const [mode, setMode] = React.useState<string>("");
    const [impactDraw, setImpactDraw] = React.useState<boolean>(false)
    // 道なり距離
    const [drawingManager, setDrawingManager] = React.useState<google.maps.drawing.DrawingManager | undefined>()
    const [drawPolyline, setDrawPolyline] = React.useState<google.maps.Polyline | undefined>()
    // 取得結果格納用
    const [planAreaList, setPlanAreaList] = React.useState<PlanAreaType[] | undefined | null>();
    const [planArea, setPlanArea] = React.useState<PlanAreaType>();
    const [existingSejStoreList, setExistingSejStoreList] = React.useState<existingSejStoreType[]>([]);
    const [selectStore, setSelectStore] = React.useState<storeForimpactCounterInterFace | null>(null);
    const [impactCounterList, setImpactCounterList] = React.useState<impactCounterType[]>([]);

    // 既存店データ表示用
    const [exSejStoreList, setExSejStoreList] = React.useState<listInterFace[]>([]);
    // 対象既存店
    const [targetExSejStore, setTargetExSejStore] = React.useState<existingSejStoreType[]>([]);

    // 影響度データ表示用
    const [impactCounterSummary, setImpactCounterSummary] = React.useState<impactCounterInterFace[]>([]);
    const [impactMarkerList, setImpactMarkerList] = React.useState<google.maps.Marker[]>([])
    const [cancelDisabled, setCancelDisabled] = React.useState(false);
    const [addDisabled, setAddDisabled] = React.useState(false);

    const objLongPageParam = useAppSelector((state) => state.longPage.param);
    const objImpactEditParams = useAppSelector((state) => state.impact.editParams);
    const objLocationBase = useAppSelector((state) => state.longPage.locationBase);

    const dispatch = useAppDispatch();

    // 閉じる
    const handleClose = () => {
        setOpen(false)
        props.handleModal(false)
        //
        setLoad(false)
        setHasPlanArea(false)
        setPolyLineButton("")
        dispatch(setObjEditParams({
            ...objImpactEditParams,
            selectTab: ""
        }))
        setImpactDraw(false)
        setImpactCounterList([])
        setSelectStore(null)
    };

    const onIdle = (m: google.maps.Map) => {
        setCenter(m.getCenter()!.toJSON());
        // Map保存
        setMap(m)
    };

    React.useEffect(() => {
        if (props.open) {
            setOpen(props.open);
            setLoad(true);
            setMode(props.mode);
        }
    }, [props.open])

    // マップ中央調整
    React.useEffect(() => {
        if (map) {
            const lanlot = map.getCenter()!.toJSON()
            if (lanlot.lat !== center.lat && lanlot.lng !== center.lng) {
                map.setCenter(center)
            }
        }
    }, [center])

    React.useEffect(() => {
        if (load) {
            if (utilityCtx.showSpinner) {
                utilityCtx.showSpinner()
            }
            // 候補地呼び出し
            callApi('planArea', objLongPageParam.planAreaId, center.lat, center.lng, DISTANCE, objLocationBase.location_excel_sequence, user)
                .then((res: any) => {
                    setCenter({
                        lat: res[0]['centerlat'],
                        lng: res[0]['centerlon'],
                    })
                    setPlanAreaList(res)
                    setPlanArea(res[0])
                    setHasPlanArea(true)

                }).catch((e) => {
                    console.log(e)
                }).finally(() => {
                    if (utilityCtx.hideSpinner) {
                        utilityCtx.hideSpinner();
                    }
                    if (planArea) {
                        setCenter({
                            lat: planArea['centerlat'],
                            lng: planArea['centerlon'],
                        })
                    }
                    setLoad(false)
                })
        }
    }, [load])

    const loadExStore = () => {
        // 既存店呼び出し
        callApi('getLongPageData', objLongPageParam.planAreaId, center.lat, center.lng, DISTANCE, objLocationBase.location_excel_sequence, user)
            .then((res: any) => {
                setExistingSejStoreList(res.getExistingSejPlanArea)
                setImpactCounterList(res.getImpactCounter)
            })
    }

    const setClickableSejStore = (list: any, clickable: boolean) => {
        list.map((row: any) => {
            row.marker.setOptions({
                clickable: clickable,
            })
        })
    }

    const setClickableImpact = (list: any, clickable: boolean) => {
        list.map((row: any) => {
            if (clickable !== false) {
                row.arrowPoly.setMap(map!)
                row.dummy.setMap(map!)
            } else {
                row.arrowPoly.setMap(null)
                row.dummy.setMap(null)
            }
        })
    }


    /**
     * 候補地処理
     */
    React.useEffect(() => {
        if (map && planAreaList && planAreaList.length !== 0) {
            drawPlanArea(planAreaList, map);
            setHasPlanArea(true)
            loadExStore()
        }
    }, [planAreaList]);

    /**
     * 既存店処理
     */
    React.useEffect(() => {
        if (
            map
            && planAreaList && planAreaList.length !== 0
            && existingSejStoreList && existingSejStoreList.length !== 0
        ) {
            const list = drawExistingSejPlanArea(
                existingSejStoreList,
                map,
                setExSejStoreList,
                planAreaList
            )
            if (props.mode === 'edit') {
                setClickableSejStore(exSejStoreList, false)
            }
            const relist = reloadStore(list)
            setExSejStoreList(relist);
            // loadImpact()
        }
    }, [existingSejStoreList]);

    const reloadStore = (list: any) => {
        if (map) {
            list.forEach((data: any) => {
                // 登録済み置き換え
                const copyData = JSON.parse(JSON.stringify(props.targetImpact))
                copyData.centerlat = copyData.store_centerlat
                copyData.centerlon = copyData.store_centerlon
                const isImpact = exsitesStoreCode(props.locationImpactList, data)
                if (isImpact) {
                    if (mode === "add") {
                        data = impactMarker(map, data, props.targetImpact)
                    } else if (mode === "edit") {
                        const selectImpact = putMarker(copyData, 'selectImpact')
                        selectImpact.setMap(map)
                        data.selectImpact = selectImpact
                    }
                }
                if (copyData && copyData.store_code === data.data.id) {
                    const counter = JSON.parse(copyData.location_impact_counter)
                    const sortData = counter.map((data: any) => {
                        return data.sort((a: any, b: any) => Number(a.counter_seq) - Number(b.counter_seq))
                    })
                    dispatch(setObjEditParams({
                        ...objImpactEditParams,
                        selectTab: "0"
                    }))
                    let pathDraw = false
                    let counterList: any = {}
                    let routePath: any = null
                    const tmpMarkerList: any = []
                    sortData.map((data: any) => {
                        data.map((row: any) => {
                            let intersection_type = ""
                            impactCounterList.map((ic_row: any) => {
                                if (
                                    ic_row.uuid === row.counter_uuid &&
                                    Number(ic_row.seq) === Number(row.counter_of_seq)
                                ) {
                                    intersection_type = ic_row.intersection_type
                                }
                            })
                            const data = {
                                selecter: '',
                                impactImage: "",
                                value: "",
                                impactData: {
                                    seq: row.route_seq,
                                    intersection_type: intersection_type,
                                    uuid: row.counter_uuid,
                                    counter_of_seq: row.counter_of_seq,
                                    name: row.counter_name,
                                    centerlat: row.counter_centerlat,
                                    centerlon: row.counter_centerlon,
                                    count_date: row.counter_date.replace('T', ' '),
                                    shape: row.shape,
                                    road_distance: row.road_distance,
                                    top_left_count: row.top_left_count,
                                    top_bottom_count: row.top_bottom_count,
                                    top_right_count: row.top_right_count,
                                    left_top_count: row.left_top_count,
                                    left_bottom_count: row.left_bottom_count,
                                    left_right_count: row.left_right_count,
                                    bottom_top_count: row.bottom_top_count,
                                    bottom_left_count: row.bottom_left_count,
                                    bottom_right_count: row.bottom_right_count,
                                    right_top_count: row.right_top_count,
                                    right_left_count: row.right_left_count,
                                    right_bottom_count: row.right_bottom_count,
                                    top_left_type: row.top_left_type,
                                    top_bottom_type: row.top_bottom_type,
                                    top_right_type: row.top_right_type,
                                    left_top_type: row.left_top_type,
                                    left_bottom_type: row.left_bottom_type,
                                    left_right_type: row.left_right_type,
                                    bottom_top_type: row.bottom_top_type,
                                    bottom_left_type: row.bottom_left_type,
                                    bottom_right_type: row.bottom_right_type,
                                    right_top_type: row.right_top_type,
                                    right_left_type: row.right_left_type,
                                    right_bottom_type: row.right_bottom_type,
                                    type: row.type,
                                    tobaccolicense: row.tobaccolicense,
                                    'top_left_type_color': row.top_left_type === '0' ? GRAY_COLOR : row.top_left_type === '1' ? GREEN_COLOR : YELLOW_COLOR,
                                    'top_bottom_type_color': row.top_bottom_type === '0' ? GRAY_COLOR : row.top_bottom_type === '1' ? GREEN_COLOR : YELLOW_COLOR,
                                    'top_right_type_color': row.top_right_type === '0' ? GRAY_COLOR : row.top_right_type === '1' ? GREEN_COLOR : YELLOW_COLOR,
                                    'left_top_type_color': row.left_top_type === '0' ? GRAY_COLOR : row.left_top_type === '1' ? GREEN_COLOR : YELLOW_COLOR,
                                    'left_bottom_type_color': row.left_bottom_type === '0' ? GRAY_COLOR : row.left_bottom_type === '1' ? GREEN_COLOR : YELLOW_COLOR,
                                    'left_right_type_color': row.left_right_type === '0' ? GRAY_COLOR : row.left_right_type === '1' ? GREEN_COLOR : YELLOW_COLOR,
                                    'bottom_top_type_color': row.bottom_top_type === '0' ? GRAY_COLOR : row.bottom_top_type === '1' ? GREEN_COLOR : YELLOW_COLOR,
                                    'bottom_left_type_color': row.bottom_left_type === '0' ? GRAY_COLOR : row.bottom_left_type === '1' ? GREEN_COLOR : YELLOW_COLOR,
                                    'bottom_right_type_color': row.bottom_right_type === '0' ? GRAY_COLOR : row.bottom_right_type === '1' ? GREEN_COLOR : YELLOW_COLOR,
                                    'right_top_type_color': row.right_top_type === '0' ? GRAY_COLOR : row.right_top_type === '1' ? GREEN_COLOR : YELLOW_COLOR,
                                    'right_left_type_color': row.right_left_type === '0' ? GRAY_COLOR : row.right_left_type === '1' ? GREEN_COLOR : YELLOW_COLOR,
                                    'right_bottom_type_color': row.right_bottom_type === '0' ? GRAY_COLOR : row.right_bottom_type === '1' ? GREEN_COLOR : YELLOW_COLOR,
                                    'hasEdit': false,
                                    'commentOpen': false,
                                },
                                lat: row.counter_centerlat,
                                lng: row.counter_centerlon,
                            }
                            const shape = JSON.parse(row.shape)
                            const path = new google.maps.Polyline({
                                path: shape.geometry.coordinates.map((val: any) => {
                                    return { lat: val[1], lng: val[0] }
                                }),
                                strokeColor: Number(row.route_seq) < 2 ? CAR_COLOR : PEOPLE_COLOR,
                            })
                            if (row.route_seq === '0' && pathDraw === false) {
                                pathDraw = true
                                path.setMap(map!)
                            }

                            if (counterList[row.route_seq] === undefined) {
                                counterList[row.route_seq] = {
                                    path: path,
                                    impactCounter: {},
                                    road_distance: row.road_distance,
                                }
                            }
                            counterList[row.route_seq]['impactCounter'][row.counter_uuid] = JSON.parse(JSON.stringify(data))
                            routePath = path;

                            // マーカー
                            if (row.route_seq === '0') {
                                let impactNumberStr = LOCAL_CONSTANT.LABEL.FRONT_FOR_EXISTING_STORES
                                if (Number(row.counter_seq) > 1) {
                                    impactNumberStr = LOCAL_CONSTANT.LABEL.INTERSECTION + (Number(row.counter_seq) - 1).toString()
                                }
                                const impactMarker: google.maps.Marker = setImpactLabelMarker(
                                    row.counter_centerlat,
                                    row.counter_centerlon,
                                    impactNumberStr
                                )
                                impactMarker.setMap(map!)
                                tmpMarkerList.push(impactMarker)
                            }
                        })
                    })
                    setImpactMarkerList(tmpMarkerList)
                    setSelectStore({
                        key: data.key,
                        type: data.type,
                        data: data.data,
                        marker: data.marker,
                        selectImpact: data.selectImpact,
                        path: routePath,
                        counter: counterList
                    })
                    setClickableSejStore(exSejStoreList, false)
                }
            })
        }

        return list
    }


    const setClickEventSejStore = () => {
        exSejStoreList.map((exSejStore) => {
            // 一度クリア
            google.maps.event.clearListeners(exSejStore.marker, "click")
            // 再設定
            exSejStore.marker.addListener('click', (e: google.maps.MapMouseEvent) => onClickExistingSejStore(
                e,
                exSejStore,
            ));
        })
    }

    /**
     * 既存店clickイベント
     * @param e 
     * @param exSej 
     */
    const onClickExistingSejStore = (e: google.maps.MapMouseEvent, exSej: any) => {
        if (props.mode !== 'edit') {
            exSej.selectImpact = putMarker(exSej.data, 'selectImpact')
            exSej.selectImpact.setMap(map);
            dispatch(setObjEditParams({
                ...objImpactEditParams,
                selectTab: "0"
            }))
            setSelectStore({
                key: exSej.key,
                type: exSej.type,
                data: exSej.data,
                marker: exSej.marker,
                selectImpact: exSej.selectImpact,
                path: null,
                counter: {}
            })
            setClickableSejStore(exSejStoreList, false)
            setRemoveDisabled(false)
        }
    }

    React.useEffect(() => {
        if (map && impactCounterList && impactCounterList.length !== 0) {
            const impactList = reloadImpactCounter(objImpactEditParams.selectTab)
            setImpactCounterSummary(impactList)
        }
        if (map &&
            impactCounterList && impactCounterList.length !== 0 && selectStore
        ) {
            selectIntersectionType(impactCounterList, selectStore)
        }

    }, [impactCounterList, selectStore])

    const selectIntersectionType = (impactCounterList: any, selectStore: any) => {
        if (selectStore) {
            Object.keys(selectStore.counter).map((route_seq: any) => {
                Object.keys(selectStore.counter[route_seq].impactCounter).map(uuid => {
                    const tmp_selecter: any = []
                    impactCounterList.map((ic_row: any) => {
                        if (
                            ic_row.uuid === selectStore.counter[route_seq].impactCounter[uuid].impactData.uuid &&
                            Number(ic_row.seq) === Number(selectStore.counter[route_seq].impactCounter[uuid].impactData.counter_of_seq)
                        ) {
                            selectStore.counter[route_seq].impactCounter[uuid].impactData.intersection_type = ic_row.intersection_type
                        }
                        // selecter
                        if (ic_row.uuid === selectStore.counter[route_seq].impactCounter[uuid].impactData.uuid) {
                            tmp_selecter.push(ic_row)
                        }
                    })
                    tmp_selecter.map((ic_row: any) => {
                        if (
                            ic_row.uuid === selectStore.counter[route_seq].impactCounter[uuid].impactData.uuid &&
                            Number(ic_row.seq) === Number(selectStore.counter[route_seq].impactCounter[uuid].impactData.counter_of_seq)
                        ) {
                            selectStore.counter[route_seq].impactCounter[uuid].value = ic_row.seq
                        }
                    })
                    selectStore.counter[route_seq].impactCounter[uuid].selecter = tmp_selecter
                })
            })
        }

        setSelectStore(selectStore)
    }


    /**
     * 影響度処理
     */
    const reloadImpactCounter = (selectTab: string) => {
        let list: any = []
        if (map && impactCounterList && impactCounterList.length !== 0) {
            (async () => {
                impactCounterSummary?.map((row) => {
                    row.arrowPoly.setMap(null)
                    row.dummy.setMap(null)
                })
                list = await drawImpactCounterSummary(
                    impactCounterList,
                    selectTab,
                )
                setImpactCounterSummary(list);
                setClickableImpact(list, true)
            })();
        }
        return list
    }

    const setClickEventImpactCounter = () => {
        if (map) {
            impactCounterSummary.map((item) => {
                google.maps.event.clearListeners(item.arrowPoly, "click")
                google.maps.event.clearListeners(item.dummy, "click")
                item.arrowPoly.addListener('click', (e: google.maps.MapMouseEvent) => onClickImpactCounter(
                    e,
                    item,
                ));
                item.dummy.addListener('click', (e: google.maps.MapMouseEvent) => onClickImpactCounter(
                    e,
                    item,
                ));
            })
        }
    }

    /**
     * 影響度clickイベント
     * @param map 
     * @param e 
     * @param data 
     */
    const onClickImpactCounter = (
        e: google.maps.MapMouseEvent,
        impactCounter: any,
    ) => {
        if (selectStore) {
            if (selectStore.counter[objImpactEditParams.selectTab] !== undefined) {
                if (selectStore.counter[objImpactEditParams.selectTab]['impactCounter'][impactCounter.key] !== undefined) {
                    return
                }
                if (Object.keys(selectStore.counter[objImpactEditParams.selectTab]['impactCounter']).length >= MAX_ADD_IMPACT) {
                    onEventHandle('errorMessage', { messageText: WARNING_MESSAGE })
                    return
                }
                const data = {
                    selecter: impactCounter.counter,
                    value: '',
                    impactData: {
                        top_left_count: 0,
                        top_bottom_count: 0,
                        top_right_count: 0,
                        left_top_count: 0,
                        left_bottom_count: 0,
                        left_right_count: 0,
                        bottom_top_count: 0,
                        bottom_left_count: 0,
                        bottom_right_count: 0,
                        right_top_count: 0,
                        right_left_count: 0,
                        right_bottom_count: 0,
                        'hasEdit': false,
                        'value': '',
                    },
                    lat: impactCounter.data.centerlat,
                    lng: impactCounter.data.centerlon,
                }
                selectStore.counter[objImpactEditParams.selectTab]['impactCounter'][impactCounter.key] = data
                setImpactDraw(!impactDraw)
                const impactText = onEventHandle('impactNumberStr', { selectTab: objImpactEditParams.selectTab, 'key': impactCounter.key })
                if (objImpactEditParams.selectTab !== '' && impactText !== '' && impactText !== undefined) {
                    const tmpList = impactMarkerList
                    const impactMarker: google.maps.Marker = setImpactLabelMarker(
                        impactCounter.data.centerlat,
                        impactCounter.data.centerlon,
                        impactText.toString()
                    )
                    impactMarker.setMap(map!)
                    tmpList.push(impactMarker)
                    setImpactMarkerList(tmpList)
                }
            }
        }
    }

    // イベント
    const onEventHandle = (keyName: string, params: any) => {
        const tab = ['0', '1', '2', '3']
        switch (keyName) {
            case 'showSelectTime':
                const date = params.data.count_date.substr(0, 10)
                const beforeHour = params.data.count_date.substr(11, 2)
                const beforeMinute = params.data.count_date.substr(14, 2)
                let afterHour = beforeHour
                let afterMinute = Number(beforeMinute) + params.data.count_time
                if (afterMinute > 60) {
                    afterHour = Number(afterHour) + 1
                    afterMinute = Number(afterMinute) - 60
                } else if (afterMinute === 0) {
                    afterHour = Number(afterHour) + 1
                }
                return date + ' ' + beforeHour.toString().padStart(2, "0")
                    + ':' + beforeMinute.toString().padStart(2, "0")
                    + '～' + afterHour.toString().padStart(2, "0")
                    + ':' + afterMinute.toString().padStart(2, "0");
            case 'impactNumberStr':
                let impactNumberStr = ''
                if (selectStore) {
                    if (
                        selectStore.counter !== undefined &&
                        selectStore.counter[params.selectTab] !== undefined &&
                        selectStore.counter[params.selectTab].impactCounter !== undefined
                    ) {
                        let count = 0
                        Object.keys(selectStore.counter[params.selectTab].impactCounter).map(uuid => {
                            if (uuid === params.key) {
                                impactNumberStr = LOCAL_CONSTANT.LABEL.FRONT_FOR_EXISTING_STORES
                                if (count !== 0) {
                                    impactNumberStr = LOCAL_CONSTANT.LABEL.INTERSECTION + count
                                }
                            }
                            count = count + 1
                        })
                    }
                }
                return impactNumberStr
            case 'checkSelectCounter':
                let checkSelect = false
                if (selectStore) {
                    if (
                        selectStore.counter !== undefined &&
                        selectStore.counter[params.selectTab] !== undefined &&
                        selectStore.counter[params.selectTab].impactCounter !== undefined
                    ) {
                        Object.keys(selectStore.counter[params.selectTab].impactCounter).map(key => {
                            if (selectStore.counter[params.selectTab].impactCounter[key].selecter !== undefined) {
                                checkSelect = true
                            }
                        })
                    }
                }

                return checkSelect
            case 'checkImpactCounter':
                let errorMessage = ""
                if (selectStore === null || selectStore === undefined) {
                    errorMessage = LOCAL_CONSTANT.CONFIRM_MESSAGE.ADD_IMPACT
                } else if (Object.keys(selectStore?.counter).length === 0) {
                    errorMessage = LOCAL_CONSTANT.CONFIRM_MESSAGE.ADD_IMPACT
                } else {
                    Object.keys(selectStore.counter).map(selectTab => {
                        const routeNumber = ((Number(selectTab) % 2) + 1).toString()
                        const route = Number(selectTab) <= 1 ? LOCAL_CONSTANT.LABEL.CAR + routeNumber : LOCAL_CONSTANT.LABEL.PEOPLE + routeNumber
                        if (Object.keys(selectStore.counter[selectTab].impactCounter).length === 0) {
                            errorMessage += LOCAL_CONSTANT.CONFIRM_MESSAGE.HAS_INTERSECTION.replace('__ROUTE__', route.toString())
                        } else {
                            Object.keys(selectStore.counter[selectTab].impactCounter).map(uuid => {
                                if (selectStore.counter[selectTab].impactCounter[uuid].value === "") {
                                    errorMessage += LOCAL_CONSTANT.CONFIRM_MESSAGE.HAS_INTERSECTION.replace('__ROUTE__', route.toString())
                                }
                            })
                        }
                    })
                }
                if (errorMessage !== '') {
                    const message = errorMessage.slice(0, errorMessage.length - 1).split("\n").map((line, key) => <span key={key}>{line}<br /></span>)
                    onEventHandle('errorMessage', { messageText: message })
                }

                return errorMessage
            case 'drawImpactLabelMarker':
                impactMarkerList?.map((row: any) => {
                    row.setMap(null)
                })
                if (selectStore) {
                    if (
                        selectStore.counter !== undefined &&
                        selectStore.counter[params.selectTab] !== undefined &&
                        selectStore.counter[params.selectTab].impactCounter !== undefined
                    ) {
                        const tmpList: google.maps.Marker[] = []
                        let count = 0
                        Object.keys(selectStore.counter[params.selectTab].impactCounter).map(uuid => {
                            let impactText = LOCAL_CONSTANT.LABEL.FRONT_FOR_EXISTING_STORES
                            if (count !== 0) {
                                impactText = LOCAL_CONSTANT.LABEL.INTERSECTION + count
                            }
                            count = count + 1
                            if (params.selectTab !== '' && impactText !== '' && impactText !== undefined) {
                                const impactMarker: google.maps.Marker = setImpactLabelMarker(
                                    selectStore.counter[params.selectTab].impactCounter[uuid].lat,
                                    selectStore.counter[params.selectTab].impactCounter[uuid].lng,
                                    impactText.toString()
                                )
                                impactMarker.setMap(map!)
                                tmpList.push(impactMarker)
                            }
                        })
                        setImpactMarkerList(tmpList)
                    }
                }
                break;
            case 'removeDisabled':
                return removeDisabled
            case 'errorMessage':
                if (utilityCtx.showSnackbar) {
                    utilityCtx.showSnackbar("warning", params.messageText);
                }
                break;
            case 'closeHandle':
                handleClose()
                break;
            case 'changeRouteTab':
                dispatch(setObjEditParams({
                    ...objImpactEditParams,
                    selectTab: params.tabValue
                }))
                const list = reloadImpactCounter(params.tabValue)
                setClickableImpact(impactCounterSummary, false)
                tab.map((key: string) => {
                    if (selectStore?.counter[key] !== undefined) {
                        selectStore?.counter[key].path.setMap(null)
                        if (key === params.tabValue) {
                            selectStore?.counter[key].path.setMap(map!)
                            setClickableImpact(list, true)
                        }
                    }
                })
                break;
            case 'removeStore':
                if (selectStore) {
                    selectStore.selectImpact?.setMap(null)
                    Object.keys(selectStore.counter).map(selectTab => {
                        selectStore.counter[selectTab].path.setMap(null)
                    })
                }
                setSelectStore(null)
                setClickableSejStore(exSejStoreList, true)
                setRemoveDisabled(true)

                impactMarkerList?.map((row: any) => {
                    row.setMap(null)
                })
                break;
            case 'startRoute':
                setPolyLineButton('draw')
                setCancelDisabled(true)
                setAddDisabled(true)
                clickDrawingManager()
                tab.map((key: string) => {
                    if (selectStore?.counter[key] !== undefined) {
                        selectStore?.counter[key].path.setMap(null)
                        if (key === objImpactEditParams.selectTab) {
                            selectStore?.counter[key].path.setMap(null)
                        }
                    }
                })
                break;
            case 'overlayPolyLine':
                endDrawingManager(drawingManager!)
                setPolyLineButton('edit')
                break;
            case 'redoPolyLine':
                endDrawingManager(drawingManager!)
                if (drawPolyline) {
                    drawPolyline.setMap(null)
                }
                setDrawPolyline(undefined)
                clickDrawingManager()
                break;
            case 'cancelPolyLine':
                endDrawingManager(drawingManager!)
                setPolyLineButton('')
                setCancelDisabled(false)
                setAddDisabled(false)
                if (drawPolyline) {
                    drawPolyline.setMap(null)
                }
                setDrawPolyline(undefined)
                tab.map((key: string) => {
                    if (selectStore?.counter[key] !== undefined) {
                        selectStore?.counter[key].path.setMap(null)
                        if (key === objImpactEditParams.selectTab) {
                            selectStore?.counter[key].path.setMap(map!)
                        }
                    }
                })
                break;
            case 'resultPolyLine':
                endDrawingManager(drawingManager!)
                setPolyLineButton('')
                setCancelDisabled(false)
                setAddDisabled(false)
                if (drawPolyline && selectStore) {
                    const road_shape = googleMapsLine2GeoJsonLine(drawPolyline)
                    let strokeColor = CAR_COLOR
                    if (Number(objImpactEditParams.selectTab) >= 2) {
                        strokeColor = PEOPLE_COLOR
                    }
                    const path = new google.maps.Polyline({
                        path: road_shape.geometry.coordinates.map((val: any) => {
                            return { lat: val[1], lng: val[0] }
                        }),
                        strokeColor: strokeColor,
                    })
                    path.setMap(map!)
                    selectStore.counter[objImpactEditParams.selectTab] = {
                        path: path,
                        impactCounter: {},
                        road_distance: calcLineLengthGeoJson(googleMapsLine2GeoJsonLine(drawPolyline)),
                    }
                    if (drawPolyline) {
                        drawPolyline.setMap(null)
                    }
                    setDrawPolyline(undefined)
                }
                break;
            case 'handleSelectedChange':
                setImpactDraw(!impactDraw)
                break;
            case 'onRegistImpactCounter':
                const message = onEventHandle('checkImpactCounter', {})
                if (message === '') {
                    const requestParams = registImpactCounter(
                        selectStore,
                        objLongPageParam.planAreaId,
                        objLongPageParam.branch,
                        mode,
                    )
                    let url = "/location/Impact/v1/regist"
                    if (mode === 'edit') {
                        url = "/location/Impact/v1/update-route"
                    }
                    const request = {
                        mode: "regist",
                        endPoint: url,
                        query: {
                            plan_area_id: objLongPageParam.planAreaId,
                            branch: objLongPageParam.branch,
                            store_code: requestParams.store_code,
                            store_name: selectStore?.data.tenpo_name,
                            location_impact: requestParams,
                            location_impact_counter: requestParams.location_impact_counter,
                            location_impact_counter_uuid: requestParams.location_impact_counter_uuid
                        }
                    };
                    userLogging(
                        LOCAL_CONSTANT.FUNCTION_NAME.LOCATION_IMPACT_INSERT,
                        request,
                        user
                    );
                    if (utilityCtx.showSpinner) {
                        utilityCtx.showSpinner();
                    }
                    (async () => {
                        try {
                            await apiRequest(request);
                            if (utilityCtx.hideSpinner) {
                                utilityCtx.hideSpinner();
                            }
                            // 閉じる
                            props.handleSetReadApi(true)
                            handleClose()
                        } catch (e) {
                            console.log(e);
                            if (utilityCtx.hideSpinner) {
                                utilityCtx.hideSpinner();
                            }
                        }
                    })();
                }
                break;
            default:
                break;
        }
    }

    // 道なり距離計測開始
    const clickDrawingManager = () => {
        let strokeColor = CAR_COLOR
        if (objImpactEditParams.selectTab === '2' || objImpactEditParams.selectTab === '3') {
            strokeColor = PEOPLE_COLOR
        }
        const drawResult = startDrawingManager(map!, strokeColor)
        setEvent(drawResult)
        setDrawingManager(drawResult)
    }

    const setEvent = (drawingManager: google.maps.drawing.DrawingManager) => {
        if (drawingManager) {
            google.maps.event.clearListeners(drawingManager, "polylinecomplete")
            google.maps.event.addListener(drawingManager, 'polylinecomplete', function (polyline: any) {
                setDrawPolyline(polyline)
                setPolylineEvent(polyline)
            });
            google.maps.event.clearListeners(drawingManager, "overlaycomplete")
            google.maps.event.addListener(drawingManager, 'overlaycomplete', function (event: any) {
                if (event.type == 'polyline') {
                    endDrawingManager(drawingManager!)
                    setPolyLineButton('edit')
                }
            });
        }
    }
    const setPolylineEvent = (polyline: google.maps.Polyline) => {
        google.maps.event.addListener(polyline.getPath(), 'insert_at', function (path: any) {
        });
        google.maps.event.addListener(polyline.getPath(), 'set_at', function (path: any) {
        });
    }


    return (
        <>
            <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                    <Box sx={{ width: '100%', height: '90vh', display: 'flex' }}>
                        <InfoBase
                            style={{
                                width: '30%',
                                height: '100%',
                            }}
                            onEventHandle={onEventHandle}
                            planArea={planArea}
                            selectStore={selectStore}
                            impactDraw={impactDraw}
                            selectTab={objImpactEditParams.selectTab}
                            cancelDisabled={cancelDisabled}
                            addDisabled={addDisabled}
                        ></InfoBase>
                        <Box
                            style={{
                                width: '70%',
                                height: '100%',
                            }}
                        >
                            <Wrapper apiKey={"AIzaSyBH0T_KDXD97Dg4ZA0lPi_WRZQ-c09HNnY"} libraries={['drawing', 'marker']} render={render} version={'quarterly'}>
                                <MapBase
                                    style={{ width: '100%', height: '100%' }}
                                    center={center}
                                    onIdle={onIdle}
                                    onClickExistingSejStore={onClickExistingSejStore}
                                    setClickEventSejStore={setClickEventSejStore}
                                    onClickImpactCounter={onClickImpactCounter}
                                    setClickEventImpactCounter={setClickEventImpactCounter}
                                    selectStore={selectStore}
                                    selectTab={objImpactEditParams.selectTab}
                                >
                                </MapBase>
                            </Wrapper>
                        </Box>
                        <Box sx={{
                            width: '70%',
                            position: 'absolute',
                            top: '0',
                            left: '30%',
                            display: 'flex',
                            justifyContent: 'center',
                            margin: '20px 0',
                        }}>
                            <Button
                                variant="outlined" color="error" size="small"
                                onClick={(e) => { onEventHandle('cancelPolyLine', {}) }}
                                sx={{ margin: '0 20px', padding: '6px 50px', display: polyLineButton === 'edit' ? 'block' : 'none' }}
                            >{LOCAL_CONSTANT.LABEL.CANCEL}</Button>
                            <Button
                                variant="contained" color="success" size="small"
                                onClick={(e) => { onEventHandle('redoPolyLine', {}) }}
                                sx={{ margin: '0 20px', padding: '6px 50px', display: polyLineButton === 'edit' ? 'block' : 'none' }}
                            >やり直し</Button>
                            <Button
                                variant="contained" color="success" size="small"
                                onClick={(e) => { onEventHandle('resultPolyLine', {}) }}
                                sx={{ margin: '0 20px', padding: '6px 50px', display: polyLineButton === 'edit' ? 'block' : 'none' }}
                            >{LOCAL_CONSTANT.LABEL.CONFIRMED}</Button>
                            <Button
                                variant="contained" color="success" size="small"
                                onClick={(e) => { onEventHandle('overlayPolyLine', {}) }}
                                sx={{ padding: '6px 50px', display: polyLineButton === 'draw' ? 'block' : 'none' }}
                            >仮確定</Button>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </>
    );
}

interface InfoProps extends google.maps.MapOptions {
    style: { [key: string]: string };
    onEventHandle: any;
    planArea: any;
    selectStore: any;
    impactDraw: boolean;
    selectTab: string;
    cancelDisabled: boolean;
    addDisabled: boolean;
    children?: React.ReactElement<google.maps.MarkerOptions>[] | React.ReactElement<google.maps.MarkerOptions>;
}

const InfoBase: React.FC<InfoProps> = ({
    style,
    onEventHandle,
    planArea,
    selectStore,
    impactDraw,
    selectTab,
    cancelDisabled,
    addDisabled,
    children,
    ...options
}) => {
    const [value, setValue] = React.useState("0");
    const [hasSelectStore, setHasSelectStore] = React.useState<boolean>(false)
    const [commentOpen, setCommentOpen] = React.useState<boolean>(false)
    const [commentText, setCommentText] = React.useState<string>("")
    const [userType, setUserType] = React.useState('');
    const user = useUserContext();
    //タスクIDを取得するため
    const objLocationBase = useAppSelector((state) => state.longPage.locationBase);

    const handleChange = (event: any, newValue: any) => {
        setValue(newValue);
        onEventHandle('changeRouteTab', { tabValue: newValue.toString() })
        onEventHandle('drawImpactLabelMarker', { selectTab: Number(newValue) })
    };

    React.useEffect(() => {
        setHasSelectStore(false)
        if (selectStore) {
            setHasSelectStore(true)
            changeTab(selectStore, value)
        }
        if (selectTab !== '') {
            setValue(selectTab)
            if (selectStore) {
                changeTab(selectStore, selectTab)
            }
        }
    }, [selectTab, selectStore])

    //ユーザー取得タイミングによる画面制御
    React.useEffect(() => {
        if (JSON.parse(user.userData || 'null') !== null) {
            setUserType(JSON.parse(user.userData || 'null').user_type);
        }
    }, [user]);

    const handleSelectedChange = (e: SelectChangeEvent<string>, data: any, selectData: any) => {
        data.value = e.target.value
        data.selecter.map((row: any) => {
            if (row.seq.toString() === e.target.value) {
                const copyData = JSON.parse(JSON.stringify(row))
                data.impactData = {
                    'uuid': copyData.uuid,
                    'seq': copyData.seq,
                    'centerlat': copyData.centerlat,
                    'centerlon': copyData.centerlon,
                    'shape': copyData.shape,
                    'create_date': copyData.create_date,
                    'update_date': copyData.update_date,
                    'delete_flag': copyData.delete_flag,
                    'name': copyData.name,
                    'count_date': copyData.count_date,
                    'count_time': copyData.count_time,
                    'counter_type': copyData.counter_type,
                    'intersection_type': copyData.intersection_type,
                    'top_left_count': Number(value) <= 1 ? copyData.top_car_left : copyData.top_people_left,
                    'top_bottom_count': Number(value) <= 1 ? copyData.top_car_bottom : copyData.top_people_bottom,
                    'top_right_count': Number(value) <= 1 ? copyData.top_car_right : copyData.top_people_right,
                    'left_top_count': Number(value) <= 1 ? copyData.left_car_top : copyData.left_people_top,
                    'left_bottom_count': Number(value) <= 1 ? copyData.left_car_bottom : copyData.left_people_bottom,
                    'left_right_count': Number(value) <= 1 ? copyData.left_car_right : copyData.left_people_right,
                    'bottom_top_count': Number(value) <= 1 ? copyData.bottom_car_top : copyData.bottom_people_top,
                    'bottom_left_count': Number(value) <= 1 ? copyData.bottom_car_left : copyData.bottom_people_left,
                    'bottom_right_count': Number(value) <= 1 ? copyData.bottom_car_right : copyData.bottom_people_right,
                    'right_top_count': Number(value) <= 1 ? copyData.right_car_top : copyData.right_people_top,
                    'right_left_count': Number(value) <= 1 ? copyData.right_car_left : copyData.right_people_left,
                    'right_bottom_count': Number(value) <= 1 ? copyData.right_car_bottom : copyData.right_people_bottom,
                    'top_left_type': '0',
                    'top_bottom_type': '0',
                    'top_right_type': '0',
                    'left_top_type': '0',
                    'left_bottom_type': '0',
                    'left_right_type': '0',
                    'bottom_top_type': '0',
                    'bottom_left_type': '0',
                    'bottom_right_type': '0',
                    'right_top_type': '0',
                    'right_left_type': '0',
                    'right_bottom_type': '0',
                    'type': copyData.type,
                    'tobaccolicense': copyData.tobaccolicense,
                    'top_left_type_color': GRAY_COLOR,
                    'top_bottom_type_color': GRAY_COLOR,
                    'top_right_type_color': GRAY_COLOR,
                    'left_top_type_color': GRAY_COLOR,
                    'left_bottom_type_color': GRAY_COLOR,
                    'left_right_type_color': GRAY_COLOR,
                    'bottom_top_type_color': GRAY_COLOR,
                    'bottom_left_type_color': GRAY_COLOR,
                    'bottom_right_type_color': GRAY_COLOR,
                    'right_top_type_color': GRAY_COLOR,
                    'right_left_type_color': GRAY_COLOR,
                    'right_bottom_type_color': GRAY_COLOR,
                    'hasEdit': false,
                    'commentOpen': false,
                }
                data.impactImage = changeImpactImage(copyData.intersection_type)
            }
        })
        onEventHandle('handleSelectedChange', {})
    }

    // 交差点画像切替
    const changeTab = (selectStore: any, tab: any) => {
        if (selectStore.counter.hasOwnProperty(tab)) {
            if (selectStore.counter[tab].hasOwnProperty('impactCounter')) {
                Object.keys(selectStore.counter[tab].impactCounter).map((uuid: any) => {
                    const path = changeImpactImage(selectStore.counter[tab].impactCounter[uuid].impactData.intersection_type)
                    selectStore.counter[tab].impactCounter[uuid].impactImage = path
                })
            }
        }
    }

    const changeImpactImage = (intersection_type: string) => {
        let imagePath = ""
        const intersection = set_intersection(Number(intersection_type))
        if (intersection) {
            imagePath = process.env.PUBLIC_URL + intersection
        }
        return imagePath
    }

    // 交差点画像パス
    const set_intersection = (intersection_type: number) => {
        const intersection_type_list = [
            "/image/impact/十字.png",  // 0
            "/image/impact/二叉路＿横.png",  // 1
            "/image/impact/二叉路＿縦.png",  // 2
            "/image/impact/T左.png",  // 3
            "/image/impact/T右.png",  // 4
            "/image/impact/T下.png",  // 5
            "/image/impact/T上.png",  // 6
            "/image/impact/Y上.png",  // 7
            "/image/impact/Y下.png",  // 8
            "/image/impact/Y左.png",  // 9
            "/image/impact/Y右.png",  // 10
            "/image/impact/中央分離帯＿横.png",  // 11
            "/image/impact/中央分離帯＿縦.png",  // 12
        ]
        return intersection_type_list[intersection_type]
    }


    const impactDataValue = (uuid: string, indexKey: string) => {
        let impactCounterValue = ''
        if (onEventHandle('checkSelectCounter', { selectTab: value }) === true) {
            Object.keys(selectStore.counter[value].impactCounter[uuid].impactData).map(impactKey => {
                if (impactKey === indexKey) {
                    impactCounterValue = selectStore.counter[value].impactCounter[uuid].impactData[impactKey]
                }
            })
        }

        return impactCounterValue
    }

    const changeValue = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, uuid: string, indexKey: string) => {
        if (onEventHandle('checkSelectCounter', { selectTab: value }) === true) {
            if (selectStore.counter[value].impactCounter[uuid].impactData.value !== '') {
                Object.keys(selectStore.counter[value].impactCounter[uuid].impactData).map(impactKey => {
                    if (impactKey === indexKey) {
                        selectStore.counter[value].impactCounter[uuid].impactData[indexKey] = e.target.value
                        onEventHandle('handleSelectedChange', {})
                    }
                })
            }
        }
    }

    const changeType = (uuid: string, indexKey: string) => {
        if (onEventHandle('checkSelectCounter', { selectTab: value }) === true) {
            if (selectStore.counter[value].impactCounter[uuid].impactData.value !== '') {
                Object.keys(selectStore.counter[value].impactCounter[uuid].impactData).map(impactKey => {
                    if (impactKey === indexKey) {
                        const hasCalculation = checkCalculation(selectStore.counter[value].impactCounter[uuid].impactData.intersection_type, indexKey)
                        if (hasCalculation) {
                            if (selectStore.counter[value].impactCounter[uuid].impactData[impactKey] === '0') {
                                selectStore.counter[value].impactCounter[uuid].impactData[impactKey] = '1'
                                selectStore.counter[value].impactCounter[uuid].impactData[impactKey + '_color'] = GREEN_COLOR
                            } else if (selectStore.counter[value].impactCounter[uuid].impactData[impactKey] === '1') {
                                selectStore.counter[value].impactCounter[uuid].impactData[impactKey] = '2'
                                selectStore.counter[value].impactCounter[uuid].impactData[impactKey + '_color'] = YELLOW_COLOR
                            } else if (selectStore.counter[value].impactCounter[uuid].impactData[impactKey] === '2') {
                                selectStore.counter[value].impactCounter[uuid].impactData[impactKey] = '0'
                                selectStore.counter[value].impactCounter[uuid].impactData[impactKey + '_color'] = GRAY_COLOR
                            }

                            onEventHandle('handleSelectedChange', {})
                        }
                    }
                })
            }
        }
    }

    const typeColor = (uuid: string, indexKey: string) => {
        let color = GRAY_COLOR
        if (onEventHandle('checkSelectCounter', { selectTab: value }) === true) {
            Object.keys(selectStore.counter[value].impactCounter[uuid].impactData).map(impactKey => {
                if (impactKey === indexKey) {
                    color = selectStore.counter[value].impactCounter[uuid].impactData[indexKey + '_color']
                    if (selectStore.counter[value].impactCounter[uuid].impactData.intersection_type !== '0') {
                        const response = checkCalculation(selectStore.counter[value].impactCounter[uuid].impactData.intersection_type, indexKey)
                        if (!response) {
                            color = '#404040'
                        }
                    }
                }
            })
        }
        return color
    }

    const checkCalculation = (intersection_type: any, impactKey: string) => {
        let hasCalculation = true
        switch (intersection_type) {
            case '0':
                break
            case '1':
                if (!['left_right_type', 'right_left_type'].includes(impactKey)) {
                    hasCalculation = false
                }
                break
            case '2':
                if (!['top_bottom_type', 'bottom_top_type'].includes(impactKey)) {
                    hasCalculation = false
                }
                break
            case '3':
                if (![
                    'top_left_type',
                    'top_bottom_type',
                    'left_top_type',
                    'left_bottom_type',
                    'bottom_left_type',
                    'bottom_top_type'
                ].includes(impactKey)) {
                    hasCalculation = false
                }
                break
            case '4':
                if (![
                    'top_bottom_type',
                    'top_right_type',
                    'right_top_type',
                    'right_bottom_type',
                    'bottom_right_type',
                    'bottom_top_type'
                ].includes(impactKey)) {
                    hasCalculation = false
                }
                break
            case '5':
                if (![
                    'left_right_type',
                    'left_bottom_type',
                    'right_left_type',
                    'right_bottom_type',
                    'bottom_left_type',
                    'bottom_right_type'
                ].includes(impactKey)) {
                    hasCalculation = false
                }
                break
            case '6':
                if (![
                    'top_left_type',
                    'top_right_type',
                    'left_top_type',
                    'left_right_type',
                    'right_top_type',
                    'right_left_type'
                ].includes(impactKey)) {
                    hasCalculation = false
                }
                break
            case '7':
                if (![
                    'top_left_type',
                    'top_right_type',
                    'left_top_type',
                    'right_top_type',
                    'bottom_left_type',
                    'bottom_right_type'
                ].includes(impactKey)) {
                    hasCalculation = false
                }
                break
            case '8':
                if (![
                    'top_left_type',
                    'top_right_type',
                    'left_bottom_type',
                    'right_bottom_type',
                    'bottom_left_type',
                    'bottom_right_type'
                ].includes(impactKey)) {
                    hasCalculation = false
                }
                break
            case '9':
                if (![
                    'top_left_type',
                    'left_top_type',
                    'left_bottom_type',
                    'right_top_type',
                    'right_bottom_type',
                    'bottom_left_type'
                ].includes(impactKey)) {
                    hasCalculation = false
                }
                break
            case '10':
                if (![
                    'top_right_type',
                    'left_top_type',
                    'left_bottom_type',
                    'right_top_type',
                    'right_bottom_type',
                    'bottom_right_type'
                ].includes(impactKey)) {
                    hasCalculation = false
                }
                break
            case '11':
                if (![
                    'left_top_type',
                    'left_bottom_type',
                    'right_top_type',
                    'right_bottom_type'
                ].includes(impactKey)) {
                    hasCalculation = false
                }
                break
            case '12':
                if (![
                    'top_left_type',
                    'top_right_type',
                    'bottom_left_type',
                    'bottom_right_type'
                ].includes(impactKey)) {
                    hasCalculation = false
                }
                break
        }

        return hasCalculation
    }

    const handleEdit = (uuid: string) => {
        if (onEventHandle('checkSelectCounter', { selectTab: value }) === true) {
            if (selectStore.counter[value].impactCounter[uuid].impactData.value !== '') {
                if (selectStore.counter[value].impactCounter[uuid].impactData.hasEdit === true) {
                    selectStore.counter[value].impactCounter[uuid].impactData.hasEdit = false
                } else {
                    selectStore.counter[value].impactCounter[uuid].impactData.hasEdit = true
                }
                onEventHandle('handleSelectedChange', {})
            }
        }
    }

    const deleteImpact = (uuid: string) => {
        if (onEventHandle('checkSelectCounter', { selectTab: value }) === true) {
            Object.keys(selectStore.counter[value].impactCounter).map(impactUuid => {
                if (impactUuid === uuid) {
                    delete selectStore.counter[value].impactCounter[uuid]
                    onEventHandle('handleSelectedChange', {})
                    onEventHandle('drawImpactLabelMarker', { selectTab: value })
                }
            })
        }
    }

    const hasEdit = (uuid: string) => {
        return selectStore.counter[value].impactCounter[uuid].impactData.hasEdit
    }

    const routeStr = () => {
        let routeStr = LOCAL_CONSTANT.LABEL.DRAW_ROUTE
        if (selectStore && selectStore.counter !== undefined) {
            if (selectStore.counter[value] !== undefined) {
                routeStr = LOCAL_CONSTANT.LABEL.ROUTE_CHANGE
            }
        }
        return routeStr
    }


    const clickCommentOpen = (uuid: string) => {
        const impactText = onEventHandle('impactNumberStr', { selectTab: selectTab, 'key': uuid })
        setCommentText(selectStore?.data.tenpo_name + " " + impactText)
        setCommentOpen(true)
    }

    const handleSaveComment = () => {
        setCommentOpen(false)
    }

    return (
        <>
            <Box sx={style}>
                <Box sx={{ width: '100%', height: '90%', padding: '6px', backgroundColor: 'white' }}>
                    <Box>
                        <Grid sx={{ display: 'flex', width: '100%' }}>
                            <InputLabel sx={{ width: '40%', padding: '10px' }}>{LOCAL_CONSTANT.LABEL.TARGET_PLAN_AREA}</InputLabel>
                            <InputLabel sx={{ width: '60%', backgroundColor: 'rgba(0, 0, 0, 0.2)', padding: '10px' }}>
                                {planArea?.plan_area_name}
                            </InputLabel>
                        </Grid>
                        <Grid sx={{ display: 'flex', width: '100%' }}>
                            <Box sx={{ width: '40%', display: 'flex', justifyContent: "space-between" }}>
                                <InputLabel sx={{ padding: '10px' }}>{LOCAL_CONSTANT.LABEL.TARGET_EXISTING_STORES}</InputLabel>
                                <Box>
                                    <Button
                                        variant="outlined" color="error" size="small"
                                        onClick={(e) => onEventHandle('removeStore', { 'store': selectStore })}
                                        disabled={onEventHandle('removeDisabled', {})}
                                        sx={{ padding: '4px', margin: '6px 2px', minWidth: '30px' }}
                                    >{LOCAL_CONSTANT.LABEL.CANCELLATION}</Button>
                                </Box>
                            </Box>
                            <InputLabel sx={{ width: '60%', backgroundColor: 'rgba(0, 0, 0, 0.2)', padding: '10px' }}>
                                {selectStore?.data.tenpo_name}
                            </InputLabel>
                        </Grid>
                        <Divider sx={{ my: 1 }} />
                    </Box>
                    <Box sx={{ width: '100%', display: hasSelectStore ? 'block' : 'none' }}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'space-around',
                            textAlign: 'center', width: '100%'
                        }}>
                            <InputLabel sx={{ fontSize: '1em' }}>{LOCAL_CONSTANT.LABEL.CAR}</InputLabel>
                            <InputLabel sx={{ fontSize: '1em' }}>{LOCAL_CONSTANT.LABEL.PEOPLE}</InputLabel>
                        </Box>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            sx={{
                                display: 'flex',
                                textAlign: 'center', width: '100%',
                                '& .MuiTabs-flexContainer': {
                                    justifyContent: 'space-around',
                                }
                            }}
                        >
                            <Tab sx={tabStyle} value="0" label={LOCAL_CONSTANT.LABEL.ROUTE + "1"} />
                            <Tab sx={tabStyle} value="1" label={LOCAL_CONSTANT.LABEL.ROUTE + "2"} />
                            <Tab sx={tabStyle} value="2" label={LOCAL_CONSTANT.LABEL.ROUTE + "1"} />
                            <Tab sx={tabStyle} value="3" label={LOCAL_CONSTANT.LABEL.ROUTE + "2"} />
                        </Tabs>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Button
                                variant="contained" color="success" size="small"
                                onClick={(e) => onEventHandle('startRoute', { 'store': selectStore })}
                            >{routeStr()}</Button>
                        </Box>
                    </Box>
                    <Box sx={{ width: '100%', minHeight: '70%', height: '70%', maxHeight: '70%', display: hasSelectStore ? 'block' : 'none', paddingBottom: '60px' }}>
                        <Box sx={{ width: '100%', minHeight: '100%', height: '100%', maxHeight: '100%', overflowY: 'scroll' }}>
                            {
                                onEventHandle('checkSelectCounter', { selectTab: value }) === true &&
                                Object.keys(selectStore.counter[value].impactCounter).map(key => (
                                    <Accordion key={key}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="ChangeHistory-content"
                                            id="ChangeHistory-header"
                                            sx={{
                                                '& .MuiAccordionSummary-expandIconWrapper': {
                                                    position: 'absolute',
                                                    left: '10px',
                                                }
                                            }}
                                        >
                                            <Typography sx={{ paddingLeft: '20px' }}>{onEventHandle('impactNumberStr', { selectTab: value, key: key })}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Box key={key} sx={{ width: '100%', border: '1px solid black' }}>
                                                <Box sx={{ display: 'flex' }}>
                                                    <FormControl size="small">
                                                        <Select

                                                            onChange={(e) => handleSelectedChange(e, selectStore.counter[value].impactCounter[key], selectStore)}
                                                            value={selectStore.counter[value].impactCounter[key].value}
                                                        >
                                                            {
                                                                Object.keys(selectStore.counter[value].impactCounter[key].selecter).map(selectVal => (
                                                                    <MenuItem
                                                                        key={selectVal}
                                                                        value={selectStore.counter[value].impactCounter[key].selecter[selectVal].seq.toString()}>
                                                                        {onEventHandle('showSelectTime', { data: selectStore.counter[value].impactCounter[key].selecter[selectVal] })}
                                                                    </MenuItem>
                                                                ))
                                                            }
                                                        </Select>
                                                    </FormControl>
                                                </Box>
                                                <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                                                    <IconButton
                                                        sx={{ margin: '10px 0' }}
                                                        color="primary"
                                                        onClick={() => clickCommentOpen(key)}
                                                        disabled={setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.IMPACT, 'deficiency_comment_button')}
                                                    >
                                                        <ChatBubbleIcon />
                                                    </IconButton>
                                                    <IconButton
                                                        sx={{ margin: '10px 0' }}
                                                        onClick={() => handleEdit(key)}
                                                        disabled={setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.IMPACT, 'edit_button')}
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                    <IconButton
                                                        sx={{ margin: '10px 0' }}
                                                        onClick={() => deleteImpact(key)}
                                                        disabled={setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.IMPACT, 'delete_button')}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Box>
                                                <Box sx={{ width: '100%', display: 'flex' }}>
                                                    <Box sx={{ width: '98%' }}>
                                                        <Box>
                                                            <Box sx={{ display: 'flex', textAlign: 'center' }}>
                                                                <Box sx={{ width: '80px', minWidth: '80px', maxWidth: '80px', height: '20px' }}></Box>
                                                                <Box sx={{ width: '40px', height: '20px', margin: '10px 0' }}>
                                                                    <InputLabel sx={{ width: '20px', height: '20px', border: '1px solid black', borderRadius: '50px', margin: '0 10px', fontSize: '0.8em' }}>1</InputLabel>
                                                                </Box>
                                                                <Box sx={{ width: '40px', height: '20px', margin: '10px 0' }}>
                                                                    <InputLabel sx={{ width: '20px', height: '20px', border: '1px solid black', borderRadius: '50px', margin: '0 10px', fontSize: '0.8em' }}>2</InputLabel>
                                                                </Box>
                                                                <Box sx={{ width: '40px', height: '20px', margin: '10px 0' }}>
                                                                    <InputLabel sx={{ width: '20px', height: '20px', border: '1px solid black', borderRadius: '50px', margin: '0 10px', fontSize: '0.8em' }}>3</InputLabel>
                                                                </Box>
                                                                <Box sx={{ width: '80px', minWidth: '80px', maxWidth: '80px', height: '20px' }}>
                                                                    <img src={'/image/impact/上赤矢印.png'} style={{ width: '70%', maxWidth: '70%' }} />
                                                                </Box>
                                                            </Box>
                                                            <Box sx={{ display: 'flex' }}>
                                                                <Box sx={{ width: '80px', minWidth: '80px', maxWidth: '80px', height: '40px' }}></Box>
                                                                {
                                                                    hasEdit(key) === true &&
                                                                    <TextField sx={{ width: '40px', height: '40px', padding: '2px 0', border: ["0", "1"].includes(value) ? '2px solid blue' : '2px solid green', backgroundColor: typeColor(key, 'top_left_type'), '& input': { textAlign: 'center', padding: '6px 0' } }}
                                                                        onChange={(e) => { changeValue(e, key, 'top_left_count') }}
                                                                        value={impactDataValue(key, 'top_left_count')}></TextField>
                                                                }
                                                                {
                                                                    hasEdit(key) === false &&
                                                                    <InputLabel sx={{ width: '40px', height: '40px', padding: '10px 0', textAlign: 'center', border: ["0", "1"].includes(value) ? '2px solid blue' : '2px solid green', backgroundColor: typeColor(key, 'top_left_type') }}
                                                                        onClick={(e) => { changeType(key, 'top_left_type') }}>{impactDataValue(key, 'top_left_count')}</InputLabel>
                                                                }

                                                                {
                                                                    hasEdit(key) === true &&
                                                                    <TextField sx={{ width: '40px', height: '40px', padding: '2px 0', border: ["0", "1"].includes(value) ? '2px solid blue' : '2px solid green', backgroundColor: typeColor(key, 'top_bottom_type'), '& input': { textAlign: 'center', padding: '6px 0' } }}
                                                                        onChange={(e) => { changeValue(e, key, 'top_bottom_count') }}
                                                                        value={impactDataValue(key, 'top_bottom_count')}></TextField>
                                                                }
                                                                {
                                                                    hasEdit(key) === false &&
                                                                    <InputLabel sx={{ width: '40px', height: '40px', padding: '10px 0', textAlign: 'center', border: ["0", "1"].includes(value) ? '2px solid blue' : '2px solid green', backgroundColor: typeColor(key, 'top_bottom_type') }}
                                                                        onClick={(e) => { changeType(key, 'top_bottom_type') }}>{impactDataValue(key, 'top_bottom_count')}</InputLabel>
                                                                }

                                                                {
                                                                    hasEdit(key) === true &&
                                                                    <TextField sx={{ width: '40px', height: '40px', padding: '2px 0', border: ["0", "1"].includes(value) ? '2px solid blue' : '2px solid green', backgroundColor: typeColor(key, 'top_right_type'), '& input': { textAlign: 'center', padding: '6px 0' } }}
                                                                        onChange={(e) => { changeValue(e, key, 'top_right_count') }}
                                                                        value={impactDataValue(key, 'top_right_count')}></TextField>
                                                                }
                                                                {
                                                                    hasEdit(key) === false &&
                                                                    <InputLabel sx={{ width: '40px', height: '40px', padding: '10px 0', textAlign: 'center', border: ["0", "1"].includes(value) ? '2px solid blue' : '2px solid green', backgroundColor: typeColor(key, 'top_right_type') }}
                                                                        onClick={(e) => { changeType(key, 'top_right_type') }}>{impactDataValue(key, 'top_right_count')}</InputLabel>
                                                                }
                                                            </Box>
                                                        </Box>
                                                        <Box sx={{ display: 'flex' }}>
                                                            <Box sx={{ width: '40px', height: '40px', padding: '10px 0 0 10px' }}>
                                                                <InputLabel sx={{ width: '20px', height: '20px', border: '1px solid black', borderRadius: '50px', margin: '0 10px', fontSize: '0.8em' }}>12</InputLabel>
                                                            </Box>
                                                            <Box sx={{ width: '40px', height: '40px' }}>
                                                                {
                                                                    hasEdit(key) === true &&
                                                                    <TextField sx={{ width: '40px', height: '40px', padding: '2px 0', border: ["0", "1"].includes(value) ? '2px solid blue' : '2px solid green', backgroundColor: typeColor(key, 'left_top_type'), '& input': { textAlign: 'center', padding: '6px 0' } }}
                                                                        onChange={(e) => { changeValue(e, key, 'left_top_count') }}
                                                                        value={impactDataValue(key, 'left_top_count')}></TextField>
                                                                }
                                                                {
                                                                    hasEdit(key) === false &&
                                                                    <InputLabel sx={{ width: '40px', height: '40px', padding: '10px 0', textAlign: 'center', border: ["0", "1"].includes(value) ? '2px solid blue' : '2px solid green', backgroundColor: typeColor(key, 'left_top_type') }}
                                                                        onClick={(e) => { changeType(key, 'left_top_type') }}>{impactDataValue(key, 'left_top_count')}</InputLabel>
                                                                }
                                                            </Box>
                                                            <Box sx={{ width: '120px', minWidth: '120px', maxWidth: '120px', height: '40px' }}>
                                                                <img src={selectStore.counter[value].impactCounter[key].impactImage} style={{ width: '100%', maxWidth: '100%' }} />
                                                            </Box>
                                                            <Box sx={{ width: '40px', height: '40px' }}>
                                                                {
                                                                    hasEdit(key) === true &&
                                                                    <TextField sx={{ width: '40px', height: '40px', padding: '2px 0', border: ["0", "1"].includes(value) ? '2px solid blue' : '2px solid green', backgroundColor: typeColor(key, 'right_top_type'), '& input': { textAlign: 'center', padding: '6px 0' } }}
                                                                        onChange={(e) => { changeValue(e, key, 'right_top_count') }}
                                                                        value={impactDataValue(key, 'right_top_count')}></TextField>
                                                                }
                                                                {
                                                                    hasEdit(key) === false &&
                                                                    <InputLabel sx={{ width: '40px', height: '40px', padding: '10px 0', textAlign: 'center', border: ["0", "1"].includes(value) ? '2px solid blue' : '2px solid green', backgroundColor: typeColor(key, 'right_top_type') }}
                                                                        onClick={(e) => { changeType(key, 'right_top_type') }}>{impactDataValue(key, 'right_top_count')}</InputLabel>
                                                                }
                                                            </Box>
                                                            <Box sx={{ width: '40px', height: '40px', padding: '10px 0' }}>
                                                                <InputLabel sx={{ width: '20px', height: '20px', border: '1px solid black', borderRadius: '50px', margin: '0 10px', textAlign: 'center', fontSize: '0.8em' }}>4</InputLabel>
                                                            </Box>
                                                        </Box>
                                                        <Box sx={{ display: 'flex' }}>
                                                            <Box sx={{ width: '40px', height: '40px', padding: '10px 0 0 10px' }}>
                                                                <InputLabel sx={{ width: '20px', height: '20px', border: '1px solid black', borderRadius: '50px', margin: '0 10px', fontSize: '0.8em' }}>11</InputLabel>
                                                            </Box>
                                                            <Box sx={{ width: '40px', height: '40px' }}>
                                                                {
                                                                    hasEdit(key) === true &&
                                                                    <TextField sx={{ width: '40px', height: '40px', padding: '2px 0', border: ["0", "1"].includes(value) ? '2px solid blue' : '2px solid green', backgroundColor: typeColor(key, 'left_right_type'), '& input': { textAlign: 'center', padding: '6px 0' } }}
                                                                        onChange={(e) => { changeValue(e, key, 'left_right_count') }}
                                                                        value={impactDataValue(key, 'left_right_count')}></TextField>
                                                                }
                                                                {
                                                                    hasEdit(key) === false &&
                                                                    <InputLabel sx={{ width: '40px', height: '40px', padding: '10px 0', textAlign: 'center', border: ["0", "1"].includes(value) ? '2px solid blue' : '2px solid green', backgroundColor: typeColor(key, 'left_right_type') }}
                                                                        onClick={(e) => { changeType(key, 'left_right_type') }}>{impactDataValue(key, 'left_right_count')}</InputLabel>
                                                                }
                                                            </Box>
                                                            <Box sx={{ width: '120px', minWidth: '120px', maxWidth: '120px', height: '40px' }}>
                                                            </Box>
                                                            <Box sx={{ width: '40px', height: '40px' }}>
                                                                {
                                                                    hasEdit(key) === true &&
                                                                    <TextField sx={{ width: '40px', height: '40px', padding: '2px 0', border: ["0", "1"].includes(value) ? '2px solid blue' : '2px solid green', backgroundColor: typeColor(key, 'right_left_type'), '& input': { textAlign: 'center', padding: '6px 0' } }}
                                                                        onChange={(e) => { changeValue(e, key, 'right_left_count') }}
                                                                        value={impactDataValue(key, 'right_left_count')}></TextField>
                                                                }
                                                                {
                                                                    hasEdit(key) === false &&
                                                                    <InputLabel sx={{ width: '40px', height: '40px', padding: '10px 0', textAlign: 'center', border: ["0", "1"].includes(value) ? '2px solid blue' : '2px solid green', backgroundColor: typeColor(key, 'right_left_type') }}
                                                                        onClick={(e) => { changeType(key, 'right_left_type') }}>{impactDataValue(key, 'right_left_count')}</InputLabel>
                                                                }
                                                            </Box>
                                                            <Box sx={{ width: '40px', height: '40px', padding: '10px 0' }}>
                                                                <InputLabel sx={{ width: '20px', height: '20px', border: '1px solid black', borderRadius: '50px', margin: '0 10px', textAlign: 'center', fontSize: '0.8em' }}>5</InputLabel>
                                                            </Box>
                                                        </Box>
                                                        <Box sx={{ display: 'flex' }}>
                                                            <Box sx={{ width: '40px', height: '40px', padding: '10px 0 0 10px' }}>
                                                                <InputLabel sx={{ width: '20px', height: '20px', border: '1px solid black', borderRadius: '50px', margin: '0 10px', fontSize: '0.8em' }}>10</InputLabel>
                                                            </Box>
                                                            <Box sx={{ width: '40px', height: '40px' }}>
                                                                {
                                                                    hasEdit(key) === true &&
                                                                    <TextField sx={{ width: '40px', height: '40px', padding: '2px 0', border: ["0", "1"].includes(value) ? '2px solid blue' : '2px solid green', backgroundColor: typeColor(key, 'left_bottom_type'), '& input': { textAlign: 'center', padding: '6px 0' } }}
                                                                        onChange={(e) => { changeValue(e, key, 'left_bottom_count') }}
                                                                        value={impactDataValue(key, 'left_bottom_count')}></TextField>
                                                                }
                                                                {
                                                                    hasEdit(key) === false &&
                                                                    <InputLabel sx={{ width: '40px', height: '40px', padding: '10px 0', textAlign: 'center', border: ["0", "1"].includes(value) ? '2px solid blue' : '2px solid green', backgroundColor: typeColor(key, 'left_bottom_type') }}
                                                                        onClick={(e) => { changeType(key, 'left_bottom_type') }}>{impactDataValue(key, 'left_bottom_count')}</InputLabel>
                                                                }
                                                            </Box>
                                                            <Box sx={{ width: '120px', minWidth: '120px', maxWidth: '120px', height: '40px' }}>
                                                            </Box>
                                                            <Box sx={{ width: '40px', height: '40px' }}>
                                                                {
                                                                    hasEdit(key) === true &&
                                                                    <TextField sx={{ width: '40px', height: '40px', padding: '2px 0', border: ["0", "1"].includes(value) ? '2px solid blue' : '2px solid green', backgroundColor: typeColor(key, 'right_bottom_type'), '& input': { textAlign: 'center', padding: '6px 0' } }}
                                                                        onChange={(e) => { changeValue(e, key, 'right_bottom_count') }}
                                                                        value={impactDataValue(key, 'right_bottom_count')}></TextField>
                                                                }
                                                                {
                                                                    hasEdit(key) === false &&
                                                                    <InputLabel sx={{ width: '40px', height: '40px', padding: '10px 0', textAlign: 'center', border: ["0", "1"].includes(value) ? '2px solid blue' : '2px solid green', backgroundColor: typeColor(key, 'right_bottom_type') }}
                                                                        onClick={(e) => { changeType(key, 'right_bottom_type') }}>{impactDataValue(key, 'right_bottom_count')}</InputLabel>
                                                                }
                                                            </Box>
                                                            <Box sx={{ width: '40px', height: '40px', padding: '10px 0' }}>
                                                                <InputLabel sx={{ width: '20px', height: '20px', border: '1px solid black', borderRadius: '50px', margin: '0 10px', textAlign: 'center', fontSize: '0.8em' }}>6</InputLabel>
                                                            </Box>
                                                        </Box>
                                                        <Box>
                                                            <Box sx={{ display: 'flex' }}>
                                                                <Box sx={{ width: '80px', minWidth: '80px', maxWidth: '80px', height: '40px' }}></Box>
                                                                {
                                                                    hasEdit(key) === true &&
                                                                    <TextField sx={{ width: '40px', height: '40px', padding: '2px 0', border: ["0", "1"].includes(value) ? '2px solid blue' : '2px solid green', backgroundColor: typeColor(key, 'bottom_left_type'), '& input': { textAlign: 'center', padding: '6px 0' } }}
                                                                        onChange={(e) => { changeValue(e, key, 'bottom_left_count') }}
                                                                        value={impactDataValue(key, 'bottom_left_count')}></TextField>
                                                                }
                                                                {
                                                                    hasEdit(key) === false &&
                                                                    <InputLabel sx={{ width: '40px', height: '40px', padding: '10px 0', textAlign: 'center', border: ["0", "1"].includes(value) ? '2px solid blue' : '2px solid green', backgroundColor: typeColor(key, 'bottom_left_type') }}
                                                                        onClick={(e) => { changeType(key, 'bottom_left_type') }}>{impactDataValue(key, 'bottom_left_count')}</InputLabel>
                                                                }
                                                                {
                                                                    hasEdit(key) === true &&
                                                                    <TextField sx={{ width: '40px', height: '40px', padding: '2px 0', border: ["0", "1"].includes(value) ? '2px solid blue' : '2px solid green', backgroundColor: typeColor(key, 'bottom_top_type'), '& input': { textAlign: 'center', padding: '6px 0' } }}
                                                                        onChange={(e) => { changeValue(e, key, 'bottom_top_count') }}
                                                                        value={impactDataValue(key, 'bottom_top_count')}></TextField>
                                                                }
                                                                {
                                                                    hasEdit(key) === false &&
                                                                    <InputLabel sx={{ width: '40px', height: '40px', padding: '10px 0', textAlign: 'center', border: ["0", "1"].includes(value) ? '2px solid blue' : '2px solid green', backgroundColor: typeColor(key, 'bottom_top_type') }}
                                                                        onClick={(e) => { changeType(key, 'bottom_top_type') }}>{impactDataValue(key, 'bottom_top_count')}</InputLabel>
                                                                }
                                                                {
                                                                    hasEdit(key) === true &&
                                                                    <TextField sx={{ width: '40px', height: '40px', padding: '2px 0', border: ["0", "1"].includes(value) ? '2px solid blue' : '2px solid green', backgroundColor: typeColor(key, 'bottom_right_type'), '& input': { textAlign: 'center', padding: '6px 0' } }}
                                                                        onChange={(e) => { changeValue(e, key, 'bottom_right_count') }}
                                                                        value={impactDataValue(key, 'bottom_right_count')}></TextField>
                                                                }
                                                                {
                                                                    hasEdit(key) === false &&
                                                                    <InputLabel sx={{ width: '40px', height: '40px', padding: '10px 0', textAlign: 'center', border: ["0", "1"].includes(value) ? '2px solid blue' : '2px solid green', backgroundColor: typeColor(key, 'bottom_right_type') }}
                                                                        onClick={(e) => { changeType(key, 'bottom_right_type') }}>{impactDataValue(key, 'bottom_right_count')}</InputLabel>
                                                                }
                                                            </Box>
                                                            <Box sx={{ display: 'flex', textAlign: 'center' }}>
                                                                <Box sx={{ width: '80px', minWidth: '80px', maxWidth: '80px', height: '20px' }}></Box>
                                                                <Box sx={{ width: '40px', height: '20px', margin: '10px 0' }}>
                                                                    <InputLabel sx={{ width: '20px', height: '20px', border: '1px solid black', borderRadius: '50px', margin: '0 10px', fontSize: '0.8em' }}>9</InputLabel>
                                                                </Box>
                                                                <Box sx={{ width: '40px', height: '20px', margin: '10px 0' }}>
                                                                    <InputLabel sx={{ width: '20px', height: '20px', border: '1px solid black', borderRadius: '50px', margin: '0 10px', fontSize: '0.8em' }}>8</InputLabel>
                                                                </Box>
                                                                <Box sx={{ width: '40px', height: '20px', margin: '10px 0' }}>
                                                                    <InputLabel sx={{ width: '20px', height: '20px', border: '1px solid black', borderRadius: '50px', margin: '0 10px', fontSize: '0.8em' }}>7</InputLabel>
                                                                </Box>
                                                                <Box sx={{ width: '80px', minWidth: '80px', maxWidth: '80px', height: '20px' }}></Box>
                                                            </Box>
                                                        </Box>
                                                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-around' }}>
                                                            <InputLabel sx={{ padding: '4px 20px', margin: '4px', border: "2px solid black", backgroundColor: GRAY_COLOR }}>{LOCAL_CONSTANT.LABEL.NOT_RELEVANT}</InputLabel>
                                                            <InputLabel sx={{ padding: '4px 20px', margin: '4px', backgroundColor: GREEN_COLOR }}>{LOCAL_CONSTANT.LABEL.COMMON}</InputLabel>
                                                            <InputLabel sx={{ padding: '4px 20px', margin: '4px', backgroundColor: YELLOW_COLOR }}>{LOCAL_CONSTANT.LABEL.UNCOMMON}</InputLabel>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                ))
                            }
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ width: '100%', height: '10%', padding: '6px', backgroundColor: 'white', position: 'relative' }}>
                    <Divider sx={{ my: 1 }} />
                    <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
                        <Button variant="outlined" color="error" size="small" disabled={cancelDisabled} onClick={(e) => { onEventHandle('closeHandle', {}) }}>{LOCAL_CONSTANT.LABEL.CANCEL}</Button>
                        <Button variant="contained" color="success" size="small" disabled={addDisabled} onClick={(e) => { onEventHandle('onRegistImpactCounter', {}) }}>{LOCAL_CONSTANT.LABEL.SAVE}</Button>
                    </Box>
                </Box>
                <Comment props={{
                    open: commentOpen,
                    params: {
                        table_name: "location_impact",
                        column_name: commentText,
                        comment: "",
                    },
                    handleModal: setCommentOpen,
                    handleSaveComment: handleSaveComment
                }}
                />
            </Box>
        </>
    );
};

interface MapProps extends google.maps.MapOptions {
    style: { [key: string]: string };
    children?: React.ReactElement<google.maps.MarkerOptions>[] | React.ReactElement<google.maps.MarkerOptions>;
    onIdle?: (map: google.maps.Map) => void;
    onClickExistingSejStore: any;
    setClickEventSejStore: any;
    onClickImpactCounter: any;
    setClickEventImpactCounter: any;
    selectStore: any;
    selectTab: string;
}

const MapBase: React.FC<MapProps> = ({
    style,
    children,
    onIdle,
    onClickExistingSejStore,
    setClickEventSejStore,
    onClickImpactCounter,
    setClickEventImpactCounter,
    selectStore,
    selectTab,
    ...options
}) => {
    const ref = React.useRef<HTMLDivElement>(null);
    const [map, setMap] = React.useState<google.maps.Map>();

    React.useEffect(() => {
        if (ref.current && !map) {
            setMap(new window.google.maps.Map(ref.current, {
                mapId: 'e42bfbd48d30d1a0',
                disableDefaultUI: false,
                keyboardShortcuts: false,
                disableDoubleClickZoom: false,
                streetViewControl: false,
                zoomControl: true,
                scaleControl: true,
                clickableIcons: false,
                fullscreenControl: false,
                mapTypeControl: false,
                scrollwheel: true,
                center: options.center,
                zoom: 16
            }));
        }
    }, [ref, map]);


    React.useEffect(() => {
        if (map) {
            ["idle"].forEach((eventName) =>
                google.maps.event.clearListeners(map, eventName)
            );

            if (onIdle) {
                map.addListener("idle", () => onIdle(map));
            }

            if (onClickExistingSejStore) {
                setClickEventSejStore()
            }
            if (onClickImpactCounter) {
                setClickEventImpactCounter()
            }
        }
    }, [map, onIdle, onClickExistingSejStore, onClickImpactCounter]);

    return (
        <>
            <div ref={ref} style={style} />
            {React.Children.map(children, (child) => {
                if (React.isValidElement(child)) {
                    // set the map prop on the child component
                    // @ts-ignore
                    return React.cloneElement(child, { map });
                }
            })}
        </>
    );
};


export default AddImpactModal;