import dayjs from "dayjs";

//表紙ページ項目名の型定義
export type CoverTypeList = {
    create_date: string;
    reception_no: string;
    store_no: string;
    survey_name: string;
    address: string;
    address_detail: string;
    contract_due_date: string;
    open_due_date: string;
    prefectures_code: string;
    new_area_no: string;
    branch_no: string;
    existing_store_design: boolean;
    s_and_b_case: boolean;
    conflict_ruins_case: boolean;
    target_contract_not_opened: String;
    survey_creator: string;
    confirmer: string;
};

//表紙ページ項目名の初期値
export const initCoverObj: CoverTypeList = {
    create_date: dayjs().format("YYYY-MM-DD"),
    reception_no: "",
    store_no: "",
    survey_name: "",
    address: "",
    address_detail: "",
    contract_due_date: dayjs().format("YYYY-MM-DD"),
    open_due_date: dayjs().format("YYYY-MM-DD"),
    prefectures_code: "",
    new_area_no: "",
    branch_no: "",
    existing_store_design: false,
    s_and_b_case: false,
    conflict_ruins_case: false,
    target_contract_not_opened: "ある",
    survey_creator: "",
    confirmer: "",
};

//表紙ページのstring型の型定義
export type CoverStringTypeList = {
    contract_due_date: string;
    open_due_date: string;
    target_contract_not_opened: string;
    [coverStringKey: string]: string; //string型でキーを指定するため
};

//表紙ページの不備コメントモーダル内のテキストフィールドの初期値
export const initCoverCommentTextFieldObj: CoverStringTypeList = {
    contract_due_date: "",
    open_due_date: "",
    target_contract_not_opened: "",
};

//表紙ページのboolean型の定義
export type CoverBooleanTypeList = {
    address: boolean;
    contract_due_date: boolean;
    open_due_date: boolean;
    target_contract_not_opened: boolean;
    [coverBooleanKey: string]: boolean; //string型でキーを指定するため
};

//表紙ページの項目値を編集可能にするステータスの初期値 trueが入力不可、falseが入力可
export const initCoverReadOnlyObj: CoverBooleanTypeList = {
    address: true,
    contract_due_date: true,
    open_due_date: true,
    target_contract_not_opened: true,
};

// 表紙ページの鉛筆アイコンの表示非表示のステータスの初期値 trueが表示、falseが非表示
export const initCoverShowEditIconObj: CoverBooleanTypeList = {
    address: true,
    contract_due_date: true,
    open_due_date: true,
    target_contract_not_opened: true,
};

//出店条件ページ項目名の型定義
export type StoreOpeningConditionsTypeList = {
    setting_reason: string;
    candidate_location_ruins: string;
    candidate_location_ruins_long_text: string;
    is_store_name_select_reason: boolean;
    removal_Reason: string;
    open24_hours: string;
    not_open24_hours: string;
    alcohol_license: string;
    no_alcohol_license: string;
    tobacco_licence: string;
    no_tobacco_licence: string;
    area_type: string;
    nearest_already_store_distance: string;
    environment_type: string;
    acquisition_requirement: string;
    tobacco_distance_standard: string;
    distance_standard_alreadystore_count: string;
    previous_job: string;
    conflict_cvs: string;
    cvs_last_nippan: string;
    relocation_distance: string;
    ownership_type: string;
    store_type: string;
    store_frontage: string;
    store_area: string;
    salses_area: string;
    open_case: string;
    nakajima_chilled: string;
    island_chilled: string;
    gondola_units: string;
    gondola_height: string;
    ice_case_large: string;
    ice_case_medium: string;
    ice_case_small: string;
    compact_stocker: string;
    low_temp_reach_in: string;
    walk_in: string;
    conter_width: string;
    existing_store_name: string;
    store_no: string;
    average_nippan: string;
    achievement_acquisition_start: string;
    achievement_acquisition_end: string;
    reception_no: string;
    s_store_open_date: string;
    s_store_close_due_date: string;
    closing_days: string;
    in_sales_construction_days: string;
    car_common_ratio: string;
    person_common_ratio: string;
    s_store_line_distance: string;
    s_store_way_distance: string;
    s_store_car_customer_ratio: string;
    last_fixed_month: string;
    last_one_year_nippan: string;
    year_on_year: string;
    year_month: string;
    change_type: string;
    content: string;
    last_one_year_nippan_0: string;
    last_one_year_nippan_1: string;
    last_one_year_nippan_2: string;
    last_one_year_nippan_3: string;
    last_one_year_nippan_4: string;
    last_one_year_nippan_5: string;
    last_one_year_nippan_6: string;
    last_one_year_nippan_7: string;
    last_one_year_nippan_8: string;
    last_one_year_nippan_9: string;
    last_one_year_nippan_10: string;
    last_one_year_nippan_11: string;
    year_on_year_0: string;
    year_on_year_1: string;
    year_on_year_2: string;
    year_on_year_3: string;
    year_on_year_4: string;
    year_on_year_5: string;
    year_on_year_6: string;
    year_on_year_7: string;
    year_on_year_8: string;
    year_on_year_9: string;
    year_on_year_10: string;
    year_on_year_11: string;
    store_move_type: string;
    store_move_distance: string;
    store_type_multiple_store: boolean;
    last_one_year_nippan_average: string;
    year_on_year_average: string;
    [StoreOpeningConditionsKey: string]: any; //string型でキーを指定するため
};

//出店条件ページ項目名の初期値
export const initStoreOpeningConditionsObj: StoreOpeningConditionsTypeList = {
    setting_reason: "",
    candidate_location_ruins: "",
    candidate_location_ruins_long_text: "",
    is_store_name_select_reason: false,
    removal_Reason: "",
    open24_hours: "",
    not_open24_hours: "",
    alcohol_license: "",
    no_alcohol_license: "",
    tobacco_licence: "",
    no_tobacco_licence: "",
    area_type: "",
    nearest_already_store_distance: "",
    environment_type: "",
    acquisition_requirement: "",
    tobacco_distance_standard: "",
    distance_standard_alreadystore_count: "",
    previous_job: "",
    conflict_cvs: "",
    cvs_last_nippan: "",
    relocation_distance: "",
    ownership_type: "",
    store_type: "",
    store_frontage: "",
    store_area: "",
    salses_area: "",
    open_case: "",
    nakajima_chilled: "",
    island_chilled: "",
    gondola_units: "",
    gondola_height: "",
    ice_case_large: "",
    ice_case_medium: "",
    ice_case_small: "",
    compact_stocker: "",
    low_temp_reach_in: "",
    walk_in: "",
    conter_width: "",
    existing_store_name: "",
    store_no: "",
    average_nippan: "",
    achievement_acquisition_start: "",
    achievement_acquisition_end: "",
    reception_no: "",
    s_store_open_date: "",
    s_store_close_due_date: "",
    s_store_line_distance: "",
    s_store_way_distance: "",
    closing_days: "",
    in_sales_construction_days: "",
    car_common_ratio: "",
    person_common_ratio: "",
    s_store_car_customer_ratio: "",
    last_fixed_month: "",
    last_one_year_nippan: "",
    year_on_year: "",
    year_month: "",
    change_type: "",
    content: "",
    last_one_year_nippan_0: "",
    last_one_year_nippan_1: "",
    last_one_year_nippan_2: "",
    last_one_year_nippan_3: "",
    last_one_year_nippan_4: "",
    last_one_year_nippan_5: "",
    last_one_year_nippan_6: "",
    last_one_year_nippan_7: "",
    last_one_year_nippan_8: "",
    last_one_year_nippan_9: "",
    last_one_year_nippan_10: "",
    last_one_year_nippan_11: "",
    year_on_year_0: "",
    year_on_year_1: "",
    year_on_year_2: "",
    year_on_year_3: "",
    year_on_year_4: "",
    year_on_year_5: "",
    year_on_year_6: "",
    year_on_year_7: "",
    year_on_year_8: "",
    year_on_year_9: "",
    year_on_year_10: "",
    year_on_year_11: "",
    store_move_type: "",
    store_move_distance: "",
    store_type_multiple_store: false,
    last_one_year_nippan_average: "",
    year_on_year_average: "",
};

//出店条件ページのboolean型の定義
export type StoreOpeningConditionsBooleanTypeList = {
    setting_reason: boolean;
    candidate_location_ruins: boolean;
    candidate_location_ruins_long_text: boolean;
    is_store_name_select_reason: boolean;
    removal_Reason: boolean;
    open24_hours: boolean;
    not_open24_hours: boolean;
    alcohol_license: boolean;
    no_alcohol_license: boolean;
    tobacco_licence: boolean;
    no_tobacco_licence: boolean;
    area_type: boolean;
    nearest_already_store_distance: boolean;
    environment_type: boolean;
    acquisition_requirement: boolean;
    tobacco_distance_standard: boolean;
    distance_standard_alreadystore_count: boolean;
    previous_job: boolean;
    conflict_cvs: boolean;
    cvs_last_nippan: boolean;
    relocation_distance: boolean;
    ownership_type: boolean;
    store_type: boolean;
    store_frontage: boolean;
    store_area: boolean;
    salses_area: boolean;
    open_case: boolean;
    nakajima_chilled: boolean;
    island_chilled: boolean;
    gondola_units: boolean;
    gondola_height: boolean;
    ice_case_large: boolean;
    ice_case_medium: boolean;
    ice_case_small: boolean;
    compact_stocker: boolean;
    low_temp_reach_in: boolean;
    walk_in: boolean;
    conter_width: boolean;
    existing_store_name: boolean;
    store_no: boolean;
    average_nippan: boolean;
    achievement_acquisition_start: boolean;
    achievement_acquisition_end: boolean;
    reception_no: boolean;
    s_store_open_date: boolean;
    s_store_close_due_date: boolean;
    closing_days: boolean;
    in_sales_construction_days: boolean;
    car_common_ratio: boolean;
    person_common_ratio: boolean;
    s_store_line_distance: boolean;
    s_store_way_distance: boolean;
    s_store_car_customer_ratio: boolean;
    last_fixed_month: boolean;
    last_one_year_nippan: boolean;
    year_on_year: boolean;
    year_month: boolean;
    change_type: boolean;
    content: boolean;
    store_move_type: boolean;
    store_move_distance: boolean;
    store_type_multiple_store: boolean;
    s_store_daily_sales_and_pre_year_comparison: boolean;
    last_one_year_nippan_average: boolean;
    year_on_year_average: boolean;
    [storeOpeningConditionsBooleanKey: string]: boolean; //string型でキーを指定するため
};

//出店条件ページのアイコン全体の表示非表示のステータスの初期値
export const initStoreOpeningConditionsShowIconObj: StoreOpeningConditionsBooleanTypeList =
    {
        setting_reason: false,
        candidate_location_ruins: false,
        candidate_location_ruins_long_text: false,
        is_store_name_select_reason: false,
        removal_Reason: false,
        open24_hours: false,
        not_open24_hours: false,
        alcohol_license: false,
        no_alcohol_license: false,
        tobacco_licence: false,
        no_tobacco_licence: false,
        area_type: false,
        nearest_already_store_distance: false,
        environment_type: false,
        acquisition_requirement: false,
        tobacco_distance_standard: false,
        distance_standard_alreadystore_count: false,
        previous_job: false,
        conflict_cvs: false,
        cvs_last_nippan: false,
        relocation_distance: false,
        ownership_type: false,
        store_type: false,
        store_frontage: false,
        store_area: false,
        salses_area: false,
        open_case: false,
        nakajima_chilled: false,
        island_chilled: false,
        gondola_units: false,
        gondola_height: false,
        ice_case_large: false,
        ice_case_medium: false,
        ice_case_small: false,
        compact_stocker: false,
        low_temp_reach_in: false,
        walk_in: false,
        conter_width: false,
        existing_store_name: false,
        store_no: false,
        average_nippan: false,
        achievement_acquisition_start: false,
        achievement_acquisition_end: false,
        reception_no: false,
        s_store_open_date: false,
        s_store_close_due_date: false,
        s_store_line_distance: false,
        s_store_way_distance: false,
        closing_days: false,
        in_sales_construction_days: false,
        car_common_ratio: false,
        person_common_ratio: false,
        s_store_car_customer_ratio: false,
        last_fixed_month: false,
        last_one_year_nippan: false,
        year_on_year: false,
        year_month: false,
        change_type: false,
        content: false,
        store_move_type: false,
        store_move_distance: false,
        store_type_multiple_store: false,
        s_store_daily_sales_and_pre_year_comparison: false,
        last_one_year_nippan_average: false,
        year_on_year_average: false,
    };

//出店条件ページの鉛筆アイコンの表示非表示のステータスの初期値 trueが表示、falseが非表示
export const initStoreOpeningConditionsShowEditIconObj: StoreOpeningConditionsBooleanTypeList =
    {
        setting_reason: true,
        candidate_location_ruins: true,
        candidate_location_ruins_long_text: true,
        is_store_name_select_reason: true,
        removal_Reason: true,
        open24_hours: true,
        not_open24_hours: true,
        alcohol_license: true,
        no_alcohol_license: true,
        tobacco_licence: true,
        no_tobacco_licence: true,
        area_type: true,
        nearest_already_store_distance: true,
        environment_type: true,
        acquisition_requirement: true,
        tobacco_distance_standard: true,
        distance_standard_alreadystore_count: true,
        previous_job: true,
        conflict_cvs: true,
        cvs_last_nippan: true,
        relocation_distance: true,
        ownership_type: true,
        store_type: true,
        store_frontage: true,
        store_area: true,
        salses_area: true,
        open_case: true,
        nakajima_chilled: true,
        island_chilled: true,
        gondola_units: true,
        gondola_height: true,
        ice_case_large: true,
        ice_case_medium: true,
        ice_case_small: true,
        compact_stocker: true,
        low_temp_reach_in: true,
        walk_in: true,
        conter_width: true,
        existing_store_name: true,
        store_no: true,
        average_nippan: true,
        achievement_acquisition_start: true,
        achievement_acquisition_end: true,
        reception_no: true,
        s_store_open_date: true,
        s_store_close_due_date: true,
        s_store_line_distance: true,
        s_store_way_distance: true,
        closing_days: true,
        in_sales_construction_days: true,
        car_common_ratio: true,
        person_common_ratio: true,
        s_store_car_customer_ratio: true,
        last_fixed_month: true,
        last_one_year_nippan: true,
        year_on_year: true,
        year_month: true,
        change_type: true,
        content: true,
        store_move_type: true,
        store_move_distance: true,
        store_type_multiple_store: true,
        s_store_daily_sales_and_pre_year_comparison: true,
        last_one_year_nippan_average: true,
        year_on_year_average: true,
    };

//出店条件ページのstring型の型定義
export type StoreOpeningConditionsStringTypeList = {
    setting_reason: string;
    candidate_location_ruins: string;
    candidate_location_ruins_long_text: string;
    is_store_name_select_reason: string;
    removal_Reason: string;
    open24_hours: string;
    not_open24_hours: string;
    alcohol_license: string;
    no_alcohol_license: string;
    tobacco_licence: string;
    no_tobacco_licence: string;
    area_type: string;
    nearest_already_store_distance: string;
    environment_type: string;
    acquisition_requirement: string;
    tobacco_distance_standard: string;
    distance_standard_alreadystore_count: string;
    previous_job: string;
    conflict_cvs: string;
    cvs_last_nippan: string;
    relocation_distance: string;
    ownership_type: string;
    store_type: string;
    store_frontage: string;
    store_area: string;
    salses_area: string;
    open_case: string;
    nakajima_chilled: string;
    island_chilled: string;
    gondola_units: string;
    gondola_height: string;
    ice_case_large: string;
    ice_case_medium: string;
    ice_case_small: string;
    compact_stocker: string;
    low_temp_reach_in: string;
    walk_in: string;
    conter_width: string;
    existing_store_name: string;
    store_no: string;
    average_nippan: string;
    achievement_acquisition_start: string;
    achievement_acquisition_end: string;
    reception_no: string;
    s_store_open_date: string;
    s_store_close_due_date: string;
    closing_days: string;
    in_sales_construction_days: string;
    car_common_ratio: string;
    person_common_ratio: string;
    s_store_line_distance: string;
    s_store_way_distance: string;
    s_store_car_customer_ratio: string;
    last_fixed_month: string;
    last_one_year_nippan: string;
    year_on_year: string;
    year_month: string;
    change_type: string;
    content: string;
    store_move_type: string;
    store_move_distance: string;
    store_type_multiple_store: string;
    s_store_daily_sales_and_pre_year_comparison: string;
    last_one_year_nippan_average: string;
    year_on_year_average: string;
    [storeOpeningConditionsStringKey: string]: string; //string型でキーを指定するため
};

//出店条件ページのモーダル内のテキストフィールドの初期値
export const initStoreOpeningConditionsCommentTextFieldObj: StoreOpeningConditionsStringTypeList =
    {
        setting_reason: "",
        candidate_location_ruins: "",
        candidate_location_ruins_long_text: "",
        is_store_name_select_reason: "",
        removal_Reason: "",
        open24_hours: "",
        not_open24_hours: "",
        alcohol_license: "",
        no_alcohol_license: "",
        tobacco_licence: "",
        no_tobacco_licence: "",
        area_type: "",
        nearest_already_store_distance: "",
        environment_type: "",
        acquisition_requirement: "",
        tobacco_distance_standard: "",
        distance_standard_alreadystore_count: "",
        previous_job: "",
        conflict_cvs: "",
        cvs_last_nippan: "",
        relocation_distance: "",
        ownership_type: "",
        store_type: "",
        store_frontage: "",
        store_area: "",
        salses_area: "",
        open_case: "",
        nakajima_chilled: "",
        island_chilled: "",
        gondola_units: "",
        gondola_height: "",
        ice_case_large: "",
        ice_case_medium: "",
        ice_case_small: "",
        compact_stocker: "",
        low_temp_reach_in: "",
        walk_in: "",
        conter_width: "",
        existing_store_name: "",
        store_no: "",
        average_nippan: "",
        achievement_acquisition_start: "",
        achievement_acquisition_end: "",
        reception_no: "",
        s_store_open_date: "",
        s_store_close_due_date: "",
        closing_days: "",
        in_sales_construction_days: "",
        car_common_ratio: "",
        person_common_ratio: "",
        s_store_line_distance: "",
        s_store_way_distance: "",
        s_store_car_customer_ratio: "",
        last_fixed_month: "",
        last_one_year_nippan: "",
        year_on_year: "",
        year_month: "",
        change_type: "",
        content: "",
        store_move_type: "",
        store_move_distance: "",
        store_type_multiple_store: "",
        s_store_daily_sales_and_pre_year_comparison: "",
        last_one_year_nippan_average: "",
        year_on_year_average: "",
    };

//出店条件ページの項目値を編集可能にするステータスの初期値 trueが入力不可、falseが入力可
export const initStoreOpeningConditionsReadOnlyObj: StoreOpeningConditionsBooleanTypeList =
    {
        setting_reason: true,
        candidate_location_ruins: true,
        candidate_location_ruins_long_text: true,
        is_store_name_select_reason: true,
        removal_Reason: true,
        open24_hours: true,
        not_open24_hours: true,
        alcohol_license: true,
        no_alcohol_license: true,
        tobacco_licence: true,
        no_tobacco_licence: true,
        area_type: true,
        nearest_already_store_distance: true,
        environment_type: true,
        acquisition_requirement: true,
        tobacco_distance_standard: true,
        distance_standard_alreadystore_count: true,
        previous_job: true,
        conflict_cvs: true,
        cvs_last_nippan: true,
        relocation_distance: true,
        ownership_type: true,
        store_type: true,
        store_frontage: true,
        store_area: true,
        salses_area: true,
        open_case: true,
        nakajima_chilled: true,
        island_chilled: true,
        gondola_units: true,
        gondola_height: true,
        ice_case_large: true,
        ice_case_medium: true,
        ice_case_small: true,
        compact_stocker: true,
        low_temp_reach_in: true,
        walk_in: true,
        conter_width: true,
        existing_store_name: true,
        store_no: true,
        average_nippan: true,
        achievement_acquisition_start: true,
        achievement_acquisition_end: true,
        reception_no: true,
        s_store_open_date: true,
        s_store_close_due_date: true,
        s_store_line_distance: true,
        s_store_way_distance: true,
        closing_days: true,
        in_sales_construction_days: true,
        car_common_ratio: true,
        person_common_ratio: true,
        s_store_car_customer_ratio: true,
        last_fixed_month: true,
        last_one_year_nippan: true,
        year_on_year: true,
        year_month: true,
        change_type: true,
        content: true,
        store_move_type: true,
        store_move_distance: true,
        store_type_multiple_store: true,
        s_store_daily_sales_and_pre_year_comparison: true,
        last_one_year_nippan_average: true,
        year_on_year_average: true,
    };

//出店条件ページロングテキスト表示非表示型定義
export type StoreOpeningConditionsShowLongText = {
    candidate_location_ruins: boolean;
    removal_Reason: boolean;
};

//出店条件ページロングテキスト表示非表示初期値
export const initStoreOpeningConditionsShowLongTextObj: StoreOpeningConditionsShowLongText =
    {
        candidate_location_ruins: false, //候補地跡地 ロングテキスト
        removal_Reason: false, //店名選定の理由について ロングテキスト
    };

//商圏ページ①周辺の国税調査（H27）データ型定義
export type TradeAreaTaxAuditTypeList = {
    taxAuditAdministrativeName: string;
    taxAuditHouseholdCount: any;
    taxAuditPopulation: any;
    taxAuditAveragePopulation: any;
    taxAuditSingleHouseholdRatio: any;
    taxAuditAgeGroup20Ratio: any;
    taxAuditAgeGroup65MoreRatio: any;
    taxAuditOffice: any;
    taxAuditEmployees: any;
    taxAuditAverageEmployees: any;
};

//商圏ページ①周辺の国税調査（H27）データ初期値
export const initTradeAreaTaxAuditObj: TradeAreaTaxAuditTypeList = {
    taxAuditAdministrativeName: "",
    taxAuditHouseholdCount: 0,
    taxAuditPopulation: 0,
    taxAuditAveragePopulation: 0,
    taxAuditSingleHouseholdRatio: 0,
    taxAuditAgeGroup20Ratio: 0,
    taxAuditAgeGroup65MoreRatio: 0,
    taxAuditOffice: 0,
    taxAuditEmployees: 0,
    taxAuditAverageEmployees: 0,
};

//商圏ページ②車5分商圏データ型定義
export type TradeAreaCar5MinMpTypeList = {
    car5MinTradeAreaHouseholdCount: any;
    car5MinSingleHouseholdCount: any;
    car5MinSingleHouseholdRatio: any;
    car5MinPopulation: any;
    car5MinAgeGroup20Total: any;
    car5MinAgeGroup20Ratio: any;
    car5MinAgeGroup30Total: any;
    car5MinAgeGroup30Ratio: any;
    car5MinAgeGroup50MoreTotal: any;
    car5MinAgeGroup50MoreRatio: any;
    car5MinAgeGroup65MoreTotal: any;
    car5MinAgeGroup65MoreRatio: any;
    car5MinOfficeCount: any;
    car5MinEmployeesCount: any;
    car5MinAllocationOffice: any;
    car5MinAllocationEmployees: any;
    car5MinDeductionOffice: any;
    car5MinDeductionEmployees: any;
    car5MinIncomeUpTo300: any;
    car5MinIncomeUpTo500: any;
    car5MinIncomeUpTo700: any;
    car5MinIncomeUpTo1000: any;
    car5MinIncomeOver1000: any;
};

//商圏ページ②車5分商圏データ初期値
export const initTradeAreaCar5MinMpObj: TradeAreaCar5MinMpTypeList = {
    car5MinTradeAreaHouseholdCount: 0,
    car5MinSingleHouseholdCount: 0,
    car5MinSingleHouseholdRatio: 0,
    car5MinPopulation: 0,
    car5MinAgeGroup20Total: 0,
    car5MinAgeGroup20Ratio: 0,
    car5MinAgeGroup30Total: 0,
    car5MinAgeGroup30Ratio: 0,
    car5MinAgeGroup50MoreTotal: 0,
    car5MinAgeGroup50MoreRatio: 0,
    car5MinAgeGroup65MoreTotal: 0,
    car5MinAgeGroup65MoreRatio: 0,
    car5MinOfficeCount: 0,
    car5MinEmployeesCount: 0,
    car5MinAllocationOffice: 0,
    car5MinAllocationEmployees: 0,
    car5MinDeductionOffice: 0,
    car5MinDeductionEmployees: 0,
    car5MinIncomeUpTo300: 0,
    car5MinIncomeUpTo500: 0,
    car5MinIncomeUpTo700: 0,
    car5MinIncomeUpTo1000: 0,
    car5MinIncomeOver1000: 0,
};

//商圏ページ③勾配加味徒歩5分商圏と④商圏内データAPI型定義
export type TradeAreaInitMpDataTypeList = {
    id: number;
    proration_retail_count: string; //引分事業所
    proration_retail_employees_count: string; //引分従業員
    income_up_to_300: string; //～300万
    income_up_to_500: string; //～500万
    income_up_to_700: string; //～700万
    income_up_to_1000: string; //～1000万
    income_over1000: string; //1000万～
    oaza_name: string; //大字名
    section_name: string; //字名
    single_household_ratio: string; //単独世帯数の比率
    popilation_total: string; //人口総数
    household_count: string; //世帯数
    age_group_20ratio: string; //20代比率
    age_group_30ratio: string; //30代比率
    age_group_50moreratio: string; //50才以上比率
    age_group_65moreratio: string; //65才以上比率
    mp_data_office_count: string; //location_excel_04_mp_data 事業所数
    employees_count: string; //従業者数
    residences_count: string; //住居戸数
    mp_data2_office_count: string; //location_excel_05_mp_data2 事業所数
    trade_area_seq: String; //商圏sequence
    k_chocode: string; //k_chocode
    reality_survey_household_count: string; //実査世帯数
    reality_survey_office: string; //実査事業所
    reality_survey_employed_workers: string; //実査就業者
};

//商圏ページ③勾配加味徒歩5分商圏と④商圏内データAPI型定義の初期値
export const initTradeAreaMpDataTypeList: TradeAreaInitMpDataTypeList[] = [];

//商圏ページ③勾配加味徒歩5分商圏と④商圏内データ型定義
export type TradeAreaMpDataTableTypeList = {
    id: number;
    gradientWalk5MinAllocationOffice: string; //引分事業所
    gradientWalk5MinAllocationEmployees: string; //引分従業員
    gradientWalk5MinIncomeUpTo300: string; //～300万
    gradientWalk5MinIncomeUpTo500: string; //～500万
    gradientWalk5MinIncomeUpTo700: string; //～700万
    gradientWalk5MinIncomeUpTo1000: string; //～1000万
    gradientWalk5MinIncomeOver1000: string; //1000万～
    townDistrict: string; //町丁字
    householdCount: string; //世帯数
    singleHousehold: string; //単身世帯
    population: string; //人口
    ageGroup20: string; //20代
    ageGroup30: string; //30代
    ageGroup50: string; //50代
    ageGroup65More: string; //65上
    office: string; //事業所
    employees: string; //従業員
    tradeAreaSeq: string; //商圏sequence
    kChocode: string; //k_chocode
    reality_survey_household_count: string; //実査世帯数
    reality_survey_office: string; //実査事業所
    reality_survey_employed_workers: string; //実査就業者
};

//商圏ページ③勾配加味徒歩5分商圏と④商圏内データ型定義の初期値
export const initTradeAreaMpDataTableTypeList: TradeAreaMpDataTableTypeList[] =
    [];

//商圏ページの④商圏内データ箇所のstring型の型定義
export type tradeAreaMpDataStringTypeList = {
    actual_survey_trade_area_data: string;
    [coverStringKey: string]: string; //string型でキーを指定するため
};

//商圏ページのモーダル内のテキストフィールドの初期値
export const initTradeAreaMpDataCommentTextFieldObj: tradeAreaMpDataStringTypeList =
    {
        actual_survey_trade_area_data: "",
    };

//商圏ページのboolean型の定義
export type TradeAreaMpDataBooleanTypeList = {
    actual_survey_trade_area_data: boolean;
};

//商圏ページの項目値を編集可能にするステータスの初期値 trueが入力不可、falseが入力可
export const initTradeAreaMpDataReadOnlyObj: TradeAreaMpDataBooleanTypeList = {
    actual_survey_trade_area_data: true,
};

//現況写真ページ項目名の型定義
export type PictureTypeList = {
    plan_area_id: string;
    branch: string;
    create_date: string;
    update_date: string;
    delete_flag: string;
    pictuer_1_path: string;
    pictuer_2_path: string;
    pictuer_3_path: string;
    pictuer_4_path: string;
    pictuer_5_path: string;
    pictuer_6_path: string;
    pictuer_7_path: string;
    pictuer_8_path: string;
    viewing_distance: number;
    upload_image: string;
    [pictureKey: string]: any; //string型でキーを指定するため
};

//現況写真ページ項目名の初期値
export const initPictureObj: PictureTypeList = {
    plan_area_id: "",
    branch: "",
    create_date: "",
    update_date: "",
    delete_flag: "",
    pictuer_1_path: "",
    pictuer_2_path: "",
    pictuer_3_path: "",
    pictuer_4_path: "",
    pictuer_5_path: "",
    pictuer_6_path: "",
    pictuer_7_path: "",
    pictuer_8_path: "",
    viewing_distance: 0,
    upload_image: "",
};

//現況写真ページのboolean型の定義
export type PictureBooleanTypeList = {
    viewing_distance: boolean;
    [pictureBooleanKey: string]: boolean; //string型でキーを指定するため
};

//現況写真ページのアイコン全体の表示非表示のステータスの初期値
export const initPictureShowIconObj: PictureBooleanTypeList = {
    viewing_distance: false,
};

//現況写真ページの鉛筆アイコンの表示非表示のステータスの初期値 trueが表示、falseが非表示
export const initPictureShowEditIconObj: PictureBooleanTypeList = {
    viewing_distance: true,
};

//現況写真ページのstring型の型定義
export type PictureStringTypeList = {
    pictuer_1_path: string;
    pictuer_2_path: string;
    pictuer_3_path: string;
    pictuer_4_path: string;
    pictuer_5_path: string;
    pictuer_6_path: string;
    pictuer_7_path: string;
    pictuer_8_path: string;
    viewing_distance: string;
    [pictureStringKey: string]: string; //string型でキーを指定するため
};

//現況写真ページのモーダル内のテキストフィールドの初期値
export const initPictureCommentTextFieldObj: PictureStringTypeList = {
    pictuer_1_path: "",
    pictuer_2_path: "",
    pictuer_3_path: "",
    pictuer_4_path: "",
    pictuer_5_path: "",
    pictuer_6_path: "",
    pictuer_7_path: "",
    pictuer_8_path: "",
    viewing_distance: "",
};

//現況写真ページの項目値を編集可能にするステータスの初期値 trueが入力不可、falseが入力可
export const initPictureReadOnlyObj: PictureBooleanTypeList = {
    viewing_distance: true,
};

//競合ページのboolean型の定義
export type ConflictBooleanTypeList = {
    households: boolean;
    employees: boolean;
    [conflictBooleanKey: string]: boolean; //string型でキーを指定するため
};

//競合ページのアイコン全体の表示非表示のステータスの初期値
export const initConflictShowIconObj: ConflictBooleanTypeList = {
    households: false,
    employees: false,
};

//競合ページの鉛筆アイコンの表示非表示のステータスの初期値 trueが表示、falseが非表示
export const initConflictShowEditIconObj: ConflictBooleanTypeList = {
    households: true,
    employees: true,
};

//競合ページの項目値を編集可能にするステータスの初期値 trueが入力不可、falseが入力可
export const initConflictReadOnlyObj: ConflictBooleanTypeList = {
    households: true,
    employees: true,
};

//競合ページのstring型の型定義
export type ConflictStringTypeList = {
    households: string;
    employees: string;
    marketCutBasis: string;
    [conflictStringKey: string]: string; //string型でキーを指定するため
};

//競合ページのモーダル内のテキストフィールドの初期値
export const initConflictCommentTextFieldObj: ConflictStringTypeList = {
    households: "",
    employees: "",
    marketCutBasis: "",
};

export type ImpactTypeList = {
    plan_area_id: String;
    branch: String;
    store_code: String;
    create_date: String;
    update_date: String;
    delete_flag: String;
    store_position: String;
    common_road: String;
    plan_median_strip: String;
    store_median_strip: String;
    straight_count: String;
    turn_count: String;
    railroad_crossing: String;
    river_width: String;
    crossing_road_count: String;
    conflict_sej: String;
    conflict_main_cvs: String;
    conflict_other_cvs: String;
    near_store_code: String;
    average_common_ratio: String;
    tenpo_name: String;
    tobaccolicense: String;
};

export const initImpactObj: ImpactTypeList = {
    plan_area_id: "",
    branch: "",
    store_code: "",
    create_date: "",
    update_date: "",
    delete_flag: "",
    store_position: "",
    common_road: "",
    plan_median_strip: "",
    store_median_strip: "",
    straight_count: "",
    turn_count: "",
    railroad_crossing: "",
    river_width: "",
    crossing_road_count: "",
    conflict_sej: "",
    conflict_main_cvs: "",
    conflict_other_cvs: "",
    near_store_code: "",
    average_common_ratio: "",
    tenpo_name: "",
    tobaccolicense: "",
};

//寄付きページ項目名の型定義
export type DonationTypeList = {
    driving_lane_positive_frontage: string;
    driving_lane_positive_sideway_a: string;
    driving_lane_positive_sideway_b: string;
    driving_lane_positive_backstreet: string;
    driving_lane_opposite_frontage: string;
    driving_lane_opposite_sideway_a: string;
    driving_lane_opposite_sideway_b: string;
    driving_lane_opposite_backstreet: string;
    median_strip_select: string;
    median_strip_select2: string;
    median_strip_select3: string;
    median_strip_select4: string;
    median_strip_height: string;
    median_strip_height2: string;
    median_strip_height3: string;
    median_strip_height4: string;
    sidewalk_select: string;
    sidewalk_select2: string;
    sidewalk_select3: string;
    sidewalk_select4: string;
    sidewalk_height: string;
    sidewalk_height2: string;
    sidewalk_height3: string;
    sidewalk_height4: string;
    road_width_door: string;
    road_width_door2: string;
    road_width_door3: string;
    road_width_door4: string;
    road_width_forward: string;
    road_width_forward2: string;
    road_width_forward3: string;
    road_width_forward4: string;
    road_width_front_location: string;
    road_width_front_location2: string;
    road_width_front_location3: string;
    road_width_front_location4: string;
    slope_climb: string;
    slope_climb2: string;
    slope_climb3: string;
    slope_climb4: string;
    slope_descend: string;
    slope_descend2: string;
    slope_descend3: string;
    slope_descend4: string;
    traffic_signal_etc_forward_select: string;
    traffic_signal_etc_forward_select2: string;
    traffic_signal_etc_forward_select3: string;
    traffic_signal_etc_forward_select4: string;
    traffic_signal_etc_forward_distance: string;
    traffic_signal_etc_forward_distance2: string;
    traffic_signal_etc_forward_distance3: string;
    traffic_signal_etc_forward_distance4: string;
    traffic_signal_etc_front_location_select: string;
    traffic_signal_etc_front_location_select2: string;
    traffic_signal_etc_front_location_select3: string;
    traffic_signal_etc_front_location_select4: string;
    traffic_signal_etc_front_location_distance: string;
    traffic_signal_etc_front_location_distance2: string;
    traffic_signal_etc_front_location_distance3: string;
    traffic_signal_etc_front_location_distance4: string;
    visibility_factor_building: string;
    visibility_factor_building2: string;
    visibility_factor_building3: string;
    visibility_factor_building4: string;
    visibility_factor_sign_post: string;
    visibility_factor_sign_post2: string;
    visibility_factor_sign_post3: string;
    visibility_factor_sign_post4: string;
    parking_area: string;
    parkingtsubo_area: string;
    parking_capacity: string;
    parking_tangent_line_frontage: string;
    parking_tangent_line_sideway_a: string;
    parking_tangent_line_sideway_b: string;
    parking_tangent_line_backstreet: string;
    no1_entrance_frontage: string;
    no1_entrance_sideway_a: string;
    no1_entrance_sideway_b: string;
    no1_entrance_backstreet: string;
    no1_depth_frontage: string;
    no1_depth_sideway_a: string;
    no1_depth_sideway_b: string;
    no1_depth_backstreet: string;
    no2_entrance_frontage: string;
    no2_entrance_sideway_a: string;
    no2_entrance_sideway_b: string;
    no2_entrance_backstreet: string;
    no2_depth_frontage: string;
    no2_depth_sideway_a: string;
    no2_depth_sideway_b: string;
    no2_depth_backstreet: string;
    complete_schedule: string;
    complete_schedule_year: string;
    complete_schedule_month: string;
    [donationStringKey: string]: string; //string型でキーを指定するため
};

//寄付きページ項目名の初期値
export const initDonationObj: DonationTypeList = {
    driving_lane_positive_frontage: "",
    driving_lane_positive_sideway_a: "",
    driving_lane_positive_sideway_b: "",
    driving_lane_positive_backstreet: "",
    driving_lane_opposite_frontage: "",
    driving_lane_opposite_sideway_a: "",
    driving_lane_opposite_sideway_b: "",
    driving_lane_opposite_backstreet: "",
    median_strip_select: "",
    median_strip_select2: "",
    median_strip_select3: "",
    median_strip_select4: "",
    median_strip_height: "",
    median_strip_height2: "",
    median_strip_height3: "",
    median_strip_height4: "",
    sidewalk_select: "",
    sidewalk_select2: "",
    sidewalk_select3: "",
    sidewalk_select4: "",
    sidewalk_height: "",
    sidewalk_height2: "",
    sidewalk_height3: "",
    sidewalk_height4: "",
    road_width_door: "",
    road_width_door2: "",
    road_width_door3: "",
    road_width_door4: "",
    road_width_forward: "",
    road_width_forward2: "",
    road_width_forward3: "",
    road_width_forward4: "",
    road_width_front_location: "",
    road_width_front_location2: "",
    road_width_front_location3: "",
    road_width_front_location4: "",
    slope_climb: "",
    slope_climb2: "",
    slope_climb3: "",
    slope_climb4: "",
    slope_descend: "",
    slope_descend2: "",
    slope_descend3: "",
    slope_descend4: "",
    traffic_signal_etc_forward_select: "",
    traffic_signal_etc_forward_select2: "",
    traffic_signal_etc_forward_select3: "",
    traffic_signal_etc_forward_select4: "",
    traffic_signal_etc_forward_distance: "",
    traffic_signal_etc_forward_distance2: "",
    traffic_signal_etc_forward_distance3: "",
    traffic_signal_etc_forward_distance4: "",
    traffic_signal_etc_front_location_select: "",
    traffic_signal_etc_front_location_select2: "",
    traffic_signal_etc_front_location_select3: "",
    traffic_signal_etc_front_location_select4: "",
    traffic_signal_etc_front_location_distance: "",
    traffic_signal_etc_front_location_distance2: "",
    traffic_signal_etc_front_location_distance3: "",
    traffic_signal_etc_front_location_distance4: "",
    visibility_factor_building: "",
    visibility_factor_building2: "",
    visibility_factor_building3: "",
    visibility_factor_building4: "",
    visibility_factor_sign_post: "",
    visibility_factor_sign_post2: "",
    visibility_factor_sign_post3: "",
    visibility_factor_sign_post4: "",
    parking_area: "",
    parkingtsubo_area: "",
    parking_capacity: "",
    parking_tangent_line_frontage: "",
    parking_tangent_line_sideway_a: "",
    parking_tangent_line_sideway_b: "",
    parking_tangent_line_backstreet: "",
    no1_entrance_frontage: "",
    no1_entrance_sideway_a: "",
    no1_entrance_sideway_b: "",
    no1_entrance_backstreet: "",
    no1_depth_frontage: "",
    no1_depth_sideway_a: "",
    no1_depth_sideway_b: "",
    no1_depth_backstreet: "",
    no2_entrance_frontage: "",
    no2_entrance_sideway_a: "",
    no2_entrance_sideway_b: "",
    no2_entrance_backstreet: "",
    no2_depth_frontage: "",
    no2_depth_sideway_a: "",
    no2_depth_sideway_b: "",
    no2_depth_backstreet: "",
    complete_schedule: "",
    complete_schedule_year: "",
    complete_schedule_month: "",
};

//寄付きページのboolean型の定義
export type DonationBooleanTypeList = {
    driving_lane_positive_frontage: boolean;
    driving_lane_positive_sideway_a: boolean;
    driving_lane_positive_sideway_b: boolean;
    driving_lane_positive_backstreet: boolean;
    driving_lane_opposite_frontage: boolean;
    driving_lane_opposite_sideway_a: boolean;
    driving_lane_opposite_sideway_b: boolean;
    driving_lane_opposite_backstreet: boolean;
    median_strip_select: boolean;
    median_strip_select2: boolean;
    median_strip_select3: boolean;
    median_strip_select4: boolean;
    median_strip_height: boolean;
    median_strip_height2: boolean;
    median_strip_height3: boolean;
    median_strip_height4: boolean;
    sidewalk_select: boolean;
    sidewalk_select2: boolean;
    sidewalk_select3: boolean;
    sidewalk_select4: boolean;
    sidewalk_height: boolean;
    sidewalk_height2: boolean;
    sidewalk_height3: boolean;
    sidewalk_height4: boolean;
    road_width_door: boolean;
    road_width_door2: boolean;
    road_width_door3: boolean;
    road_width_door4: boolean;
    road_width_forward: boolean;
    road_width_forward2: boolean;
    road_width_forward3: boolean;
    road_width_forward4: boolean;
    road_width_front_location: boolean;
    road_width_front_location2: boolean;
    road_width_front_location3: boolean;
    road_width_front_location4: boolean;
    slope_climb: boolean;
    slope_climb2: boolean;
    slope_climb3: boolean;
    slope_climb4: boolean;
    slope_descend: boolean;
    slope_descend2: boolean;
    slope_descend3: boolean;
    slope_descend4: boolean;
    traffic_signal_etc_forward_select: boolean;
    traffic_signal_etc_forward_select2: boolean;
    traffic_signal_etc_forward_select3: boolean;
    traffic_signal_etc_forward_select4: boolean;
    traffic_signal_etc_forward_distance: boolean;
    traffic_signal_etc_forward_distance2: boolean;
    traffic_signal_etc_forward_distance3: boolean;
    traffic_signal_etc_forward_distance4: boolean;
    traffic_signal_etc_front_location_select: boolean;
    traffic_signal_etc_front_location_select2: boolean;
    traffic_signal_etc_front_location_select3: boolean;
    traffic_signal_etc_front_location_select4: boolean;
    traffic_signal_etc_front_location_distance: boolean;
    traffic_signal_etc_front_location_distance2: boolean;
    traffic_signal_etc_front_location_distance3: boolean;
    traffic_signal_etc_front_location_distance4: boolean;
    visibility_factor_building: boolean;
    visibility_factor_building2: boolean;
    visibility_factor_building3: boolean;
    visibility_factor_building4: boolean;
    visibility_factor_sign_post: boolean;
    visibility_factor_sign_post2: boolean;
    visibility_factor_sign_post3: boolean;
    visibility_factor_sign_post4: boolean;
    parking_area: boolean;
    parkingtsubo_area: boolean;
    parking_capacity: boolean;
    parking_tangent_line_frontage: boolean;
    parking_tangent_line_sideway_a: boolean;
    parking_tangent_line_sideway_b: boolean;
    parking_tangent_line_backstreet: boolean;
    no1_entrance_frontage: boolean;
    no1_entrance_sideway_a: boolean;
    no1_entrance_sideway_b: boolean;
    no1_entrance_backstreet: boolean;
    no1_depth_frontage: boolean;
    no1_depth_sideway_a: boolean;
    no1_depth_sideway_b: boolean;
    no1_depth_backstreet: boolean;
    no2_entrance_frontage: boolean;
    no2_entrance_sideway_a: boolean;
    no2_entrance_sideway_b: boolean;
    no2_entrance_backstreet: boolean;
    no2_depth_frontage: boolean;
    no2_depth_sideway_a: boolean;
    no2_depth_sideway_b: boolean;
    no2_depth_backstreet: boolean;
    complete_schedule: boolean;
    [donationBooleanKey: string]: boolean; //string型でキーを指定するため
};

//寄付きページのアイコン全体の表示非表示のステータスの初期値
export const initDonationShowIconObj: DonationBooleanTypeList = {
    driving_lane_positive_frontage: false,
    driving_lane_positive_sideway_a: false,
    driving_lane_positive_sideway_b: false,
    driving_lane_positive_backstreet: false,
    driving_lane_opposite_frontage: false,
    driving_lane_opposite_sideway_a: false,
    driving_lane_opposite_sideway_b: false,
    driving_lane_opposite_backstreet: false,
    median_strip_select: false,
    median_strip_select2: false,
    median_strip_select3: false,
    median_strip_select4: false,
    median_strip_height: false,
    median_strip_height2: false,
    median_strip_height3: false,
    median_strip_height4: false,
    sidewalk_select: false,
    sidewalk_select2: false,
    sidewalk_select3: false,
    sidewalk_select4: false,
    sidewalk_height: false,
    sidewalk_height2: false,
    sidewalk_height3: false,
    sidewalk_height4: false,
    road_width_door: false,
    road_width_door2: false,
    road_width_door3: false,
    road_width_door4: false,
    road_width_forward: false,
    road_width_forward2: false,
    road_width_forward3: false,
    road_width_forward4: false,
    road_width_front_location: false,
    road_width_front_location2: false,
    road_width_front_location3: false,
    road_width_front_location4: false,
    slope_climb: false,
    slope_climb2: false,
    slope_climb3: false,
    slope_climb4: false,
    slope_descend: false,
    slope_descend2: false,
    slope_descend3: false,
    slope_descend4: false,
    traffic_signal_etc_forward_select: false,
    traffic_signal_etc_forward_select2: false,
    traffic_signal_etc_forward_select3: false,
    traffic_signal_etc_forward_select4: false,
    traffic_signal_etc_forward_distance: false,
    traffic_signal_etc_forward_distance2: false,
    traffic_signal_etc_forward_distance3: false,
    traffic_signal_etc_forward_distance4: false,
    traffic_signal_etc_front_location_select: false,
    traffic_signal_etc_front_location_select2: false,
    traffic_signal_etc_front_location_select3: false,
    traffic_signal_etc_front_location_select4: false,
    traffic_signal_etc_front_location_distance: false,
    traffic_signal_etc_front_location_distance2: false,
    traffic_signal_etc_front_location_distance3: false,
    traffic_signal_etc_front_location_distance4: false,
    visibility_factor_building: false,
    visibility_factor_building2: false,
    visibility_factor_building3: false,
    visibility_factor_building4: false,
    visibility_factor_sign_post: false,
    visibility_factor_sign_post2: false,
    visibility_factor_sign_post3: false,
    visibility_factor_sign_post4: false,
    parking_area: false,
    parkingtsubo_area: false,
    parking_capacity: false,
    parking_tangent_line_frontage: false,
    parking_tangent_line_sideway_a: false,
    parking_tangent_line_sideway_b: false,
    parking_tangent_line_backstreet: false,
    no1_entrance_frontage: false,
    no1_entrance_sideway_a: false,
    no1_entrance_sideway_b: false,
    no1_entrance_backstreet: false,
    no1_depth_frontage: false,
    no1_depth_sideway_a: false,
    no1_depth_sideway_b: false,
    no1_depth_backstreet: false,
    no2_entrance_frontage: false,
    no2_entrance_sideway_a: false,
    no2_entrance_sideway_b: false,
    no2_entrance_backstreet: false,
    no2_depth_frontage: false,
    no2_depth_sideway_a: false,
    no2_depth_sideway_b: false,
    no2_depth_backstreet: false,
    complete_schedule: false,
};

//寄付きページの鉛筆アイコンの表示非表示のステータスの初期値 trueが表示、falseが非表示
export const initDonationShowEditIconObj: DonationBooleanTypeList = {
    driving_lane_positive_frontage: true,
    driving_lane_positive_sideway_a: true,
    driving_lane_positive_sideway_b: true,
    driving_lane_positive_backstreet: true,
    driving_lane_opposite_frontage: true,
    driving_lane_opposite_sideway_a: true,
    driving_lane_opposite_sideway_b: true,
    driving_lane_opposite_backstreet: true,
    median_strip_select: true,
    median_strip_select2: true,
    median_strip_select3: true,
    median_strip_select4: true,
    median_strip_height: true,
    median_strip_height2: true,
    median_strip_height3: true,
    median_strip_height4: true,
    sidewalk_select: true,
    sidewalk_select2: true,
    sidewalk_select3: true,
    sidewalk_select4: true,
    sidewalk_height: true,
    sidewalk_height2: true,
    sidewalk_height3: true,
    sidewalk_height4: true,
    road_width_door: true,
    road_width_door2: true,
    road_width_door3: true,
    road_width_door4: true,
    road_width_forward: true,
    road_width_forward2: true,
    road_width_forward3: true,
    road_width_forward4: true,
    road_width_front_location: true,
    road_width_front_location2: true,
    road_width_front_location3: true,
    road_width_front_location4: true,
    slope_climb: true,
    slope_climb2: true,
    slope_climb3: true,
    slope_climb4: true,
    slope_descend: true,
    slope_descend2: true,
    slope_descend3: true,
    slope_descend4: true,
    traffic_signal_etc_forward_select: true,
    traffic_signal_etc_forward_select2: true,
    traffic_signal_etc_forward_select3: true,
    traffic_signal_etc_forward_select4: true,
    traffic_signal_etc_forward_distance: true,
    traffic_signal_etc_forward_distance2: true,
    traffic_signal_etc_forward_distance3: true,
    traffic_signal_etc_forward_distance4: true,
    traffic_signal_etc_front_location_select: true,
    traffic_signal_etc_front_location_select2: true,
    traffic_signal_etc_front_location_select3: true,
    traffic_signal_etc_front_location_select4: true,
    traffic_signal_etc_front_location_distance: true,
    traffic_signal_etc_front_location_distance2: true,
    traffic_signal_etc_front_location_distance3: true,
    traffic_signal_etc_front_location_distance4: true,
    visibility_factor_building: true,
    visibility_factor_building2: true,
    visibility_factor_building3: true,
    visibility_factor_building4: true,
    visibility_factor_sign_post: true,
    visibility_factor_sign_post2: true,
    visibility_factor_sign_post3: true,
    visibility_factor_sign_post4: true,
    parking_area: true,
    parkingtsubo_area: false,
    parking_capacity: true,
    parking_tangent_line_frontage: true,
    parking_tangent_line_sideway_a: true,
    parking_tangent_line_sideway_b: true,
    parking_tangent_line_backstreet: true,
    no1_entrance_frontage: true,
    no1_entrance_sideway_a: true,
    no1_entrance_sideway_b: true,
    no1_entrance_backstreet: true,
    no1_depth_frontage: true,
    no1_depth_sideway_a: true,
    no1_depth_sideway_b: true,
    no1_depth_backstreet: true,
    no2_entrance_frontage: true,
    no2_entrance_sideway_a: true,
    no2_entrance_sideway_b: true,
    no2_entrance_backstreet: true,
    no2_depth_frontage: true,
    no2_depth_sideway_a: true,
    no2_depth_sideway_b: true,
    no2_depth_backstreet: true,
    complete_schedule: true,
};

//寄付きページのstring型の定義
export type DonationStringTypeList = {
    driving_lane_positive_frontage: string;
    driving_lane_positive_sideway_a: string;
    driving_lane_positive_sideway_b: string;
    driving_lane_positive_backstreet: string;
    driving_lane_opposite_frontage: string;
    driving_lane_opposite_sideway_a: string;
    driving_lane_opposite_sideway_b: string;
    driving_lane_opposite_backstreet: string;
    median_strip_select: string;
    median_strip_select2: string;
    median_strip_select3: string;
    median_strip_select4: string;
    median_strip_height: string;
    median_strip_height2: string;
    median_strip_height3: string;
    median_strip_height4: string;
    sidewalk_select: string;
    sidewalk_select2: string;
    sidewalk_select3: string;
    sidewalk_select4: string;
    sidewalk_height: string;
    sidewalk_height2: string;
    sidewalk_height3: string;
    sidewalk_height4: string;
    road_width_door: string;
    road_width_door2: string;
    road_width_door3: string;
    road_width_door4: string;
    road_width_forward: string;
    road_width_forward2: string;
    road_width_forward3: string;
    road_width_forward4: string;
    road_width_front_location: string;
    road_width_front_location2: string;
    road_width_front_location3: string;
    road_width_front_location4: string;
    slope_climb: string;
    slope_climb2: string;
    slope_climb3: string;
    slope_climb4: string;
    slope_descend: string;
    slope_descend2: string;
    slope_descend3: string;
    slope_descend4: string;
    traffic_signal_etc_forward_select: string;
    traffic_signal_etc_forward_select2: string;
    traffic_signal_etc_forward_select3: string;
    traffic_signal_etc_forward_select4: string;
    traffic_signal_etc_forward_distance: string;
    traffic_signal_etc_forward_distance2: string;
    traffic_signal_etc_forward_distance3: string;
    traffic_signal_etc_forward_distance4: string;
    traffic_signal_etc_front_location_select: string;
    traffic_signal_etc_front_location_select2: string;
    traffic_signal_etc_front_location_select3: string;
    traffic_signal_etc_front_location_select4: string;
    traffic_signal_etc_front_location_distance: string;
    traffic_signal_etc_front_location_distance2: string;
    traffic_signal_etc_front_location_distance3: string;
    traffic_signal_etc_front_location_distance4: string;
    visibility_factor_building: string;
    visibility_factor_building2: string;
    visibility_factor_building3: string;
    visibility_factor_building4: string;
    visibility_factor_sign_post: string;
    visibility_factor_sign_post2: string;
    visibility_factor_sign_post3: string;
    visibility_factor_sign_post4: string;
    parking_area: string;
    parkingtsubo_area: string;
    parking_capacity: string;
    parking_tangent_line_frontage: string;
    parking_tangent_line_sideway_a: string;
    parking_tangent_line_sideway_b: string;
    parking_tangent_line_backstreet: string;
    no1_entrance_frontage: string;
    no1_entrance_sideway_a: string;
    no1_entrance_sideway_b: string;
    no1_entrance_backstreet: string;
    no1_depth_frontage: string;
    no1_depth_sideway_a: string;
    no1_depth_sideway_b: string;
    no1_depth_backstreet: string;
    no2_entrance_frontage: string;
    no2_entrance_sideway_a: string;
    no2_entrance_sideway_b: string;
    no2_entrance_backstreet: string;
    no2_depth_frontage: string;
    no2_depth_sideway_a: string;
    no2_depth_sideway_b: string;
    no2_depth_backstreet: string;
    complete_schedule: string;
    [donationStringKey: string]: string; //string型でキーを指定するため
};

//寄付きページのモーダル内のテキストフィールドの初期値
export const initDonationCommentTextFieldObj: DonationStringTypeList = {
    driving_lane_positive_frontage: "",
    driving_lane_positive_sideway_a: "",
    driving_lane_positive_sideway_b: "",
    driving_lane_positive_backstreet: "",
    driving_lane_opposite_frontage: "",
    driving_lane_opposite_sideway_a: "",
    driving_lane_opposite_sideway_b: "",
    driving_lane_opposite_backstreet: "",
    median_strip_select: "",
    median_strip_select2: "",
    median_strip_select3: "",
    median_strip_select4: "",
    median_strip_height: "",
    median_strip_height2: "",
    median_strip_height3: "",
    median_strip_height4: "",
    sidewalk_select: "",
    sidewalk_select2: "",
    sidewalk_select3: "",
    sidewalk_select4: "",
    sidewalk_height: "",
    sidewalk_height2: "",
    sidewalk_height3: "",
    sidewalk_height4: "",
    road_width_door: "",
    road_width_door2: "",
    road_width_door3: "",
    road_width_door4: "",
    road_width_forward: "",
    road_width_forward2: "",
    road_width_forward3: "",
    road_width_forward4: "",
    road_width_front_location: "",
    road_width_front_location2: "",
    road_width_front_location3: "",
    road_width_front_location4: "",
    slope_climb: "",
    slope_climb2: "",
    slope_climb3: "",
    slope_climb4: "",
    slope_descend: "",
    slope_descend2: "",
    slope_descend3: "",
    slope_descend4: "",
    traffic_signal_etc_forward_select: "",
    traffic_signal_etc_forward_select2: "",
    traffic_signal_etc_forward_select3: "",
    traffic_signal_etc_forward_select4: "",
    traffic_signal_etc_forward_distance: "",
    traffic_signal_etc_forward_distance2: "",
    traffic_signal_etc_forward_distance3: "",
    traffic_signal_etc_forward_distance4: "",
    traffic_signal_etc_front_location_select: "",
    traffic_signal_etc_front_location_select2: "",
    traffic_signal_etc_front_location_select3: "",
    traffic_signal_etc_front_location_select4: "",
    traffic_signal_etc_front_location_distance: "",
    traffic_signal_etc_front_location_distance2: "",
    traffic_signal_etc_front_location_distance3: "",
    traffic_signal_etc_front_location_distance4: "",
    visibility_factor_building: "",
    visibility_factor_building2: "",
    visibility_factor_building3: "",
    visibility_factor_building4: "",
    visibility_factor_sign_post: "",
    visibility_factor_sign_post2: "",
    visibility_factor_sign_post3: "",
    visibility_factor_sign_post4: "",
    parking_area: "",
    parkingtsubo_area: "",
    parking_capacity: "",
    parking_tangent_line_frontage: "",
    parking_tangent_line_sideway_a: "",
    parking_tangent_line_sideway_b: "",
    parking_tangent_line_backstreet: "",
    no1_entrance_frontage: "",
    no1_entrance_sideway_a: "",
    no1_entrance_sideway_b: "",
    no1_entrance_backstreet: "",
    no1_depth_frontage: "",
    no1_depth_sideway_a: "",
    no1_depth_sideway_b: "",
    no1_depth_backstreet: "",
    no2_entrance_frontage: "",
    no2_entrance_sideway_a: "",
    no2_entrance_sideway_b: "",
    no2_entrance_backstreet: "",
    no2_depth_frontage: "",
    no2_depth_sideway_a: "",
    no2_depth_sideway_b: "",
    no2_depth_backstreet: "",
    complete_schedule: "",
};

//寄付きページの項目値を編集可能にするステータスの初期値 trueが入力不可、falseが入力可
export const initDonationReadOnlyObj: DonationBooleanTypeList = {
    driving_lane_positive_frontage: true,
    driving_lane_positive_sideway_a: true,
    driving_lane_positive_sideway_b: true,
    driving_lane_positive_backstreet: true,
    driving_lane_opposite_frontage: true,
    driving_lane_opposite_sideway_a: true,
    driving_lane_opposite_sideway_b: true,
    driving_lane_opposite_backstreet: true,
    median_strip_select: true,
    median_strip_select2: true,
    median_strip_select3: true,
    median_strip_select4: true,
    median_strip_height: true,
    median_strip_height2: true,
    median_strip_height3: true,
    median_strip_height4: true,
    sidewalk_select: true,
    sidewalk_select2: true,
    sidewalk_select3: true,
    sidewalk_select4: true,
    sidewalk_height: true,
    sidewalk_height2: true,
    sidewalk_height3: true,
    sidewalk_height4: true,
    road_width_door: true,
    road_width_door2: true,
    road_width_door3: true,
    road_width_door4: true,
    road_width_forward: true,
    road_width_forward2: true,
    road_width_forward3: true,
    road_width_forward4: true,
    road_width_front_location: true,
    road_width_front_location2: true,
    road_width_front_location3: true,
    road_width_front_location4: true,
    slope_climb: true,
    slope_climb2: true,
    slope_climb3: true,
    slope_climb4: true,
    slope_descend: true,
    slope_descend2: true,
    slope_descend3: true,
    slope_descend4: true,
    traffic_signal_etc_forward_select: true,
    traffic_signal_etc_forward_select2: true,
    traffic_signal_etc_forward_select3: true,
    traffic_signal_etc_forward_select4: true,
    traffic_signal_etc_forward_distance: true,
    traffic_signal_etc_forward_distance2: true,
    traffic_signal_etc_forward_distance3: true,
    traffic_signal_etc_forward_distance4: true,
    traffic_signal_etc_front_location_select: true,
    traffic_signal_etc_front_location_select2: true,
    traffic_signal_etc_front_location_select3: true,
    traffic_signal_etc_front_location_select4: true,
    traffic_signal_etc_front_location_distance: true,
    traffic_signal_etc_front_location_distance2: true,
    traffic_signal_etc_front_location_distance3: true,
    traffic_signal_etc_front_location_distance4: true,
    visibility_factor_building: true,
    visibility_factor_building2: true,
    visibility_factor_building3: true,
    visibility_factor_building4: true,
    visibility_factor_sign_post: true,
    visibility_factor_sign_post2: true,
    visibility_factor_sign_post3: true,
    visibility_factor_sign_post4: true,
    parking_area: true,
    parkingtsubo_area: true,
    parking_capacity: true,
    parking_tangent_line_frontage: true,
    parking_tangent_line_sideway_a: true,
    parking_tangent_line_sideway_b: true,
    parking_tangent_line_backstreet: true,
    no1_entrance_frontage: true,
    no1_entrance_sideway_a: true,
    no1_entrance_sideway_b: true,
    no1_entrance_backstreet: true,
    no1_depth_frontage: true,
    no1_depth_sideway_a: true,
    no1_depth_sideway_b: true,
    no1_depth_backstreet: true,
    no2_entrance_frontage: true,
    no2_entrance_sideway_a: true,
    no2_entrance_sideway_b: true,
    no2_entrance_backstreet: true,
    no2_depth_frontage: true,
    no2_depth_sideway_a: true,
    no2_depth_sideway_b: true,
    no2_depth_backstreet: true,
    complete_schedule: true,
};

export type SalesForecastSummaryMainElementListObjTypeList = {
    pdfPath: string;
    temp_sales_forecast_path: string;
    temp_sales_forecast_upload_date: string;
    temp_sales_forecast_upload_user: string;
    temp_sales_forecast_upload_mail: string;
    sales_forecast_path: string;
    sales_forecast_upload_date: string;
    sales_forecast_upload_user: string;
    sales_forecast_upload_mail: string;
};
export const initSalesForecastSummaryMainElementListObj: SalesForecastSummaryMainElementListObjTypeList =
    {
        pdfPath: "",
        temp_sales_forecast_path: "",
        temp_sales_forecast_upload_date: "",
        temp_sales_forecast_upload_user: "",
        temp_sales_forecast_upload_mail: "",
        sales_forecast_path: "",
        sales_forecast_upload_date: "",
        sales_forecast_upload_user: "",
        sales_forecast_upload_mail: "",
    };

export type PdfSummaryObjTypeList = {
    pdfPath: string;
};
export const initPdfSummaryObjTypeList: PdfSummaryObjTypeList = {
    pdfPath: "",
};

export type AddConflictObjTypeList = {
    competitiveTarget: string;
    walkWithInCommercialArea: string;
    chainName: string;
    storeName: string;
    prefectures: string;
    address: string;
    businessType: string;
    businessHours: string;
    closingTime: string;
    liquorLicense: string;
    tobaccoLicense: string;
    location: string;
    salesFloorArea: string;
    gondolaScale: string;
    parkingAvailable: string;
    storeFrontage: string;
    parkingArea: string;
    numberOfParkingSpaces: string;
    frontParkingLotTangent: string;
    frontFirstEntrance: string;
    frontFirstDepth: string;
    frontSecondEntrance: string;
    frontSecondDepth: string;
    typeOfSideRoad: string;
    sideRoadParkingLotTangent: string;
    sideRoadFirstEntrance: string;
    sideRoadFirstDepth: string;
    sideRoadSecondEntrance: string;
    sideRoadSecondDepth: string;
    estimatedDailSsales: string;
    directDistance: string;
    roadDistance: string;
    commonRoadInFrontOfCandidate: string;
    direction: string;
    commonRoadBeforeCompetition: string;
    presenceOfCommonRoadSeparationStripBeforeCompetition: string;
    leftRightTurnIndicator: string;
    straightGauge: string;
    roadShape: google.maps.Polyline | null;
};

export const initAddConflictObj: AddConflictObjTypeList = {
    competitiveTarget: "", // 競合対象
    walkWithInCommercialArea: "", // 徒歩5分商圏内外
    chainName: "", // チェーン名
    storeName: "", // 店名
    prefectures: "", // 都道府県
    address: "", // 住所
    businessType: "", // 業態
    businessHours: "", // 営業時間
    closingTime: "", // 閉店時間
    liquorLicense: "無", // 酒免許
    tobaccoLicense: "無", // 煙草免許
    location: "", // 立地
    salesFloorArea: "", // 売場面積(㎡)
    gondolaScale: "", // ゴンドラ尺数
    parkingAvailable: "無", // 駐車場有無
    storeFrontage: "", // 店舗間口
    parkingArea: "", // 駐車場面積(㎡)
    numberOfParkingSpaces: "", // 駐車場台数
    frontParkingLotTangent: "", // 前面駐車場接線(m)
    frontFirstEntrance: "", // 前面第一進入口(m)
    frontFirstDepth: "", // 前面第一奥行き(m)
    frontSecondEntrance: "", // 前面第二進入口(m)
    frontSecondDepth: "", // 前面第二奥行き(m)
    typeOfSideRoad: "", // 側道有無種類
    sideRoadParkingLotTangent: "", // 側道(裏道)駐車場接線(m)
    sideRoadFirstEntrance: "", // 側道(裏道)第一進入口(m)
    sideRoadFirstDepth: "", // 側道(裏道)第一奥行き(m)
    sideRoadSecondEntrance: "", // 側道第二進入口(m)
    sideRoadSecondDepth: "", // 側道第二奥行き(m)
    estimatedDailSsales: "", // 推定日販(千円)
    directDistance: "", // 直線距離(m)
    roadDistance: "", // 道なり距離(m)
    commonRoadInFrontOfCandidate: "", // 候補前共通道路
    direction: "", // 方向
    commonRoadBeforeCompetition: "", // 競合前共通道路
    presenceOfCommonRoadSeparationStripBeforeCompetition: "", // 競合前共通道路分離帯有無
    leftRightTurnIndicator: "", // 右左折計
    straightGauge: "", // 直進計
    roadShape: null, // 道なり距離形状
};

//地図画面 プレビューpdfパス 型定義
export type mapPdfObjTypeList = {
    pdfPath: string;
};

//地図画面 プレビューpdfパス 初期値
export const initMapPdfObj: mapPdfObjTypeList = {
    pdfPath: "",
};

export type appHeaderObjTypeList = {
    planAreaId: string;
    planAreaName: string;
    citycode: string;
    centerlat: string;
    centerlon: string;
};

export const initAddHeaderObj: appHeaderObjTypeList = {
    planAreaId: "",
    planAreaName: "",
    citycode: "",
    centerlat: "",
    centerlon: "",
};

export type longPageParamTypeList = {
    planAreaId: string;
    branch: string;
};

export const initLongPageParamObj: longPageParamTypeList = {
    planAreaId: "",
    branch: "",
};

export type longPageSaveStatusTypeList = {
    LongPage: boolean;
    AddSurvey: boolean;
    Cover: boolean;
    StoreOpeningConditions: boolean;
    TradeArea: boolean;
    Picture: boolean;
    Donation: boolean;
    TrafficVolume: boolean;
    TrafficVolumeCarPeople: boolean;
    Conflict: boolean;
    ConflictMarketCut: boolean;
    Impact: boolean;
    Map: boolean;
    Drawing: boolean;
};

export const initLongPageSaveStatusObj: longPageSaveStatusTypeList = {
    LongPage: false,
    AddSurvey: false,
    Cover: false,
    StoreOpeningConditions: false,
    TradeArea: false,
    Picture: false,
    Donation: false,
    TrafficVolume: false,
    TrafficVolumeCarPeople: false,
    Conflict: false,
    ConflictMarketCut: false,
    Impact: false,
    Map: false,
    Drawing: false,
};

export type UnitEditTypeList = {
    ConflictCVSOwn: boolean; //競合CVS 自店
    ConflictCVS: boolean; //競合CVS 競合店
    ConflictSM: boolean; //競合SM 競合
};

export const initUnitEditObj: UnitEditTypeList = {
    ConflictCVSOwn: false, //競合CVS 自店
    ConflictCVS: false, //競合CVS 競合店
    ConflictSM: false, //競合SM 競合
};

export type TrafficUnitEditTypeList = {
    TrafficType0: boolean; //車 朝
    TrafficType1: boolean; //車 昼
    TrafficType2: boolean; //車 夜
    TrafficType4: boolean; //車 土曜、休祝日又は行楽地のトップシーズン
    TrafficType6In5m: boolean; //人 5m内 朝
    TrafficType7In5m: boolean; //人 5m内 朝特定TR
    TrafficType8In5m: boolean; //人 5m内 昼
    TrafficType9In5m: boolean; //人 5m内 昼特定TR
    TrafficType10In5m: boolean; //人 5m内 夜
    TrafficType12In5m: boolean; //人 5m内 夕特定TR
    TrafficType13In5m: boolean; //人 5m内 土曜、休祝日又は行楽地のトップシーズン
    TrafficType6Out5m: boolean; //人 5m外 朝
    TrafficType7Out5m: boolean; //人 5m外 朝特定TR
    TrafficType8Out5m: boolean; //人 5m外 昼
    TrafficType9Out5m: boolean; //人 5m外 昼特定TR
    TrafficType10Out5m: boolean; //人 5m外 夜
    TrafficType12Out5m: boolean; //人 5m外 夕特定TR
    TrafficType13Out5m: boolean; //人 5m外 土曜、休祝日又は行楽地のトップシーズン
};

export const initTrafficEditObj: TrafficUnitEditTypeList = {
    TrafficType0: false, //車 朝
    TrafficType1: false, //車 昼
    TrafficType2: false, //車 夜
    TrafficType4: false, //車 土曜、休祝日又は行楽地のトップシーズン
    TrafficType6In5m: false, //人 5m内 朝
    TrafficType7In5m: false, //人 5m内 朝特定TR
    TrafficType8In5m: false, //人 5m内 昼
    TrafficType9In5m: false, //人 5m内 昼特定TR
    TrafficType10In5m: false, //人 5m内 夜
    TrafficType12In5m: false, //人 5m内 夕特定TR
    TrafficType13In5m: false, //人 5m内 土曜、休祝日又は行楽地のトップシーズン
    TrafficType6Out5m: false, //人 5m外 朝
    TrafficType7Out5m: false, //人 5m外 朝特定TR
    TrafficType8Out5m: false, //人 5m外 昼
    TrafficType9Out5m: false, //人 5m外 昼特定TR
    TrafficType10Out5m: false, //人 5m外 夜
    TrafficType12Out5m: false, //人 5m外 夕特定TR
    TrafficType13Out5m: false, //人 5m外 土曜、休祝日又は行楽地のトップシーズン
};

export type locationBaseTypeList = {
    plan_area_id: string;
    branch: string;
    location_type: string;
    location_excel_flag: string;
    location_excel_sequence: string;
    child_location: string;
    parent_location: string;
    temp_sales_forecast_path: string;
    temp_sales_forecast_upload_user: string;
    temp_sales_forecast_upload_mail: string;
    sales_forecast_path: string;
    sales_forecast_upload_user: string;
    sales_forecast_upload_mail: string;
    shp_flag: string;
    task_id: string;
    status_id: string;
    status_seq: any;
    sb_document_excel_path: string;
    sb_document_pdf_path: string;
    other_document_excel_path: any[];
    other_document_pdf_path: any[];
    rr_document_pdf_path: any[];
    map_excel_path: string;
    map_excel_user: string;
    map_pdf_path: string;
    map_pdf_user: string;
    impact_judge_pdf_path: string;
    impact_judge_pdf_user: string;
    daily_sales_year_1: string;
    daily_sales_year_2: string;
    daily_sales_year_3: string;
    daily_sales_year_4: string;
    daily_sales_year_5: string;
    impact_store_name_1: string;
    impact_store_1: string;
    impact_store_name_2: string;
    impact_store_2: string;
    impact_store_name_3: string;
    impact_store_3: string;
    impact_store_name_4: string;
    impact_store_4: string;
};

export const initLocationBaseObj: locationBaseTypeList = {
    plan_area_id: "",
    branch: "",
    location_type: "",
    location_excel_flag: "",
    location_excel_sequence: "0",
    child_location: "",
    parent_location: "",
    temp_sales_forecast_path: "",
    temp_sales_forecast_upload_user: "",
    temp_sales_forecast_upload_mail: "",
    sales_forecast_path: "",
    sales_forecast_upload_user: "",
    sales_forecast_upload_mail: "",
    shp_flag: "",
    task_id: "",
    status_id: "",
    status_seq: 0,
    sb_document_excel_path: "",
    sb_document_pdf_path: "",
    other_document_excel_path: [],
    other_document_pdf_path: [],
    rr_document_pdf_path: [],
    map_excel_path: "",
    map_excel_user: "",
    map_pdf_path: "",
    map_pdf_user: "",
    impact_judge_pdf_path: "",
    impact_judge_pdf_user: "",
    daily_sales_year_1: "",
    daily_sales_year_2: "",
    daily_sales_year_3: "",
    daily_sales_year_4: "",
    daily_sales_year_5: "",
    impact_store_name_1: "",
    impact_store_1: "",
    impact_store_name_2: "",
    impact_store_2: "",
    impact_store_name_3: "",
    impact_store_3: "",
    impact_store_name_4: "",
    impact_store_4: "",
};

export type portalObjTypeList = {
    conversionCreateDate: string; //依頼受付日
    statusId: string[]; //ステータス
    picType: string[]; //担当依頼
    picStaffNumber: string[]; //依頼担当者
    taskId: string[]; //依頼タスク
    planAreaId: string; //候補地No
    departmentCode: string[]; //部
    districtCode: string[]; //地区
    planAreaName: string; //候補地名
    planAreaRfc: string[]; //RFC
    statusValues: any[]; //「ステータス」項目配列保持用
    picTypeValues: any[]; //「担当依頼」項目配列保持用
    taskValues: any[]; //「依頼タスク」配列保持用
    picNameValues: any[]; //「依頼担当者」配列保持用
    departmentValues: any[]; //「部」配列保持用
    districtValues: any[]; //「地区」配列保持用
    localStorageFlag: boolean; //ローカルストレージの更新判定フラグ
};

export const initPortalObj: portalObjTypeList = {
    conversionCreateDate: "", //依頼受付日
    statusId: [], //ステータス
    picType: [], //担当依頼
    picStaffNumber: [], //依頼担当者
    taskId: [], //依頼タスク
    planAreaId: "", //候補地No
    departmentCode: [], //部
    districtCode: [], //地区
    planAreaName: "", //候補地名
    planAreaRfc: [], //RFC
    statusValues: [], //「ステータス」項目配列保持用
    picTypeValues: [], //「担当依頼」項目配列保持用
    taskValues: [], //「依頼タスク」配列保持用
    picNameValues: [], //「依頼担当者」配列保持用
    departmentValues: [], //「部」配列保持用
    districtValues: [], //「地区」配列保持用
    localStorageFlag: false, //ローカルストレージの更新フラグ
};

//図面ページ項目名の型定義
export type DrawingTypeList = {
    upload_file_paths: any[];
    is_green_gem: boolean;
    is_grave: boolean;
    is_median_strip: boolean;
    is_bus_stop_blackout: boolean;
    is_in_green_gem: boolean;
    is_other_tenant_parking: boolean;
    is_expressway_etc_pole: boolean;
    is_bus_lane_etc: boolean;
    is_electricity_pole: boolean;
    is_sidewalk: boolean;
    is_one_way_street: boolean;
    is_tram_track: boolean;
    is_electricity_pole_branch_line: boolean;
    is_crosswalk: boolean;
    is_no_entry_road: boolean;
    is_parking_meter: boolean;
    is_high_voltage_towers: boolean;
    is_guardrail: boolean;
    is_waterway_etc: boolean;
    is_others_place: boolean;
    is_hydrant_etc: boolean;
    is_street_trees_etc: boolean;
    is_railroad_crossing: boolean;
    is_old_road: boolean;
    is_billboard_tower_etc: boolean;
    is_street_lights_etc: boolean;
    is_bridge: boolean;
    is_unpaved_road: boolean;
    is_garbage_dump: boolean;
    is_road_signs: boolean;
    is_overpass: boolean;
    is_unmaintained_etc: boolean;
    is_other: boolean;
    other_long_text: string;
    hand_drawn_layout: any[];
    hand_drawn_layout_total: number;
    hand_drawn_layout_total_tsubo: number;
    layout_file_path: string;
    layout_file_user: string;
    placement_file_path: string;
    placement_file_user: string;
    designer_placement_file_path: string;
    designer_placement_file_user: string;
};

//図面ページ項目名の初期値
export const initDrawingObj: DrawingTypeList = {
    upload_file_paths: [],
    is_green_gem: false,
    is_grave: false,
    is_median_strip: false,
    is_bus_stop_blackout: false,
    is_in_green_gem: false,
    is_other_tenant_parking: false,
    is_expressway_etc_pole: false,
    is_bus_lane_etc: false,
    is_electricity_pole: false,
    is_sidewalk: false,
    is_one_way_street: false,
    is_tram_track: false,
    is_electricity_pole_branch_line: false,
    is_crosswalk: false,
    is_no_entry_road: false,
    is_parking_meter: false,
    is_high_voltage_towers: false,
    is_guardrail: false,
    is_waterway_etc: false,
    is_others_place: false,
    is_hydrant_etc: false,
    is_street_trees_etc: false,
    is_railroad_crossing: false,
    is_old_road: false,
    is_billboard_tower_etc: false,
    is_street_lights_etc: false,
    is_bridge: false,
    is_unpaved_road: false,
    is_garbage_dump: false,
    is_road_signs: false,
    is_overpass: false,
    is_unmaintained_etc: false,
    is_other: false,
    other_long_text: "",
    hand_drawn_layout: [],
    hand_drawn_layout_total: 0,
    hand_drawn_layout_total_tsubo: 0,
    layout_file_path: "",
    layout_file_user: "",
    placement_file_path: "",
    placement_file_user: "",
    designer_placement_file_path: "",
    designer_placement_file_user: "",
};

export type DrawingBooleanTypeList = {
    is_green_gem: boolean;
    is_grave: boolean;
    is_median_strip: boolean;
    is_bus_stop_blackout: boolean;
    is_in_green_gem: boolean;
    is_other_tenant_parking: boolean;
    is_expressway_etc_pole: boolean;
    is_bus_lane_etc: boolean;
    is_electricity_pole: boolean;
    is_sidewalk: boolean;
    is_one_way_street: boolean;
    is_tram_track: boolean;
    is_electricity_pole_branch_line: boolean;
    is_crosswalk: boolean;
    is_no_entry_road: boolean;
    is_parking_meter: boolean;
    is_high_voltage_towers: boolean;
    is_guardrail: boolean;
    is_waterway_etc: boolean;
    is_others_place: boolean;
    is_hydrant_etc: boolean;
    is_street_trees_etc: boolean;
    is_railroad_crossing: boolean;
    is_old_road: boolean;
    is_billboard_tower_etc: boolean;
    is_street_lights_etc: boolean;
    is_bridge: boolean;
    is_unpaved_road: boolean;
    is_garbage_dump: boolean;
    is_road_signs: boolean;
    is_overpass: boolean;
    is_unmaintained_etc: boolean;
    is_other: boolean;
    other_long_text: boolean;
    handDrawnLayoutFormula: boolean;
    handDrawnLayoutSubTotal: boolean;
    hand_drawn_layout_total: boolean;
    hand_drawn_layout_total_tsubo: boolean;
    ObstaclesAndHindrancesComingToStore: boolean;
    hand_drawn_layout: boolean;
    [drawingBooleanKey: string]: boolean; //string型でキーを指定
};

//図面ページの項目値を編集可能にするステータスの初期値 trueが入力不可、falseが入力可
export const initDrawingReadOnlyObj: DrawingBooleanTypeList = {
    is_green_gem: true,
    is_grave: true,
    is_median_strip: true,
    is_bus_stop_blackout: true,
    is_in_green_gem: true,
    is_other_tenant_parking: true,
    is_expressway_etc_pole: true,
    is_bus_lane_etc: true,
    is_electricity_pole: true,
    is_sidewalk: true,
    is_one_way_street: true,
    is_tram_track: true,
    is_electricity_pole_branch_line: true,
    is_crosswalk: true,
    is_no_entry_road: true,
    is_parking_meter: true,
    is_high_voltage_towers: true,
    is_guardrail: true,
    is_waterway_etc: true,
    is_others_place: true,
    is_hydrant_etc: true,
    is_street_trees_etc: true,
    is_railroad_crossing: true,
    is_old_road: true,
    is_billboard_tower_etc: true,
    is_street_lights_etc: true,
    is_bridge: true,
    is_unpaved_road: true,
    is_garbage_dump: true,
    is_road_signs: true,
    is_overpass: true,
    is_unmaintained_etc: true,
    is_other: true,
    other_long_text: true,
    handDrawnLayoutFormula: true,
    handDrawnLayoutSubTotal: true,
    hand_drawn_layout_total: true,
    hand_drawn_layout_total_tsubo: true,
    ObstaclesAndHindrancesComingToStore: true,
    hand_drawn_layout: true,
};

export const initDrawingShowIconsObj: DrawingBooleanTypeList = {
    is_green_gem: false,
    is_grave: false,
    is_median_strip: false,
    is_bus_stop_blackout: false,
    is_in_green_gem: false,
    is_other_tenant_parking: false,
    is_expressway_etc_pole: false,
    is_bus_lane_etc: false,
    is_electricity_pole: false,
    is_sidewalk: false,
    is_one_way_street: false,
    is_tram_track: false,
    is_electricity_pole_branch_line: false,
    is_crosswalk: false,
    is_no_entry_road: false,
    is_parking_meter: false,
    is_high_voltage_towers: false,
    is_guardrail: false,
    is_waterway_etc: false,
    is_others_place: false,
    is_hydrant_etc: false,
    is_street_trees_etc: false,
    is_railroad_crossing: false,
    is_old_road: false,
    is_billboard_tower_etc: false,
    is_street_lights_etc: false,
    is_bridge: false,
    is_unpaved_road: false,
    is_garbage_dump: false,
    is_road_signs: false,
    is_overpass: false,
    is_unmaintained_etc: false,
    is_other: false,
    other_long_text: false,
    handDrawnLayoutFormula: false,
    handDrawnLayoutSubTotal: false,
    hand_drawn_layout_total: false,
    hand_drawn_layout_total_tsubo: false,
    ObstaclesAndHindrancesComingToStore: false,
    hand_drawn_layout: false,
};

//図面ページの鉛筆アイコンの表示非表示のステータスの初期値 trueが表示、falseが非表示
export const initDrawingShowEditIconObj: DrawingBooleanTypeList = {
    is_green_gem: true,
    is_grave: true,
    is_median_strip: true,
    is_bus_stop_blackout: true,
    is_in_green_gem: true,
    is_other_tenant_parking: true,
    is_expressway_etc_pole: true,
    is_bus_lane_etc: true,
    is_electricity_pole: true,
    is_sidewalk: true,
    is_one_way_street: true,
    is_tram_track: true,
    is_electricity_pole_branch_line: true,
    is_crosswalk: true,
    is_no_entry_road: true,
    is_parking_meter: true,
    is_high_voltage_towers: true,
    is_guardrail: true,
    is_waterway_etc: true,
    is_others_place: true,
    is_hydrant_etc: true,
    is_street_trees_etc: true,
    is_railroad_crossing: true,
    is_old_road: true,
    is_billboard_tower_etc: true,
    is_street_lights_etc: true,
    is_bridge: true,
    is_unpaved_road: true,
    is_garbage_dump: true,
    is_road_signs: true,
    is_overpass: true,
    is_unmaintained_etc: true,
    is_other: true,
    other_long_text: true,
    handDrawnLayoutFormula: true,
    handDrawnLayoutSubTotal: true,
    hand_drawn_layout_total: true,
    hand_drawn_layout_total_tsubo: true,
    ObstaclesAndHindrancesComingToStore: true,
    hand_drawn_layout: true,
};

//図面ページのstring型の型定義
export type DrawingStringTypeList = {
    ObstaclesAndHindrancesComingToStore: string;
    other_long_text: string;
    hand_drawn_layout: string;
    drawing_upload: string;
    [coverStringKey: string]: string; //string型でキーを指定するため
};

//図面ページのモーダル内のテキストフィールドの初期値
export const initDrawingCommentTextFieldObj: DrawingStringTypeList = {
    ObstaclesAndHindrancesComingToStore: "",
    other_long_text: "",
    hand_drawn_layout: "",
    drawing_upload: "",
};

// 不備コメント一覧のテーブル 型定義
export type DeficiencyCommentList = {
    id: number;
    flg: string;
    createDate: string; //日付
    tableName: string; //ページ
    columnName: string; //項目
    comment: string; //コメント
    initComment: string; //コメント初期値 キャンセルで戻すため
    deficiencyStatus: string; //不備ステータス
    editStatus: boolean; //編集の可否 trueが編集不可 falseが編集可
};

// 不備コメント一覧のテーブル
export const initDeficiencyCommentList: DeficiencyCommentList[] = [];

export type locationTraffic5mcutTypeList = {
    plan_area_id: string;
    branch: string;
    create_date: string;
    update_date: string;
    delete_flag: string;
    excel_path: string;
    layout_img_path: string;
    photo_img_path: string;
    check_planting: string;
    check_visibility: string;
    check_stairs: string;
    check_count: string;
    car_another_survey: string;
    people_another_survey: string;
    cut_survey: string;
    others_facility_1: string;
    others_name_1: string;
    others_distance_1: string;
    others_value_1: string;
    others_facility_2: string;
    others_name_2: string;
    others_distance_2: string;
    others_value_2: string;
    others_facility_3: string;
    others_name_3: string;
    others_distance_3: string;
    others_value_3: string;
    others_facility_4: string;
    others_name_4: string;
    others_distance_4: string;
    others_value_4: string;
    approval_confirm_flag: string;
    approval_confirm_user: string;
    approval_confirm_department: string;
    approval_confirm_date: string;
    approval_confirm_stamp: string;
    approval_research_mgr_flag: string;
    approval_research_mgr_user: string;
    approval_research_mgr_department: string;
    approval_research_mgr_date: string;
    approval_research_mgr_stamp: string;
    approval_research_gm_flag: string;
    approval_research_gm_user: string;
    approval_research_gm_department: string;
    approval_research_gm_date: string;
    approval_research_gm_stamp: string;
    approval_mgr_flag: string;
    approval_mgr_user: string;
    approval_mgr_department: string;
    approval_mgr_date: string;
    approval_mgr_stamp: string;
    approval_gm_flag: string;
    approval_gm_user: string;
    approval_gm_department: string;
    approval_gm_date: string;
    approval_gm_stamp: string;
};

export const locationTraffic5mcutObj: locationTraffic5mcutTypeList = {
    plan_area_id: "",
    branch: "",
    create_date: "",
    update_date: "",
    delete_flag: "",
    excel_path: "",
    layout_img_path: "",
    photo_img_path: "",
    check_planting: "0",
    check_visibility: "0",
    check_stairs: "0",
    check_count: "0",
    car_another_survey: "0",
    people_another_survey: "0",
    cut_survey: "0",
    others_facility_1: "0",
    others_name_1: "",
    others_distance_1: "",
    others_value_1: "",
    others_facility_2: "1",
    others_name_2: "",
    others_distance_2: "",
    others_value_2: "",
    others_facility_3: "",
    others_name_3: "",
    others_distance_3: "",
    others_value_3: "",
    others_facility_4: "",
    others_name_4: "",
    others_distance_4: "",
    others_value_4: "",
    approval_confirm_flag: "",
    approval_confirm_user: "",
    approval_confirm_department: "",
    approval_confirm_date: "",
    approval_confirm_stamp: "",
    approval_research_mgr_flag: "",
    approval_research_mgr_user: "",
    approval_research_mgr_department: "",
    approval_research_mgr_date: "",
    approval_research_mgr_stamp: "",
    approval_research_gm_flag: "",
    approval_research_gm_user: "",
    approval_research_gm_department: "",
    approval_research_gm_date: "",
    approval_research_gm_stamp: "",
    approval_mgr_flag: "",
    approval_mgr_user: "",
    approval_mgr_department: "",
    approval_mgr_date: "",
    approval_mgr_stamp: "",
    approval_gm_flag: "",
    approval_gm_user: "",
    approval_gm_department: "",
    approval_gm_date: "",
    approval_gm_stamp: "",
};

//通行量 その他 駅出入口の行のテーブルデータ取得型定義
export type locationTrafficStationTypeList = {
    station_entrance_name: string; //駅出入口の行 名称
    road_distance: string; //駅出入口の行 距離
};

//通行量 その他 駅出入口の行のテーブル データ初期値
export const locationTrafficStationObj: locationTrafficStationTypeList = {
    station_entrance_name: "", //駅出入口の行 名称
    road_distance: "", //駅出入口の行 距離
};

//通行量 その他 大学短大の行のテーブル データ取得型定義
export type locationTrafficSchoolTypeList = {
    display_name: string; //大学短大の行 名称
    trade_area_value: string; //大学短大の行 距離
};

//通行量 その他 大学短大の行のテーブル データ初期値
export const locationTrafficSchoolObj: locationTrafficSchoolTypeList = {
    display_name: "", //大学短大の行 名称
    trade_area_value: "", //大学短大の行 距離
};

//通行量 その他 入力可能項目 型定義
export type locationTraffic5mcutTextTypeList = {
    others_value_1: string;
    others_value_2: string;
    others_facility_3: string;
    others_name_3: string;
    others_distance_3: string;
    others_value_3: string;
    others_facility_4: string;
    others_name_4: string;
    others_distance_4: string;
    others_value_4: string;
};

//通行量 その他 入力可能項目 初期値
export const locationTraffic5mcutTextObj: locationTraffic5mcutTextTypeList = {
    others_value_1: "",
    others_value_2: "",
    others_facility_3: "",
    others_name_3: "",
    others_distance_3: "",
    others_value_3: "",
    others_facility_4: "",
    others_name_4: "",
    others_distance_4: "",
    others_value_4: "",
};

//通行量 その他 string型の型定義
export type LocationTrafficOthersStringTypeList = {
    others_traffic_volume: string;
    [coverStringKey: string]: string; //string型でキーを指定するため
};

//通行量　その他のモーダル内のテキストフィールドの初期値
export const initLocationTrafficOthersCommentTextFieldObj: LocationTrafficOthersStringTypeList =
    {
        others_traffic_volume: "",
    };

//通行量　その他のboolean型の定義
export type LocationTrafficOthersBooleanTypeList = {
    others_traffic_volume: boolean;
};

//表紙ページの項目値を編集可能にするステータスの初期値 trueが入力不可、falseが入力可
export const initLocationTrafficOthersReadOnlyObj: LocationTrafficOthersBooleanTypeList =
    {
        others_traffic_volume: true,
    };

export type CodeMasterTypeList = {
    uuid: string;
    create_date: string;
    update_date: string;
    delete_flag: string;
    common_item_flag: string;
    item_name_display: string;
    item_name_value: string;
    code_display: string;
    code_value: string;
    sort_column: string;
};

export const initCodeMasterObj: CodeMasterTypeList[] = [];

export type pdfDataList = {
    fileName: string;
    uploadDate: string;
    uploader: string;
    pdfPath: string;
    previewPdfPath: string;
};

export const initPdfData: pdfDataList = {
    fileName: "",
    uploadDate: "",
    uploader: "",
    pdfPath: "",
    previewPdfPath: "",
};

export type locationAddSurveyTypeList = {
    planAreaId: string;
    branch: string;
    create_date: string;
    update_date: string;
    delete_flag: string;
    cover_excel_path: string;
    cover_excel_user: string;
    cover_pdf_path: string;
    cover_pdf_user: string;
    doc_pdf_path: any[];
    reference_value_1: string;
    reference_value_2: string;
    reference_value_3: string;
    reference_value_4: string;
    reference_value_5: string;
    approval_mgr_flag: string;
    approval_mgr_user: string;
    approval_mgr_department: string;
    approval_mgr_date: string;
    approval_mgr_stamp: string;
    approval_gm_flag: string;
    approval_gm_user: string;
    approval_gm_department: string;
    approval_gm_date: string;
    approval_gm_stamp: string;
    approval_research_mgr_flag: string;
    approval_research_mgr_user: string;
    approval_research_mgr_department: string;
    approval_research_mgr_date: string;
    approval_research_mgr_stamp: string;
    approval_research_gm_flag: string;
    approval_research_gm_user: string;
    approval_research_gm_department: string;
    approval_research_gm_date: string;
    approval_research_gm_stamp: string;
};

export const initLocationAddSurveyObj: locationAddSurveyTypeList = {
    planAreaId: "",
    branch: "",
    create_date: "",
    update_date: "",
    delete_flag: "",
    cover_excel_path: "",
    cover_excel_user: "",
    cover_pdf_path: "",
    cover_pdf_user: "",
    doc_pdf_path: [],
    reference_value_1: "",
    reference_value_2: "",
    reference_value_3: "",
    reference_value_4: "",
    reference_value_5: "",
    approval_mgr_flag: "",
    approval_mgr_user: "",
    approval_mgr_department: "",
    approval_mgr_date: "",
    approval_mgr_stamp: "",
    approval_gm_flag: "",
    approval_gm_user: "",
    approval_gm_department: "",
    approval_gm_date: "",
    approval_gm_stamp: "",
    approval_research_mgr_flag: "",
    approval_research_mgr_user: "",
    approval_research_mgr_department: "",
    approval_research_mgr_date: "",
    approval_research_mgr_stamp: "",
    approval_research_gm_flag: "",
    approval_research_gm_user: "",
    approval_research_gm_department: "",
    approval_research_gm_date: "",
    approval_research_gm_stamp: "",
};

export type editParamsList = {
    selectTab: string;
};

export const initEditParams: editParamsList = {
    selectTab: "",
};

//表紙ページ項目名の型定義
export type Approval5mcutTypeList = {
    fiveMeters: string;
    marketCut: string;
    confirm: string;
};

//表紙ページ項目名の初期値
export const initApproval5mcutObj: Approval5mcutTypeList = {
    fiveMeters: "",
    marketCut: "",
    confirm: "",
};

//追加調査ページ 不備コメント用 string型の型定義
export type additionalResearchStringTypeList = {
    add_survey_cover: string;
    add_survey_document: string;
    other_document_excel: string;
    other_document_pdf: string;
    [additionalResearchString: string]: string; //string型でキーを指定するため
};

//追加調査ページ 不備コメント用 string型の型定義
export const initAdditionalResearchCommentTextFieldObj: additionalResearchStringTypeList =
    {
        add_survey_cover: "",
        add_survey_document: "",
        other_document_excel: "",
        other_document_pdf: "",
    };

//地図ページ 不備コメント用 string型の型定義
export type mapStringTypeList = {
    map_upload: string;
    [mapString: string]: string; //string型でキーを指定するため
};

//地図ページ 不備コメント用 string型の型定義
export const initMapCommentTextFieldObj: mapStringTypeList = {
    map_upload: "",
};

//影響度ページのboolean型の定義
export type LocationImpactBooleanTypeList = {
    store_position: boolean;
    common_road: boolean;
    plan_median_strip: boolean;
    store_median_strip: boolean;
    straight_count: boolean;
    turn_count: boolean;
    railroad_crossing: boolean;
    river_width: boolean;
    crossing_road_count: boolean;
    conflict_sej: boolean;
    conflict_main_cvs: boolean;
    conflict_other_cvs: boolean;
    near_store_code: boolean;
    average_common_ratio: boolean;
    road_distance: boolean;
    salesfloor: boolean;
    parkingarea: boolean;
    car_front_store_count: boolean;
    car_total_share_ratio: boolean;
    people_front_store_count: boolean;
    people_total_share_ratio: boolean;
    tobaccolicense: boolean;
    [locationImpactBooleanKey: string]: boolean; //string型でキーを指定するため
};

//影響度ページのアイコン全体の表示非表示のステータスの初期値
export const initLocationImpactShowIconObj: LocationImpactBooleanTypeList = {
    store_position: false,
    common_road: false,
    plan_median_strip: false,
    store_median_strip: false,
    straight_count: false,
    turn_count: false,
    railroad_crossing: false,
    river_width: false,
    crossing_road_count: false,
    conflict_sej: false,
    conflict_main_cvs: false,
    conflict_other_cvs: false,
    near_store_code: false,
    average_common_ratio: false,
    road_distance: false,
    salesfloor: false,
    parkingarea: false,
    car_front_store_count: false,
    car_total_share_ratio: false,
    people_front_store_count: false,
    people_total_share_ratio: false,
    tobaccolicense: false,
};

//影響度ページの鉛筆アイコンの表示非表示のステータスの初期値 trueが表示、falseが非表示
export const initLocationImpactShowEditIconObj: LocationImpactBooleanTypeList =
    {
        store_position: true,
        common_road: true,
        plan_median_strip: true,
        store_median_strip: true,
        straight_count: true,
        turn_count: true,
        railroad_crossing: true,
        river_width: true,
        crossing_road_count: true,
        conflict_sej: true,
        conflict_main_cvs: true,
        conflict_other_cvs: true,
        near_store_code: true,
        average_common_ratio: true,
        road_distance: true,
        salesfloor: true,
        parkingarea: true,
        car_front_store_count: true,
        car_total_share_ratio: true,
        people_front_store_count: true,
        people_total_share_ratio: true,
        tobaccolicense: true,
    };

//影響度ページの項目値を編集可能にするステータスの初期値 trueが入力不可、falseが入力可
export const initLocationImpactReadOnlyObj: LocationImpactBooleanTypeList = {
    store_position: true,
    common_road: true,
    plan_median_strip: true,
    store_median_strip: true,
    straight_count: true,
    turn_count: true,
    railroad_crossing: true,
    river_width: true,
    crossing_road_count: true,
    conflict_sej: true,
    conflict_main_cvs: true,
    conflict_other_cvs: true,
    near_store_code: true,
    average_common_ratio: true,
    road_distance: true,
    salesfloor: true,
    parkingarea: true,
    car_front_store_count: true,
    car_total_share_ratio: true,
    people_front_store_count: true,
    people_total_share_ratio: true,
    tobaccolicense: true,
};

//影響度ページのstring型の型定義
export type LocationImpactStringTypeList = {
    store_position: string;
    common_road: string;
    plan_median_strip: string;
    store_median_strip: string;
    straight_count: string;
    turn_count: string;
    railroad_crossing: string;
    river_width: string;
    crossing_road_count: string;
    conflict_sej: string;
    conflict_main_cvs: string;
    conflict_other_cvs: string;
    near_store_code: string;
    average_common_ratio: string;
    road_distance: string;
    salesfloor: string;
    parkingarea: string;
    car_front_store_count: string;
    car_total_share_ratio: string;
    people_front_store_count: string;
    people_total_share_ratio: string;
    [locationImpactStringKey: string]: string; //string型でキーを指定するため
};

//影響度ページのモーダル内のテキストフィールドの初期値
export const initLocationImpactCommentTextFieldObj: LocationImpactStringTypeList =
    {
        store_position: "",
        common_road: "",
        plan_median_strip: "",
        store_median_strip: "",
        straight_count: "",
        turn_count: "",
        railroad_crossing: "",
        river_width: "",
        crossing_road_count: "",
        conflict_sej: "",
        conflict_main_cvs: "",
        conflict_other_cvs: "",
        near_store_code: "",
        average_common_ratio: "",
        road_distance: "",
        salesfloor: "",
        parkingarea: "",
        car_front_store_count: "",
        car_total_share_ratio: "",
        people_front_store_count: "",
        people_total_share_ratio: "",
    };

//表紙ページ項目名の型定義
export type ApprovalSurveyTypeList = {
    additionalResearch: string;
};

//表紙ページ項目名の初期値
export const initApprovalSurveyObj: ApprovalSurveyTypeList = {
    additionalResearch: "",
};

export type locationSalesForecastTypeList = {
    planAreaId: string;
    branch: string;
    create_date: string;
    update_date: string;
    delete_flag: string;
    temp_flag: string;
    sales_forecast_excel_path: string;
    sales_forecast_pdf_path: string;
    sales_forecast_upload_user: string;
    sales_forecast_upload_mail: string;
    sales_forecast_color: string;
    daily_sales_year_1: string;
    daily_sales_year_2: string;
    daily_sales_year_3: string;
    daily_sales_year_4: string;
    daily_sales_year_5: string;
    impact_store_name_1: string;
    impact_store_1: string;
    impact_store_name_2: string;
    impact_store_2: string;
    impact_store_name_3: string;
    impact_store_3: string;
    impact_store_name_4: string;
    impact_store_4: string;
    approval_research_output_flag: string;
    approval_research_output_user: string;
    approval_research_output_department: string;
    approval_research_output_date: string;
    approval_research_output_stamp: string;
    approval_research_mgr_flag: string;
    approval_research_mgr_user: string;
    approval_research_mgr_department: string;
    approval_research_mgr_date: string;
    approval_research_mgr_stamp: string;
    approval_research_gm_flag: string;
    approval_research_gm_user: string;
    approval_research_gm_department: string;
    approval_research_gm_date: string;
    approval_research_gm_stamp: string;
};

export const locationSalesForecastObj: locationSalesForecastTypeList = {
    planAreaId: "",
    branch: "",
    create_date: "",
    update_date: "",
    delete_flag: "",
    temp_flag: "",
    sales_forecast_excel_path: "",
    sales_forecast_pdf_path: "",
    sales_forecast_upload_user: "",
    sales_forecast_upload_mail: "",
    sales_forecast_color: "",
    daily_sales_year_1: "",
    daily_sales_year_2: "",
    daily_sales_year_3: "",
    daily_sales_year_4: "",
    daily_sales_year_5: "",
    impact_store_name_1: "",
    impact_store_1: "",
    impact_store_name_2: "",
    impact_store_2: "",
    impact_store_name_3: "",
    impact_store_3: "",
    impact_store_name_4: "",
    impact_store_4: "",
    approval_research_output_flag: "",
    approval_research_output_user: "",
    approval_research_output_department: "",
    approval_research_output_date: "",
    approval_research_output_stamp: "",
    approval_research_mgr_flag: "",
    approval_research_mgr_user: "",
    approval_research_mgr_department: "",
    approval_research_mgr_date: "",
    approval_research_mgr_stamp: "",
    approval_research_gm_flag: "",
    approval_research_gm_user: "",
    approval_research_gm_department: "",
    approval_research_gm_date: "",
    approval_research_gm_stamp: "",
};

export type locationTrafficTypeList = {
    plan_area_id: string;
    branch: string;
    traffic_type: string;
    traffic_type_seq: string;
    create_date: string;
    update_date: string;
    delete_flag: string;
    counter_uuid: string;
    count_date: string;
    count_time: string;
    front_traffic_jam_time: string;
    side_a_traffic_jam_time: string;
    count_type: string;
    car_front_standard: number;
    car_front_light: number;
    car_front_large: number;
    car_side_a_standard: number;
    car_side_a_light: number;
    car_side_a_large: number;
    car_side_b_standard: number;
    car_side_b_light: number;
    car_side_b_large: number;
    car_back_standard: number;
    car_back_light: number;
    car_back_large: number;
    people_5m_in_male_65over: number;
    people_5m_in_female_65over: number;
    people_5m_in_male_other: number;
    people_5m_in_female_other: number;
    people_5m_in_student: number;
    people_5m_in_bicycle: number;
    people_5m_out_male_65over: number;
    people_5m_out_female_65over: number;
    people_5m_out_male_other: number;
    people_5m_out_female_other: number;
    people_5m_out_student: number;
    people_5m_out_bicycle: number;
    car_front_total: number;
    car_side_a_total: number;
    car_side_b_total: number;
    car_back_total: number;
};

export const locationTrafficObj: locationTrafficTypeList = {
    plan_area_id: "",
    branch: "",
    traffic_type: "",
    traffic_type_seq: "",
    create_date: "",
    update_date: "",
    delete_flag: "",
    counter_uuid: "",
    count_date: "",
    count_time: "",
    front_traffic_jam_time: "",
    side_a_traffic_jam_time: "",
    count_type: "",
    car_front_standard: 0,
    car_front_light: 0,
    car_front_large: 0,
    car_side_a_standard: 0,
    car_side_a_light: 0,
    car_side_a_large: 0,
    car_side_b_standard: 0,
    car_side_b_light: 0,
    car_side_b_large: 0,
    car_back_standard: 0,
    car_back_light: 0,
    car_back_large: 0,
    people_5m_in_male_65over: 0,
    people_5m_in_female_65over: 0,
    people_5m_in_male_other: 0,
    people_5m_in_female_other: 0,
    people_5m_in_student: 0,
    people_5m_in_bicycle: 0,
    people_5m_out_male_65over: 0,
    people_5m_out_female_65over: 0,
    people_5m_out_male_other: 0,
    people_5m_out_female_other: 0,
    people_5m_out_student: 0,
    people_5m_out_bicycle: 0,
    car_front_total: 0,
    car_side_a_total: 0,
    car_side_b_total: 0,
    car_back_total: 0,
};

export type AddImpactObjTypeList = {
    plan_area_id: string;
    branch: string;
    store_code: string;
    create_date: string;
    update_date: string;
    delete_flag: string;
    store_position: string;
    common_road: string;
    plan_median_strip: string;
    store_median_strip: string;
    straight_count: string;
    turn_count: string;
    railroad_crossing: string;
    river_width: string;
    crossing_road_count: string;
    conflict_sej: string;
    conflict_main_cvs: string;
    conflict_other_cvs: string;
    near_store_code: string;
    average_common_ratio: string;
    road_distance: string;
    salesfloor: string;
    parkingarea: string;
    car_front_store_count: string;
    car_total_share_ratio: string;
    people_front_store_count: string;
    people_total_share_ratio: string;
    type: string;
    tobaccolicense: string;
};

export const initAddImpactObj: AddImpactObjTypeList = {
    plan_area_id: "",
    branch: "",
    store_code: "",
    create_date: "",
    update_date: "",
    delete_flag: "",
    store_position: "",
    common_road: "",
    plan_median_strip: "",
    store_median_strip: "",
    straight_count: "",
    turn_count: "",
    railroad_crossing: "",
    river_width: "",
    crossing_road_count: "",
    conflict_sej: "",
    conflict_main_cvs: "",
    conflict_other_cvs: "",
    near_store_code: "",
    average_common_ratio: "",
    road_distance: "",
    salesfloor: "",
    parkingarea: "",
    car_front_store_count: "",
    car_total_share_ratio: "",
    people_front_store_count: "",
    people_total_share_ratio: "",
    type: "",
    tobaccolicense: "",
};

export type TrafficStringTypeList = {
    plan_area_id: string;
    branch: string;
    traffic_type: string;
    traffic_type_seq: string;
    create_date: string;
    update_date: string;
    delete_flag: string;
    counter_uuid: string;
    count_date: string;
    count_time: string;
    front_traffic_jam_time: string;
    side_a_traffic_jam_time: string;
    count_type: string;
    car_front_standard: string;
    car_front_light: string;
    car_front_large: string;
    car_side_a_standard: string;
    car_side_a_light: string;
    car_side_a_large: string;
    car_side_b_standard: string;
    car_side_b_light: string;
    car_side_b_large: string;
    car_back_standard: string;
    car_back_light: string;
    car_back_large: string;
    people_5m_in_male_65over: string;
    people_5m_in_female_65over: string;
    people_5m_in_male_other: string;
    people_5m_in_female_other: string;
    people_5m_in_student: string;
    people_5m_in_bicycle: string;
    people_5m_out_male_65over: string;
    people_5m_out_female_65over: string;
    people_5m_out_male_other: string;
    people_5m_out_female_other: string;
    people_5m_out_student: string;
    people_5m_out_bicycle: string;
    car_front_total: string;
    car_side_a_total: string;
    car_side_b_total: string;
    car_back_total: string;
};

export const initTrafficList: TrafficStringTypeList[] = [];

export type trafficDeleteParamTypeList = {
    mode: string;
    endPoint: string;
    query: {};
};

export const initTrafficDeleteParamList: trafficDeleteParamTypeList[] = [];

export type traffic5cutImagesList = {
    layout_img_path: string;
    photo_img_path: string;
    approvalResearchMgrStamp: string;
    approvalResearchGmStamp: string;
    approvalMgrStamp: string;
    approvalGmStamp: string;
};

export const initTraffic5cutImages: traffic5cutImagesList = {
    layout_img_path: "",
    photo_img_path: "",
    approvalResearchMgrStamp: "",
    approvalResearchGmStamp: "",
    approvalMgrStamp: "",
    approvalGmStamp: "",
};
