export const LOCAL_CONSTANT = {
    NAVI: {
        CIRCULAR_HISTORY: "回覧履歴",
        ITEM_CHANGE_HISTORY: "項目変更履歴",
        LONG_PAGE: "ロングページ",
        SALES_FORECAST_SUMMARY_MAIN_ELEMENT_LIST: "予測集計表等",
        ADDITIONAL_RESEARCH: "追加調査",
        OTHER_DOCUMENT: "その他資料",
        RR_INTERNAL_USE: "RR内部用",
        COVER: "表紙",
        STORE_OPENING_CONDITIONS: "出店条件",
        TRADE_AREA: "商圏",
        PICTURE: "現況写真",
        DONATION: "寄付き",
        TRAFFIC_VOLUME: "通行量",
        CONFLICT: "競合",
        IMPACT: "影響度",
        MAP: "地図",
        DRAWING: "図面",
        EVALUATE_CHANGE_HISTORY: "評価変更履歴",
        LONGPAGE: "ロングページ",
        TRADE_AREA_CUT: "商圏カット根拠",
        MARKET_CUT: "商圏カット",
        TRAFFIC_5MCUT: "通行量5m外",
    },
    LABEL: {
        FILE_NAME: "ファイル名",
        UPLOAD: "アップロード",
        UPLOAD_DATE: "アップロード日",
        UPLOADER: "アップロード者",
        YES: "有",
        NOTHING: "無",
        YES_KANA: "ある",
        NOTHING_KANA: "ない",
        MONTHS: "ヶ月",
        BEFORE_MODIFICATION: "改造前",
        AFTER_MODIFICATION: "改造後",
        INFORMATION_BEFORE_TYPE_CHANGE: "タイプ変更前情報",
        INFORMATION_AFTER_TYPE_CHANGE: "タイプ変更後情報",
        R_VIEW: "Rビュー",
        PDF: "PDF",
        PDF_LIST: "PDF一覧",
        PDF_CREATION: "PDF作成",
        SURVEY_REPORT_PDF_CREATION: "調査書PDF作成",
        PDF_OUTPUT: "PDF出力",
        CSV_OUTPUT: "CSV出力",
        SAVE: "保存",
        SEARCH: "検索",
        REQUEST_DATE: "依頼日時",
        FLAG: "フラグ",
        ADD: "追加",
        CANCEL: "キャンセル",
        UPDATE: "更新",
        DELETE: "削除",
        CHANGE_HISTORY: "変更履歴",
        STATUS_CHANGE_DATE_AND_TIME: "ステータス変更日時",
        ID: "id",
        DEPARTMENT: "部",
        DISTRICT: "地区",
        RFC: "RFC",
        STEP: "STEP",
        BRANCH: "枝",
        IN_CHARGE: "担当",
        MANAGER: "担当者",
        TASK: "タスク",
        GREEN_PAPER: "緑紙",
        BLANK_PAPER: "白紙",
        GREEN_PAPER_BLANK_PAPER: "緑紙/白紙",
        COMMENT: "コメント",
        COMMENT_YN: "コメント",
        COMMENT_TYPE: "コメント種別",
        LIST_OF_INCOMPLETE_COMMENTS: "不備コメント一覧",
        REQUEST: "依頼",
        REQUEST_COMMENT: "依頼/コメント",
        LIST_OF_DEFICIENCIES: "不備一覧",
        FROM_TO: "from / to",
        PLAN_AREA_NO: "候補地No",
        PLAN_AREA_NO_2: "候補地番号",
        PLAN_AREA_NAME: "候補地名",
        CONFLICT_RESEARCH: "競合調査",
        CONFLICT_SETTING: "競合CVS設定",
        PLAN_AREA_LOCATION_SURVEY: "候補店立地調査",
        CREATE_DATE: "作成日",
        RECEPTION_NO: "受付NO",
        STORE_CODE: "店NO",
        NAME: "名称",
        OLD_STORE_NAME: "旧屋号",
        RESIDENCE: "所在地",
        SCHEDULED_CONTRACT_DATE: "契約予定日",
        SCHEDULED_OPENING_DATE: "開店予定日",
        PREFECTURE_CODE: "都道府県コード",
        NEW_AREA_NO: "新エリアNo",
        SURVEY_REPORT_CREATOR: "調査書作成者",
        CONFIRMER: "確認者",
        NEW_FLAG: "新規フラグ",
        DATE: "日付",
        PAGE: "ページ",
        ITEM: "項目",
        FRONT: "前面",
        SIDE: "側道",
        SIDE_A: "側道A",
        SIDE_B: "側道B",
        BACK: "裏道",
        SHORT_SIDE_A: "側A",
        SHORT_SIDE_B: "側B",
        LANE: "車線",
        METER: "m",
        SQUARE_METER: "㎡",
        TIMES: "回",
        CREATOR: "作成者",
        ASSEMBLY: "組立て",
        PATTERN: "パターン",
        PATTERN_DETAILS: "パターン詳細",
        REQUEST_TYPE: "依頼種別",
        STATUS: "ステータス",
        CHANGE: "変更",
        APPLICABLE_PAGE: "該当ページ",
        ITEM_NAME: "項目名",
        CHANGE_BEFORE: "変更前",
        AFTER_CHANGE: "変更後",
        RESEARCH_COMMENTS: "リサーチコメント",
        RFC_COMMENTS: "RFCコメント",
        DATE_OF_CHANGE: "変更年月日",
        PROGRESS: "進行状況",
        BEFORE_CONTRACT: "契約前",
        AFTER_CONTRACT: "契約後",
        BEFORE_OPENING: "開店前",
        AFTER_OPENING: "開店後",
        MODIFIER: "変更者",
        INCREASE_INPUT_FIELDS: "入力欄を増やす",
        TARGET_PLAN_AREA: "対象候補地",
        TARGET_EXISTING_STORES: "対象既存店",
        REGIST_LABEL: "登録",
        CAR: "車",
        PEOPLE: "人",
        ROUTE: "ルート",
        DRAW_ROUTE: "ルート描画",
        ROUTE_CHANGE: "ルート変更",
        EDIT_ROUTE: "ルート編集",
        LOG_CONFIRM: "ログ確認",
        CONFIRM: "確認",
        CONFIRMED: "確定",
        INTERSECTION: "交差点",
        CANCELLATION: "解除",
        OK_SIGN: "○",
        BAD_SIGN: "×",
        STORE_LABEL: "店",
        TOP: "上",
        LEFT: "左",
        RIGHT: "右",
        BOTTOM: "下",
        TYPE: "種別",
        INTERSECTION_NAME: "交差点名",
        MEASUREMENT_DATE_AND_TIME: "測定日時",
        NOT_RELEVANT: "対象外",
        COMMON: "共通",
        UNCOMMON: "非共通",
        COMMON_RATIO: "共通比",
        STORE_NO: "店番",
        STORE_NAME: "店名",
        COMPETITIVE_TARGET: "競合対象",
        FIVE_MINUTES_WALK_WITHIN_COMMERCIAL_AREA: "徒歩5分商圏内外",
        CHAIN_NAME: "チェーン名",
        PREFECTURES: "都道府県",
        ADDRESS: "住所",
        BUSINESS_TYPE: "業態",
        BUSINESS_TYPE_NAME: "業態タイプ",
        BUSINESS_HOURS: "営業時間",
        CLOSING_TIME: "閉店時間",
        LIQUOR_LICENSE: "酒免許",
        LOCATION: "立地",
        TOBACCO_LICENSE: "煙草免許",
        LIQUOR: "酒",
        TOBACCO: "煙草",
        SALES_FLOOR_AREA: "売場面積",
        GONDOLA_SCALE: "ゴンドラ尺数",
        PARKING_AVAILABLE: "駐車場有無",
        STORE_FRONTAGE: "店舗間口",
        PARKING_AREA: "駐車場面積",
        NUMBER_OF_PARKING_SPACES: "駐車場台数",
        FRONT_PARKING_LOT_TANGENT: "前面駐車場接線",
        FRONT_FIRST_ENTRANCE: "前面第一進入口",
        FRONT_FIRST_DEPTH: "前面第一奥行き",
        FRONT_SECOND_ENTRANCE: "前面第二進入口",
        FRONT_SECOND_DEPTH: "前面第二奥行き",
        PARKING_LOT_TANGENT: "駐車場接線",
        FIRST_ENTRANCE: "第一進入口",
        FIRST_DEPTH: "第一奥行き",
        SECOND_ENTRANCE: "第二進入口",
        SECOND_DEPTH: "第二奥行き",
        TYPE_OF_SIDE_ROAD: "側道有無種類",
        SIDE_OAD_BACK_ROAD_PARKING_LOT_TANGENT: "側道(裏道)駐車場接線",
        SIDE_OAD_BACK_ROAD_FIRST_ENTRANCE: "側道(裏道)第一進入口",
        SIDE_OAD_BACK_ROAD_FIRST_DEPTH: "側道(裏道)第一奥行き",
        SIDE_OAD_SECOND_ENTRANCE: "側道第二進入口",
        SIDE_OAD_SECOND_DEPTH: "側道第二奥行き",
        OAD_BACK_ROAD_PARKING_LOT_TANGENT: "(裏道)駐車場接線",
        OAD_BACK_ROAD_FIRST_ENTRANCE: "(裏道)第一進入口",
        OAD_BACK_ROAD_FIRST_DEPTH: "(裏道)第一奥行き",
        OAD_SECOND_ENTRANCE: "(裏道)第二進入口",
        OAD_SECOND_DEPTH: "(裏道)第二奥行き",
        ESTIMATED_DAILY_SALES: "推定日販",
        COMMON_ROAD_IN_FRONT_OF_CANDIDATE: "候補前共通道路",
        DIRECTION: "方向",
        COMMON_ROAD_BEFORE_COMPETITION: "競合前共通道路",
        PRESENCE_OF_COMMON_ROAD_SEPARATION_STRIP_BEFORE_COMPETITION:
            "競合前共通道路分離帯有無",
        LEFT_RIGHT_TURN_INDICATOR: "右左折計",
        STRAIGHT_GAUGE: "直進計",
        ROAD_DISTANCE: "道なり距離",
        DIRECT_DISTANCE: "直線距離",
        CONFLICT_STORES: "競合店",
        EXISTING_STORES: "既存店",
        FRONT_FOR_EXISTING_STORES: "既存店前",
        SHOOTING_DATE_AND_TIME: "撮影日時",
        FULL_WIDTH_SPACE: "　",
        PHOTO_REGISTRATION: "写真登録",
        PLAN_AREA_CURRENT_PHOTO: "候補地現況写",
        PREDICTION: "予測",
        SURVEY_REPORT_CONFIRMATION: "調査書確認",
        OUTPUT_REQUEST_DATE: "出力依頼日",
        DETAILS: "詳細",
        SUBJECT: "対象",
        NOT_SUBJECT: "対象外",
        CONFLICT_DETERMINATION: "競合判定",
        MEASUREMENT: "計測",
        METRE: "m",
        SQUARE_METRE: "㎡",
        THOUSAND_YEN: "千円",
        BASIC_AND_CONDUCTOR_CONFLICT: "基礎＋導線",
        BASIC_CONFLICT: "基礎競合",
        CONDUCTOR_CONFLICT: "導線競合",
        OWN_STORE: "自店",
        PERCENT: "%",
        RFC_ITEM_CHANGE_HISTORY: "RFC項目変更履歴",
        RR_ITEM_CHANGE_HISTORY: "RR項目変更履歴",
        RR_EVALUATE_CHANGE_HISTORY: "RR評価変更履歴",
        COLON: "：",
        CAR_TRAFFIC: "車通行量",
        PEOPLE_TRAFFIC: "人通行量",
        COUNT_DATE: "調査日",
        COUNT_TIME: "調査時間",
        COUNT_DATE_TIME: "計測日時",
        COUNT_TIME_RANGE: "調査時間範囲",
        COUNT_START_TIME: "調査開始時間",
        TRAFFIC_JAM_TIME: "渋滞閉鎖\n（分）",
        CAR_TOTAL: "総台数",
        STANDARD: "普通",
        LIGHT: "軽",
        LARGE: "大型",
        IN_STANDARD: "（内）\n普通",
        IN_LIGHT: "（内）\n軽",
        IN_LARGE: "（内）\n大型",
        DATE_AND_TIME: "日時",
        TIME: "時間",
        SELECT_COUNT_DATE: "登録時間選択",
        SELECT: "選択",
        MORNING: "朝",
        NOON: "昼",
        NIGHT: "夜",
        HOLIDAYS: "休日",
        MORNING_TR: "朝特定",
        NOON_TR: "昼特定",
        NIGHT_TR: "夜特定",
        AVG: "平均",
        STUDENT: "中高生",
        OVER_SIXTY_FIVE: "65歳以上",
        MALE: "男",
        FEMALE: "女",
        TOTAL: "合計",
        OTHER: "その他",
        BICYCLE: "（内）自転車",
        BICYCLE_LINE_BREAK: "自転車",
        IN_FIVE: "5m内",
        OUT_FIVE: "5m外",
        IN_OUT_FIVE: "5m内外 合算",
        CONFLICT_SM_SETTING: "競合SM設定",
        TEMPLATE_DL: "テンプレートDL",
        EXCEL_DL: "エクセルDL",
        INTERSECTION_CONFIRM: "既存店・交差点確認地図",
        MANUAL_INPUT: "手入力",
        IN: "（内）",
        ESSENTIALLY_COMPETING_STORE: "カット基礎競合店",
        BASIS_FOR_TRADE_AREA_CUT: "商圏カット根拠",
        SALES_FORECAST_SUMMARY_TABLE: "売上予測集計表",
        TEMPORARY_OUTPUT: "仮出力",
        CREATE: "作成",
        CLOSE: "閉じる",
        RECREATE: "再作成",
        CHANGE_DETAILS: "変更内容",
        REGIST_COMMENT: "コメント登録",
        INTERMEDIATE_PRODUCTS: "中間生成物",
        FIVE_MINUTE_WALK_TRADE_AREA: "徒歩5分商圏",
        N15_ADDITIONAL_TRADE_AREA: "N15追加商圏",
        SALES_FORECASTING_OUTPUT_COUNT: "売上予測出力件数",
        SURVEY_FORM_CREATING_ORDER_COUNT: "調査書作成依頼件数",
        AVERAGE_RECEPTION_RESIDENCE_TIME: "平均受付滞留時間",
        LAYOUT_DIAGRAM: "レイアウト図",
        PLACEMENT_DIAGRAM: "配置図",
        DESIGNER_PLACEMENT_DIAGRAM: "設計事務所配置図",
        location5mCutMarketCutApproval: "５ｍ外・商圏カット承認",
        location5mCutApproval: "５ｍ外承認",
        marketCutApproval: "商圏カット承認",
    },
    SYSTEM_LOG_TYPE: {
        ITEM_CHANGE: "項目変更", // log_type 0
        CONFLICT_CVS_CHANGE: "競合CVS変更", // log_type 3
        CONFLICT_SMDS_CHANGE: "競合SMDS変更",
        IMPACT_INTERSECTION_CHANGE: "影響度交差点変更",
        SALES_FORECAST_SUMMARY_TABLE_CHANGE: "売上予測集計表変更",
        ADDITIONAL_SURVEY_CHANGE: "追加調査変更",
        OTHER_DOCUMENT_CHANGE: "その他資料変更",
        RR_INTERIOR_CHANGE: "RR内部用変更",
        CURRENT_STATUS_PHOTO_CHANGE: "現況写真変更",
        TRAFFIC_VOLUME_CHANGE: "通行量変更",
        FIVEM_EXTRA_CALCULATION_BASIS_CHANGE: "5m外算入根拠変更",
        TRADE_AREA_CUT_CHANGE: "商圏カット",
        IMPACT_ASSESSMENT_CHANGE: "影響度判定",
        MAP_CHANGE: "地図",
    },
    VALUE: {
        ADD_CAR: "car",
        ADD_PEOPLE: "people",
        CHANGE_CAR: "change_car",
        CHANGE_PEOPLE: "change_people",
        PEOPLE_TARGET_IN: "in",
        PEOPLE_TARGET_OUT: "out",
        PEOPLE_TARGET_IN_N_OUT: "in_n_out",
    },
    NUMBER: {
        ZERO: 0,
        ONE: 1,
        TWO: 2,
        THREE: 3,
        FOUR: 4,
        FIVE: 5,
        SIX: 6,
        SEVEN: 7,
        EIGHT: 8,
        NINE: 9,
        TEN: 10,
        TWENTEEN: 12,
    },
    CONFIRM_MESSAGE: {
        CHANGE_SAVE: "変更を保存しますか？",
        LIMIT_OF_COMPETITOR_RESEARCH_LIST:
            "競合調査リストは最大__MAX__件まで登録可能です",
        LIMIT_OF_IMPACT_ADDED_LIST:
            "影響度追加リストは最大__MAX_ADD_IMPACT__件まで登録可能です",
        ADD_IMPACT: "影響度を登録してください\n",
        HAS_INTERSECTION: "__ROUTE__の交差点を登録してください\n",
        ONLY_NUMBER: "数値のみ入力してください",
        PLEASE_SELECT_A_PHOTO: "写真を選んでください",
        ERROR_MESSAGE_MORNING_NOON:
            "朝、昼は各2つ、または手入力を選択してください。",
        ERROR_MESSAGE_PEOPLE_NIGHT: "夜は2つ、または手入力を選択してください。",
        ERROR_MESSAGE_PEOPLE_HOLIDAYS:
            "休日は2つ、または手入力を選択してください。",
        ERROR_COUNT_START_TIME:
            "調査開始時間は __RANGE_TIME__ に収まる範囲内で設定してください\n",
        ERROR_COUNT_END_TIME:
            "調査時間は __RANGE_TIME__ に収まる範囲内で設定してください\n",
        OVER_USER_INPUT_CAR:
            "総台数内で入力してください\n総台数：__TOTAL__ 普通：__STANDARD__ （内）軽：__LIGHT__ （内）大型：__LARGE__\n",
        OVER_USER_INPUT_PEOPLE:
            "合計内で入力してください\n合計：__TOTAL__　(内)自転車：__BICYCLE__\n",
        CONVERT_EXCEL: "Excelを変換中です",
        COMPLETE_CONVERT_EXCEL: "Excelを変換しました",
        UNCOMPLETE_CONVERT_EXCEL: "Excelの変換に失敗",
        SALES_FORECASTS_COLOR: "売上予測集計表の色を選択してください",
        CREATE_PDF: "PDFを作成中",
        COMPLETE_CREATE_PDF: "PDFを作成しました",
        UNCOMPLETE_CREATE_PDF: "PDFの作成に失敗しました",
        UPLOAD: "アップロード中",
        SUCCESS_UPLOAD: "アップロードが完了しました",
        ERROE_UPLOAD: "アップロードに失敗しました",
        UPLOAD_FILE: "ファイルをアップロード中",
        SUCCESS_UPLOAD_FILE: "ファイルをアップロードしました",
        ERROR_UPLOAD_FILE: "ファイルのアップロードに失敗しました",
        UPLOAD_PDF: "PDFをアップロード中",
        SUCCESS_UPLOAD_PDF: "PDFをアップロードしました",
        ERROR_UPLOAD_PDF: "PDFのアップロードに失敗しました",
        DELETE_FILE: "ファイルを削除中",
        SUCCESS_DELETE_FILE: "ファイルを削除しました",
        ERROR_DELETE_FILE: "ファイルの削除に失敗しました",
        DELETE_PDF: "PDFを削除中",
        SUCCESS_DELETE_PDF: "PDFを削除しました",
        ERROR_DELETE_PDF: "PDFの削除に失敗しました",
        DOWNLOAD_FOR_DETERMINING_THE_IMPACT_CSV:
            "影響度判定用データをダウンロード中",
        SUCCESS_DOWNLOAD_FOR_DETERMINING_THE_IMPACT_CSV:
            "影響度判定用データをダウンロードしました",
        ERROR_DOWNLOAD_FOR_DETERMINING_THE_IMPACT_CSV:
            "影響度判定用データのダウンロードに失敗しました",
        PLEASE_INPUT_COMMENT: "コメントを入力してください",
        UPLOAD_5MINUTE_WALK_TRADE_AREA: "徒歩5分商圏ファイルアップロード中",
        SUCCESS_5MINUTE_WALK_TRADE_AREA:
            "徒歩5分商圏ファイルをアップロードしました",
        ERROR_5MINUTE_WALK_TRADE_AREA:
            "徒歩5分商圏ファイルのアップロードに失敗しました",
        UPLOAD_N15_TRADE_AREA: "N15追加商圏ファイルアップロード中",
        SUCCESS_N15_WALK_TRADE_AREA:
            "N15追加商圏ファイルをアップロードしました",
        ERROR_N15_WALK_TRADE_AREA:
            "N15追加商圏ファイルのアップロードに失敗しました",
        DOWNLOAD_MAP_FILE: "地図テンプレートをダウンロード中",
        SUCCESS_DOWNLOAD_MAP_FILE: "地図テンプレートをダウンロードしました",
        ERROR_DOWNLOAD_MAP_FILE: "地図テンプレートのダウンロードに失敗しました",
        DIRECT_DISTANCE_ISINTEGER: "直線距離は半角整数で入力してください",
        ROAD_DISTANCE_ISINTEGER: "道なり距離は半角整数で入力してください",
        ADD_START_TRAFFIC_CAR: "車通行量登録中",
        ADD_TRAFFIC_CAR: "車通行量登録しました",
        ADD_ERROR_TRAFFIC_CAR: "車通行量登録に失敗しました",
        DELETE_START_TRAFFIC_CAR: "車通行量削除中",
        DELETE_TRAFFIC_CAR: "車通行量削除しました",
        DELETE_ERROR_TRAFFIC_CAR: "車通行量削除に失敗しました",
        ADD_START_TRAFFIC_PEOPLE: "人通行量登録中",
        ADD_TRAFFIC_PEOPLE: "人通行量登録しました",
        ADD_ERROR_TRAFFIC_PEOPLE: "人通行量登録に失敗しました",
        DELETE_START_TRAFFIC_PEOPLE: "人通行量削除中",
        DELETE_TRAFFIC_PEOPLE: "人通行量削除しました",
        DELETE_ERROR_TRAFFIC_PEOPLE: "人通行量削除に失敗しました",
        UPDATE_START_TRAFFIC_CAR: "車通行量更新中",
        UPDATE_TRAFFIC_CAR: "車通行量更新しました",
        UPDATE_ERROR_TRAFFIC_CAR: "車通行量更新に失敗しました",
        UPDATE_START_TRAFFIC_PEOPLE: "人通行量更新中",
        UPDATE_TRAFFIC_PEOPLE: "人通行量更新しました",
        UPDATE_ERROR_TRAFFIC_PEOPLE: "人通行量更新に失敗しました",
        ERROR_MESSAGE_ADD_TRAFFIC: "_TIME_は_COUNT_つ登録済みです\n",
        ERROR_MESSAGE_ADD_OVER_TRAFFIC: "_TIME_は2つまで登録可能です\n",
        CHANGE_TRAFFIC_CONFIRM:
            "対象外の時間帯が選択されています。\n日付・時間の表記を変更するか、\nカウント結果を登録し直してください",
    },
    ALERT_MESSAGE: {
        ALERT_LONGCHECK_LABEL: "以下をご確認ください\n\n",
        ALERT_LOCATION_EXCEL_FLAG: "・中間生成物が取り込まれていません\n",
        ALERT_60DAYS_OVER_COUNT_DATE: "・通行量調査から2カ月経過しています\n",
        ALERT_EMPTY_N15ADDMARKET: "・N15追加商圏が取り込まれていません\n",
    },
    ERROR_MESSAGE: {
        ERROR_LOCATION_ITEM: "以下項目の入力が不正です。",
        ERROR_LOCATION_CHILD_ITEM: "S店の以下の入力が不正です。",
        ERROR_LOCATION_EXCEL_FLAG: "中間生成物が取り込まれていません",
        ERROR_NO_LOCATION_CHILD: "既存店の調査書が作成されていません",
        ERROR_EMPTY_N15ADDMARKET: "N15追加商圏が取り込まれていません",
    },
    TRAFFICVOLUME: {
        TRAFFICTYPE_0: {
            typeVal: "0",
            min: "0900",
            max: "1030",
            range: "09:00　～　10:30",
            name: "朝　9:00　～　10:30",
        },
        TRAFFICTYPE_1: {
            typeVal: "1",
            min: "1300",
            max: "1500",
            range: "13:00　～　15:00",
            name: "昼　13:00　～　15:00",
        },
        TRAFFICTYPE_2: {
            typeVal: "2",
            min: "2000",
            max: "2130",
            range: "20:00　～　21:30",
            name: "夜　20:00　～　21:30",
        },
        TRAFFICTYPE_3: {
            typeVal: "3",
            min: "2000",
            max: "2130",
            range: "20:00　～　21:30",
            name: "夜（モバ空）　20:00　～　21:30",
        },
        TRAFFICTYPE_4: {
            typeVal: "4",
            min: "1030",
            max: "1630",
            range: "10:30　～　16:30",
            name: "土曜、休祝日又は行楽地のトップシーズン　10:30　～　16:30",
        },
        TRAFFICTYPE_5: {
            typeVal: "5",
            min: "1030",
            max: "1630",
            range: "10:30　～　16:30",
            name: "土曜、休祝日又は行楽地のトップシーズン（モバ空）　10:30　～　16:30",
        },
        TRAFFICTYPE_6: {
            typeVal: "6",
            min: "0900",
            max: "1030",
            range: "09:00　～　10:30",
            name: "朝　9:00　～　10:30",
        },
        TRAFFICTYPE_7: {
            typeVal: "7",
            min: "0730",
            max: "0830",
            range: "07:30　～　08:30",
            name: "朝特定TR　7:30　～　8:30　（15分間30人以上の場合のみ）",
        },
        TRAFFICTYPE_8: {
            typeVal: "8",
            min: "1300",
            max: "1500",
            range: "13:00　～　15:00",
            name: "昼　13:00　～　15:00",
        },
        TRAFFICTYPE_9: {
            typeVal: "9",
            min: "1200",
            max: "1300",
            range: "12:00　～　13:00",
            name: "昼特定TR　12:00　～　13:00　（15分間30人以上の場合のみ）",
        },
        TRAFFICTYPE_10: {
            typeVal: "10",
            min: "2000",
            max: "2130",
            range: "20:00　～　21:30",
            name: "夜　20:00　～　21:30",
        },
        TRAFFICTYPE_11: {
            typeVal: "11",
            min: "2000",
            max: "2130",
            range: "20:00　～　21:30",
            name: "夜（モバ空）　20:00　～　21:30",
        },
        TRAFFICTYPE_12: {
            typeVal: "12",
            min: "1600",
            max: "1730",
            range: "16:00　～　17:30",
            name: "夕特定TR　16:00　～　17:30　（15分間30人以上の場合のみ）",
        },
        TRAFFICTYPE_13: {
            typeVal: "13",
            min: "1030",
            max: "1630",
            range: "10:30　～　16:30",
            name: "土曜、休祝日又は行楽地のトップシーズン　10:30　～　16:30",
        },
        TRAFFICTYPE_14: {
            typeVal: "14",
            min: "1030",
            max: "1630",
            range: "10:30　～　16:30",
            name: "土曜、休祝日又は行楽地のトップシーズン（モバ空）　10:30　～　16:30",
        },
    },
    FUNCTION_NAME: {
        OTHER_EXCEL_UPLOAD: "その他資料EXCEL アップロード",
        OTHER_EXCEL_DELETE: "その他資料EXCEL 削除",
        OTHER_EXCEL_COMMENT: "その他資料EXCEL 不備コメント",
        OTHER_PDF_UPLOAD: "その他資料pdf アップロード",
        OTHER_PDF_DELETE: "その他資料pdf 削除",
        OTHER_PDF_COMMENT: "その他資料pdf 不備コメント",
        LOCATION_PICTURE_UPDATE: "現況写真 登録",
        LOCATION_PICTURE_COMMENT: "現況写真 不備コメント",
        LOCATION_CONFLICT_INSERT: "競合店 登録",
        LOCATION_CONFLICT_UPDATE: "競合店 更新",
        LOCATION_CONFLICT_SEJ_UPDATE: "競合店 自店更新",
        LOCATION_TRAFFIC_CAR_INSERT: "車通行量 登録",
        LOCATION_TRAFFIC_CAR_UPDATE: "車通行量 更新",
        LOCATION_TRAFFIC_CAR_DELETE: "車通行量 削除",
        LOCATION_TRAFFIC_PEOPLE_INSERT: "人通行量 登録",
        LOCATION_TRAFFIC_PEOPLE_UPDATE: "人通行量 更新",
        LOCATION_TRAFFIC_PEOPLE_DELETE: "人通行量 削除",
        LOCATION_TRAFFIC_CAR_PEOPLE_UPDATE: "車通行量 人通行量 更新",
        LOCATION_TRAFFIC_CAR_PEOPLE_DELETE: "車通行量 人通行量 削除",
        LOCATION_TRAFFIC_5MCUT_UPDATE: "5m外算入根拠 更新",
        LOCATION_TRAFFIC_OTHER_COMMENT: "その他通行量 不備コメント",
        LOCATION_IMPACT_INSERT: "影響度 登録",
        LOCATION_IMPACT_UPDATE: "影響度 更新",
        LOCATION_IMPACT_DELETE: "影響度 削除",
        LOCATION_IMPACT_COMMENT: "影響度 不備コメント",
        LOCATION_COVER_PDF_UPLOAD: "表紙PDF アップロード",
        LOCATION_COVER_PDF_DELETE: "表紙PDF 削除",
        LOCATION_COVER_PDF_COMMENT: "表紙PDF 不備コメント",
        S_STORE_COMMENT: "S店情報 不備コメント",
        SPECIAL_NOTES_COMMENT: "特記事項​ 不備コメント",
        SETTING_REASON_COMMENT: "出店条件 不備コメント",
        OBSTACLES_ETC_COMMENT: "手書き配置図 不備コメント",
        MARKET_CUT_COMMENT: "商圏カット 不備コメント",
        APPROVAL_ADD_SURVEY: "追加調査 承認",
        APPROVAL_ADD_SURVEY_PDF_CREATE: "追加調査 PDF作成",
        RFC_ITEM_CHANGE_HISTORY_COMMENT: "RFC項目変更履歴 コメント登録",
        COMMENT_REGIST: "不備コメント 登録",
        SEND_MAIL: "メール送信",
        COMMENT_REGIST_SEND_MAIL: "依頼コメント登録＆メール送信",
        REQUEST_TASK_PARENT: "依頼 親入力チェック",
        REQUEST_TASK_CHILD: "依頼 子入力チェック",
        REQUEST_TASK_PDF_CREATE: "依頼 PDF作成",
        REQUEST_TASK_OUTPUT_PARENT: "依頼 親調査書の出力依頼",
        REQUEST_TASK_OUTPUT_CHILD: "依頼 子調査書の出力依頼",
        REQUEST_TASK_CSV_PARENT: "依頼 csvアップロード（親）",
        REQUEST_TASK_CSV_CHILD: "依頼 csvアップロード（子）",
        REQUEST_TASK_CHECK: "依頼 入力チェック",
        REQUEST_TASK_OUTPUT: "依頼 調査書の出力依頼",
        REQUEST_TASK_CSV: "依頼 csvアップロード",
        COVER_COMMENT: "表紙 不備コメント",
        COMMENT_UPDATE: "不備コメント 更新",
        COMMENT_DELETE: "不備コメント 削除",
        DONATION_COMMENT: "寄付き 不備コメント",
        DRAWING_PDF_CREATE: "図面 PDF作成",
        DRAWING_PDF_DELETE: "図面 PDF削除",
        MAP_COMMENT: "地図 不備コメント",
        COVER_FILE_UPLOAD: "表紙 ファイルアップロード",
        COVER_FILE_DELETE: "表紙 ファイル削除",
        DESCRIPTION_DOCUMENT_FILE_UPLOAD: "説明資料 ファイルアップロード",
        DESCRIPTION_DOCUMENT_FILE_DELETE: "説明資料 ファイル削除",
        APPROVAL_SURVEY_PDF: "承認　登録PDF変換",
        DRAWING_COMMENT_PARKING_LOT_AREA:
            "図面 駐車場面積算出根拠 不備コメント",
        DRAWING_COMMENT_OBSTACLES:
            "図面 人車来店時の障害物・支障物 不備コメント",
        TRADE_AREA_COMMENT: "商圏 不備コメント",
        RR_INTERNAL_USE_FILE_UPLOAD: "RR内部用 ファイルアップロード",
        RR_INTERNAL_USE_FILE_DELETE: "RR内部用 ファイル削除",
        MAP_FILE_TEMPLATE_DOWNLOAD: "地図 テンプレートダウンロード",
        MAP_FILE_DOWNLOAD: "地図 ファイルダウンロード",
        MAP_FILE_UPLOAD: "地図 ファイルアップロード",
        MAP_FILE_DELETE: "地図 ファイル削除",
        APPROVAL_TRAFFIC_5MCUT: "5m外算入根拠 承認/差戻",
    },
};
